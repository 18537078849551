import { HttpClient } from '@angular/common/http';
import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { Meta, MetaDefinition } from '@angular/platform-browser';
import { BehaviorSubject, Observable, ReplaySubject, Subject } from 'rxjs';
import { map, take } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { AppInsightsErrorHandlerService } from '../app-insights-error-handler/app-insights-error-handler.service';
import { LocalizationService } from '../localization/localization.service';
import { TelemetryService } from '../telemetry/telemetry.service';
import { IContentDocuments } from './contentRT.model';
import { IConfig } from './contentRT.model';
import { DOCUMENT, isPlatformBrowser } from '@angular/common';
import { UmpVideoPlayerService } from 'src/app/shared/ump-video-player/ump-video-player.service';


declare var MsOnePlayer: any;

@Injectable({
  providedIn: 'root'
})
export class ContentService {

  public pageTitle = new BehaviorSubject<string>('');
  private categories$: Subject<any> = new ReplaySubject(1);
  public metaSubject$: Subject<any> = new ReplaySubject(1);
  public language: string;
  public market: string;
  public isRtl = false;
  private winDow: Window | null;
  

  constructor(
    private http: HttpClient,
    private localizationService: LocalizationService,
    private telemetryService: TelemetryService,
    private metaService: Meta,
    private appInsightsErrorHandler: AppInsightsErrorHandlerService,
    @Inject(DOCUMENT) private document: any,
    @Inject(PLATFORM_ID) private platformId: any,
    private umpVideoPlayer : UmpVideoPlayerService
  ) {
    this.winDow = this.document.defaultView;
  }

  // Function to get the page title
  public getPageTitle(): Observable<string> {
    return this.pageTitle.asObservable();
  }

  public getCategories(): Observable<any> {
    return this.categories$.asObservable();
  }

  // call this when the application boots up and it will allow component pages to get paging settings from cache
  public initCategories() {
    const language = this.localizationService.getLanguage();

    this.http.get<IContentDocuments>(environment.urls.categoriesUrl + `&language=${language}`).pipe(take(1))
      .subscribe((result) => {
        const json = result;
        this.categories$.next(json);
      }, err => {
        this.telemetryService.logError('Error getting categories from WordPress: ' + JSON.stringify(err));
        this.appInsightsErrorHandler.handleError('Error getting categories from WordPress: ' + JSON.stringify(err))
      });
  }


  public setPageTitle(title: string) {
    this.pageTitle.next(title);
  }

  public setRtl(rtl: boolean) {
    this.isRtl = rtl;
  }

  public getRtl() {
    return this.isRtl;
  }

  // This does not work for mock content at this time
  // public getCmsContent(pageContentString: string, useLocal = false, slug = ''): Observable<any> {
  //   if(!useLocal) {
  //     return this.http.get<IContentDocuments>(environment.urls.contentUrl + pageContentString);
  //   } else {
  //     const baseUrl = window.location.origin + '/assets/data/';
  //     return this.http.get<IContentDocuments>(baseUrl + slug + ".json");
  //   }
  // }



  public getCmsContent(pageContentString: string, useLocal = false, slug = ''): Observable<any> {
    if (!useLocal) {
      return this.http.get<IContentDocuments>(environment.urls.contentUrl + pageContentString).pipe(
        map((res) => {
          const modifiedHtml = this.modifySrc(res);
          return modifiedHtml;
        })
      );
    } else {
      const baseUrl = window.location.origin + '/assets/data/';
      return this.http.get<IContentDocuments>(baseUrl + slug + ".json");
    }
  }

  // This does not work for mock content at this time
  public getCmsConfig(): Observable<any> {
    return this.http.get<IConfig>(environment.urls.configUrl);
  }


  public getBlobContent(pageContentString: string): Observable<any> {
    return this.http.get<IContentDocuments>(environment.urls.contentUrl + pageContentString + "&language=" + this.localizationService.getLanguage() + "");
  }

  public getLocalContent(slug: string): Observable<any> {
    const baseUrl = window.location.origin + '/assets/data/';
    return this.http.get<IContentDocuments>(baseUrl + slug + ".json");
  }


  public getMetaConfigs(): Observable<any> {
    return this.metaSubject$.asObservable();
  }

  public initCmsConfig(){
    if((environment.azureSearchKey || "").length < 5){
      this.getCmsConfig()
        .pipe(take(1))
        .subscribe((result) => {
          environment.azureSearchKey = result.azureSearchKey;
        }, err => {
          this.telemetryService.logError(`Error getting config : ${JSON.stringify(err)}`);
          this.appInsightsErrorHandler.handleError(`Error getting config : ${JSON.stringify(err)}`);
        });
    }
  }

  public initMetaConfigs() {
    const language = this.localizationService.getLanguage();
    const contentPageString = `meta/meta.json&language=${language}`;
    this.getCmsContent(contentPageString)
      .pipe(take(1))
      .subscribe((result) => {
        const pageContent = result.documents[0];
        const metaData = JSON.parse(pageContent.document);
        this.metaSubject$.next(metaData);
        this.metaSubject$.complete();
      }, err => {
        this.telemetryService.logError(`Error getting page-unavailable : ${JSON.stringify(err)}`);
        this.appInsightsErrorHandler.handleError(`Error getting page-unavailable : ${JSON.stringify(err)}`);
      });
  }

  public pauseVideo(divContainerId: string, videoId: string) {
    const playerData = {
      options: {
        autoplay: false,
        mute: false,
        loop: false,
        market: this.localizationService.getLocaleCookie(),
        playFullScreen: false
      },
      metadata: {
        videoId: videoId  // Id from RedTiger CMS
      }
    };

    MsOnePlayer.render(
      divContainerId,     // id of the container div
      playerData,	        // player data
      (player) => {    // onPlayerReady callback function which returns back player instance on which APIs can be called

        player.pause();
      }
    );
  }


  public setupOnePlayer(divContainerId: string, videoId: string) {

    /*
  specify the playData with options and metadata
    where options:
     - autoplay: boolean value used to set autoplay state of the player (default: false)
     - mute: boolean value used to set mute state of the player (default: false)
     - controls: boolean value used to show/hide player controls (default: true)
     - loop: boolean value used to set video in loop (default: false)
     - startTime: int value used to set start time of video
     - autoCaptions - string value indicating locale for default captions (e.g. en-us will show en-us caption automatically without user having to enable the captions)
     - playFullScreen - boolean value used to launch player in fullscreen on first user interaction (not available with autoplay=true) (default: false)
     - market - string value indicating the market/locale of the player. This is a required options to set correct player localization.

 and metadata:
     - videoId: the vCMS video id.
*/

    const playerData = {
      options: {
        autoplay: false,
        mute: false,
        loop: false,
        market: this.localizationService.getLocaleCookie(),
        playFullScreen: false
      },
      metadata: {
        videoId: videoId  // Id from RedTiger CMS
      }
    };

    MsOnePlayer.render(
      divContainerId,     // id of the container div
      playerData,	        // player data
      (player) => {    // onPlayerReady callback function which returns back player instance on which APIs can be called

        // override the video iframe elements
        // The iframe is injected by OnePlayer JS when the player is ready.  Therefore we use JavaScript to set the needed CSS attributes
        const videoIframe = document.getElementById(divContainerId + '-oneplayer');
        // console.log(videoIframe);
        videoIframe.style.position = 'absolute';
        videoIframe.style.top = '0';
        videoIframe.style.top = '0';
        videoIframe.style.left = '0';
        videoIframe.style.width = '100%';
        videoIframe.style.height = '100%';

        player.addPlayerEventListener((e) => {
          // console.log('player event: ' + JSON.stringify(e));
        });
      }
    );
  }

  /**
   * This can be used when there are no specific tags from WordPress Yoast plugin
   * @param description
   * @param keywords
   * @param title
   */
  public loadSeo(description: string, keywords: string, title: string) {

    // console.log(this.metaService.getTag("name='description'"));

    this.metaService.updateTag({
      name: "description",
      content: description
    });

    // console.log(this.metaService.getTag("name='description'"));

    this.metaService.updateTag({
      name: "keywords",
      content: keywords
    });

    this.metaService.updateTag({
      name: "og:url",
      content: "https://" + window.location.hostname + "blog"
    });

    this.metaService.updateTag({
      name: "og:locale",
      content: this.localizationService.getLanguage()
    });

    this.metaService.updateTag({ name: "og:type", content: title });
    this.metaService.updateTag({ name: "og:title", content: title });
  }

  public addTags() {

    // const descriptionText = 'Become an Insider: be one of the first to explore new Windows features for you and your business or use the latest Windows SDK to build great apps.';
    const titleText = 'Windows Insider Program';
    // const imageUrl = 'https://wipwebprodcdnv2.blob.core.windows.net/wipmedia/wp-content/uploads/sites/8/2019/02/pc-wallpaper_1200x630.jpg';

    const keywords: MetaDefinition = {
      name: "keywords",
      content: 'Windows Insider Program, Windows Insider, Microsoft 365, Windows, Insider, Windows SDK, Apps, Insider for Business, Insider for consumers, Insider for developers, Microsoft developer SDK'
    };

    const description: MetaDefinition = {
      name: "description",
      // content: descriptionText
    };

    // const ogDescription: MetaDefinition = {
    //   name: "og:description",
    //   content: 'Become an Insider: be one of the first to explore new Windows features for you and your business or use the latest Windows SDK to build great apps.'
    // };

    const locale: MetaDefinition = {
      name: "og:locale",
      content: this.localizationService.getLanguage()
    };

    const title: MetaDefinition = {
      name: "og:title",
      content: titleText
    };


    const url: MetaDefinition = {
      name: "og:url",
      content: 'https://www.microsoft.com/windowsinsider'
    };

    const site_name: MetaDefinition = {
      name: "og:site_name",
      content: 'https://www.microsoft.com/windowsinsider'
    };

    // const image: MetaDefinition = {
    //   name: "og:image",
    //   content: imageUrl
    // };


    // const secureImage: MetaDefinition = {
    //   name: "og:image:secure_url",
    //   content: imageUrl
    // };



    const width: MetaDefinition = {
      name: "og:image:width",
      content: '1200'
    };

    const height: MetaDefinition = {
      name: "og:image:height",
      content: '600'
    };

    const twitterCard: MetaDefinition = {
      name: "twitter:card",
      content: 'summary_large_image'
    };

    const twitterDescription: MetaDefinition = {
      name: "twitter:description",
      // content: descriptionText
    };

    const twitterTitle: MetaDefinition = {
      name: "twitter:title",
      content: titleText
    };

    const twitterImage: MetaDefinition = {
      name: "twitter:image",
      // content: imageUrl
    };

    if (environment.production) {
      const robots: MetaDefinition = {
        name: "robots",
        content: 'index, follow'
      };
      this.metaService.addTags([robots]);
    } else {
      const robots: MetaDefinition = {
        name: "robots",
        content: 'noindex, nofollow, nosnippet'
      };
      this.metaService.addTags([robots]);
    }

    //this.metaService.addTags([keywords, description, locale, url, site_name, width, height, twitterCard, twitterDescription, twitterTitle, twitterImage]);

    // this.metaService.addTags([
    //   {name: 'keywords', content: 'Office Insider, Microsoft 365'},
    //   {name: 'description', content: 'Office Insider, Microsoft 365'},
    //   {name: 'og:locale', content: this.localizationService.getLanguage() },
    //   {name: 'og:url', content: "https://" + window.location.hostname },
    //   {name: "og:type", content: "" },
    //   {name: "og:title", content: "Office Insiders" },
    //   {name: "og:image", content: "https://compass-ssl.microsoft.com/assets/4f/d8/4fd86f4e-f807-44e4-bdc4-34da4a88da2b.jpg?n=ioc_home_hero_1260x473.jpg" }
    //     <meta property="og:site_name" content="Windows Insider" />
    // <meta property="og:image" content="https://wipwebprodcdnv2.blob.core.windows.net/wipmedia/wp-content/uploads/sites/8/2019/02/pc-wallpaper_1200x630.jpg" />
    // <meta property="og:image:secure_url" content="https://wipwebprodcdnv2.blob.core.windows.net/wipmedia/wp-content/uploads/sites/8/2019/02/pc-wallpaper_1200x630.jpg" />
    // <meta property="og:image:width" content="1200" />
    // <meta property="og:image:height" content="630" />
    // <meta name="twitter:card" content="summary_large_image" />
    // <meta name="twitter:description" content="Become an Insider: be one of the first to explore new Windows features for you and your business or use the latest Windows SDK to build great apps." />
    // <meta name="twitter:title" content="Windows Insider Program" />
    // <meta name="twitter:image" content="https://wipwebprodcdnv2.blob.core.windows.net/wipmedia/wp-content/uploads/sites/8/2019/01/pc-wallpaper_1024x512.jpg" />
  }

  private modifySrc(res){
    try {
      if(res && res.documents && res.documents.length > 0){
        res.documents.map(
          (doc) => {
            if(doc['alias'] && doc['document'] && (doc['alias'].endsWith('.html') || doc['alias'].endsWith('meta.json'))){
              doc.document = doc.document.replaceAll('https://wipwebprodcdnv2.blob.core.windows.net',environment.assetsBasePath);
              if (isPlatformBrowser(this.platformId)) {
                doc.document = this.umpVideoPlayer.addUMPVideoPlayer(doc.document);
              }
              return doc;
            } else {
              return doc;
            }
          }
        );
        return res;
      
      } else {
        return res;
      }
    } catch(error){
      this.telemetryService.logError(`Error loading images : ${JSON.stringify(error)}`);
      this.appInsightsErrorHandler.handleError(`Error loading images : ${JSON.stringify(error)}`);
      return res;
    }
  }
}
