import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ContestsRoutingModule } from './contests-routing.module';
import { ContestsComponent } from './contests.component';
import { SharedModule } from 'src/app/shared/shared.module';



@NgModule({
  declarations: [ContestsComponent],
  imports: [
    SharedModule,
    CommonModule,
    ContestsRoutingModule
  ]
})
export class ContestsModule { }
