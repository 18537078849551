import { Component, OnInit } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';
import { AppInsightsErrorHandlerService } from 'src/app/core/app-insights-error-handler/app-insights-error-handler.service';
import { LocalizationService } from '../../core/localization/localization.service';
import { TelemetryService } from '../../core/telemetry/telemetry.service';
import { ContestsService } from './contest.service';

@Component({
  selector: 'app-contests',
  templateUrl: './contests.component.html',
  styleUrls: ['./contests.component.scss']
})
export class ContestsComponent implements OnInit {

  public contentLoading = true;
  public meta: any;
  public contest: any;

  constructor(
    private metaService: Meta,
    private titleService: Title,
    private telemetryService: TelemetryService,
    private contestsService: ContestsService,
    private locationService: LocalizationService,
    private appInsightsErrorHandler : AppInsightsErrorHandlerService
  ) {

  }

  ngOnInit() {
    this.getContent();
  }

  public getContent() {
    this.contestsService.getMetaSettings()
      .subscribe((result) => {
        this.contest = result.documents[0].document;
        this.contentLoading = false;
      },
        (err: any) => {
          this.telemetryService.logError('Error while getting page events: ' + JSON.stringify(err));
          this.appInsightsErrorHandler.handleError('Error while getting page events: ' + JSON.stringify(err));
          this.contentLoading = false;
        });
  }

  // private setSEO() {
  //   this.titleService.setTitle(this.meta.title);

  //   this.metaService.updateTag({
  //     name: 'description',
  //     content: this.meta.metaDescription,
  //   });

  //   this.metaService.updateTag({
  //     name: 'keywords',
  //     content: this.meta.keywordText,
  //   });

  //   this.metaService.updateTag({
  //     name: 'og:url',
  //     content: 'https://' + window.location.hostname + '/contests',
  //   });

  //   this.metaService.updateTag({
  //     name: 'og:locale',
  //     content: this.locationService.getLanguage()
  //   });

  //   this.metaService.updateTag({ name: 'og:type', content: 'contests' });
  //   this.metaService.updateTag({ name: 'og:title', content: this.meta.heading.text });

  //   this.metaService.updateTag({
  //     name: 'og:image',
  //     content: this.meta.featuredbackgroundImage.vp4,
  //   });
  // }

}
