<section class="container" *ngIf="!contentLoading">
  <!-- <div class="col-12">
    <h1 class="h2">{{ meta.heading }}</h1>
  </div> -->

  <div class="col-12">
    <div id="RendererContents">
      <ul id="ItemList">
        <!-- <li id="cs-CZListItem" class="ltrtext">
          <a class="btn btn-link" href="/?omkt=cs-CZ" id="cs-CZRegionalLink" name="cs-CZ" tabindex="1"
            lang="cs-CZ">Čeština - Česká republika</a>
        </li>
        <li id="da-DKListItem" class="ltrtext">
          <a class="btn btn-link" href="/?omkt=da-DK" id="da-DKRegionalLink" name="da-DK" tabindex="2"
            lang="da-DK">Dansk - Danmark</a>
        </li> -->
        <li id="de-DEListItem" class="ltrtext">
          <a class="btn btn-link" href="/de-de/windowsinsider/?omkt=de-DE" id="de-DERegionalLink" name="de-DE" tabindex="3"
            lang="de-DE">Deutsch - Deutschland</a>
        </li>
        <!-- <li id="de-ATListItem" class="ltrtext">
          <a class="btn btn-link" href="/?omkt=de-AT" id="de-ATRegionalLink" name="de-AT" tabindex="4"
            lang="de-AT">Deutsch - Österreich</a>
        </li>
        <li id="de-CHListItem" class="ltrtext">
          <a class="btn btn-link" href="/?omkt=de-CH" id="de-CHRegionalLink" name="de-CH" tabindex="5"
            lang="de-CH">Deutsch - Schweiz</a>
        </li>
        <li id="et-EEListItem" class="ltrtext">
          <a class="btn btn-link" href="/?omkt=et-EE" id="et-EERegionalLink" name="et-EE" tabindex="6"
            lang="et-EE">Eesti - Eesti</a>
        </li>
        <li id="en-AUListItem" class="ltrtext">
          <a class="btn btn-link" href="/?omkt=en-AU" id="en-AURegionalLink" name="en-AU" tabindex="7"
            lang="en-AU">English - Australia</a>
        </li>
        <li id="en-CAListItem" class="ltrtext">
          <a class="btn btn-link" href="/?omkt=en-CA" id="en-CARegionalLink" name="en-CA" tabindex="8"
            lang="en-CA">English - Canada</a>
        </li>
        <li id="en-INListItem" class="ltrtext">
          <a class="btn btn-link" href="/?omkt=en-IN" id="en-INRegionalLink" name="en-IN" tabindex="9"
            lang="en-IN">English - India</a>
        </li>
        <li id="enListItem" class="ltrtext">
          <a class="btn btn-link" href="/?omkt=en" id="enRegionalLink" name="en" tabindex="10" lang="en">English -
            International</a>
        </li>
        <li id="en-IEListItem" class="ltrtext">
          <a class="btn btn-link" href="/?omkt=en-IE" id="en-IERegionalLink" name="en-IE" tabindex="11"
            lang="en-IE">English - Ireland</a>
        </li>
        <li id="en-MYListItem" class="ltrtext">
          <a class="btn btn-link" href="/?omkt=en-MY" id="en-MYRegionalLink" name="en-MY" tabindex="12"
            lang="en-MY">English - Malaysia</a>
        </li>
        <li id="en-NZListItem" class="ltrtext">
          <a class="btn btn-link" href="/?omkt=en-NZ" id="en-NZRegionalLink" name="en-NZ" tabindex="13"
            lang="en-NZ">English - New Zealand</a>
        </li>
        <li id="en-SGListItem" class="ltrtext">
          <a class="btn btn-link" href="/?omkt=en-SG" id="en-SGRegionalLink" name="en-SG" tabindex="14"
            lang="en-SG">English - Singapore</a>
        </li>
        <li id="en-ZAListItem" class="ltrtext">
          <a class="btn btn-link" href="/?omkt=en-ZA" id="en-ZARegionalLink" name="en-ZA" tabindex="15"
            lang="en-ZA">English - South Africa</a>
        </li>
        <li id="en-GBListItem" class="ltrtext">
          <a class="btn btn-link" href="/?omkt=en-GB" id="en-GBRegionalLink" name="en-GB" tabindex="16"
            lang="en-GB">English - United Kingdom</a>
        </li> -->
      <li id="en-USListItem" class="ltrtext">
          <a class="btn btn-link" href="/en-us/windowsinsider/?omkt=en-US" id="en-USRegionalLink" name="en-US" tabindex="17"
            lang="en-US">English - United States</a>
        </li>
          <!-- 
        <li id="es-ARListItem" class="ltrtext">
          <a class="btn btn-link" href="/?omkt=es-AR" id="es-ARRegionalLink" name="es-AR" tabindex="18"
            lang="es-AR">Español - Argentina</a>
        </li>
        <li id="es-CLListItem" class="ltrtext">
          <a class="btn btn-link" href="/?omkt=es-CL" id="es-CLRegionalLink" name="es-CL" tabindex="19"
            lang="es-CL">Español - Chile</a>
        </li>
        <li id="es-COListItem" class="ltrtext">
          <a class="btn btn-link" href="/?omkt=es-CO" id="es-CORegionalLink" name="es-CO" tabindex="20"
            lang="es-CO">Español - Colombia</a>
        </li> -->
        <li id="es-ESListItem" class="ltrtext">
          <a class="btn btn-link" href="/es-es/windowsinsider/?omkt=es-ES" id="es-ESRegionalLink" name="es-ES" tabindex="21"
            lang="es-ES">Español - España</a>
        </li>
        <!-- <li id="esListItem" class="ltrtext">
          <a class="btn btn-link" href="/?omkt=es" id="esRegionalLink" name="es" tabindex="22" lang="es">Español -
            Latinoamérica</a>
        </li>
        <li id="es-MXListItem" class="ltrtext">
          <a class="btn btn-link" href="/?omkt=es-MX" id="es-MXRegionalLink" name="es-MX" tabindex="23"
            lang="es-MX">Español - México</a>
        </li>
        <li id="fr-BEListItem" class="ltrtext">
          <a class="btn btn-link" href="/?omkt=fr-BE" id="fr-BERegionalLink" name="fr-BE" tabindex="24"
            lang="fr-BE">Français - Belgique</a>
        </li>
        <li id="fr-CAListItem" class="ltrtext">
          <a class="btn btn-link" href="/?omkt=fr-CA" id="fr-CARegionalLink" name="fr-CA" tabindex="25"
            lang="fr-CA">Français - Canada</a>
        </li> -->
        <li id="fr-FRListItem" class="ltrtext">
          <a class="btn btn-link" href="/fr-fr/windowsinsider/?omkt=fr-FR" id="fr-FRRegionalLink" name="fr-FR" tabindex="26"
            lang="fr-FR">Français - France</a>
        </li>
        <!-- <li id="frListItem" class="ltrtext">
          <a class="btn btn-link" href="/?omkt=fr" id="frRegionalLink" name="fr" tabindex="27" lang="fr">Français -
            International</a>
        </li>
        <li id="fr-CHListItem" class="ltrtext">
          <a class="btn btn-link" href="/?omkt=fr-CH" id="fr-CHRegionalLink" name="fr-CH" tabindex="28"
            lang="fr-CH">Français - Suisse</a>
        </li>
        <li id="hr-HRListItem" class="ltrtext">
          <a class="btn btn-link" href="/?omkt=hr-HR" id="hr-HRRegionalLink" name="hr-HR" tabindex="29"
            lang="hr-HR">Hrvatski - Hrvatska</a>
        </li>
        <li id="id-IDListItem" class="ltrtext">
          <a class="btn btn-link" href="/?omkt=id-ID" id="id-IDRegionalLink" name="id-ID" tabindex="30"
            lang="id-ID">Indonesia - Indonesia</a>
        </li> -->
        <li id="it-ITListItem" class="ltrtext">
          <a class="btn btn-link" href="/it-it/windowsinsider/?omkt=it-IT" id="it-ITRegionalLink" name="it-IT" tabindex="31"
            lang="it-IT">Italiano - Italia</a>
        </li>
        <!-- <li id="lv-LVListItem" class="ltrtext">
          <a class="btn btn-link" href="/?omkt=lv-LV" id="lv-LVRegionalLink" name="lv-LV" tabindex="32"
            lang="lv-LV">Latviešu - Latvija</a>
        </li>
        <li id="lt-LTListItem" class="ltrtext">
          <a class="btn btn-link" href="/?omkt=lt-LT" id="lt-LTRegionalLink" name="lt-LT" tabindex="33"
            lang="lt-LT">Lietuvių - Lietuva</a>
        </li>
        <li id="hu-HUListItem" class="ltrtext">
          <a class="btn btn-link" href="/?omkt=hu-HU" id="hu-HURegionalLink" name="hu-HU" tabindex="34"
            lang="hu-HU">Magyar - Magyarország</a>
        </li>
        <li id="nl-BEListItem" class="ltrtext">
          <a class="btn btn-link" href="/?omkt=nl-BE" id="nl-BERegionalLink" name="nl-BE" tabindex="35"
            lang="nl-BE">Nederlands - België</a>
        </li>
        <li id="nl-NLListItem" class="ltrtext">
          <a class="btn btn-link" href="/?omkt=nl-NL" id="nl-NLRegionalLink" name="nl-NL" tabindex="36"
            lang="nl-NL">Nederlands - Nederland</a>
        </li>
        <li id="nb-NOListItem" class="ltrtext">
          <a class="btn btn-link" href="/?omkt=nb-NO" id="nb-NORegionalLink" name="nb-NO" tabindex="37"
            lang="nb-NO">Norsk - Norge</a>
        </li>
        <li id="pl-PLListItem" class="ltrtext">
          <a class="btn btn-link" href="/?omkt=pl-PL" id="pl-PLRegionalLink" name="pl-PL" tabindex="38"
            lang="pl-PL">Polski - Polska</a>
        </li> -->
        <li id="pt-BRListItem" class="ltrtext">
          <a class="btn btn-link" href="/pt-br/windowsinsider/?omkt=pt-BR" id="pt-BRRegionalLink" name="pt-BR" tabindex="39"
            lang="pt-BR">Português - Brasil</a>
        </li>
        <!-- <li id="pt-PTListItem" class="ltrtext">
          <a class="btn btn-link" href="/?omkt=pt-PT" id="pt-PTRegionalLink" name="pt-PT" tabindex="40"
            lang="pt-PT">Português - Portugal</a>
        </li>
        <li id="ro-ROListItem" class="ltrtext">
          <a class="btn btn-link" href="/?omkt=ro-RO" id="ro-RORegionalLink" name="ro-RO" tabindex="41"
            lang="ro-RO">Română - România</a>
        </li>
        <li id="sk-SKListItem" class="ltrtext">
          <a class="btn btn-link" href="/?omkt=sk-SK" id="sk-SKRegionalLink" name="sk-SK" tabindex="42"
            lang="sk-SK">Slovenčina - Slovenská republika</a>
        </li>
        <li id="sl-SIListItem" class="ltrtext">
          <a class="btn btn-link" href="/?omkt=sl-SI" id="sl-SIRegionalLink" name="sl-SI" tabindex="43"
            lang="sl-SI">Slovenski - Slovenija</a>
        </li>
        <li id="sr-Latn-RSListItem" class="ltrtext">
          <a class="btn btn-link" href="/?omkt=sr-Latn-RS" id="sr-Latn-RSRegionalLink" name="sr-Latn-RS" tabindex="44"
            lang="sr-Latn-RS">Srpski - Rep. Srbija i Rep. Crna Gora</a>
        </li>
        <li id="fi-FIListItem" class="ltrtext">
          <a class="btn btn-link" href="/?omkt=fi-FI" id="fi-FIRegionalLink" name="fi-FI" tabindex="45"
            lang="fi-FI">Suomi - Suomi</a>
        </li>
        <li id="sv-SEListItem" class="ltrtext">
          <a class="btn btn-link" href="/?omkt=sv-SE" id="sv-SERegionalLink" name="sv-SE" tabindex="46"
            lang="sv-SE">Svenska - Sverige</a>
        </li>
        <li id="vi-VNListItem" class="ltrtext">
          <a class="btn btn-link" href="/?omkt=vi-VN" id="vi-VNRegionalLink" name="vi-VN" tabindex="47"
            lang="vi-VN">Tiếng Việt - Việt Nam</a>
        </li>
        <li id="tr-TRListItem" class="ltrtext">
          <a class="btn btn-link" href="/?omkt=tr-TR" id="tr-TRRegionalLink" name="tr-TR" tabindex="48"
            lang="tr-TR">Türkçe - Türkiye</a>
        </li>
        <li id="el-GRListItem" class="ltrtext">
          <a class="btn btn-link" href="/?omkt=el-GR" id="el-GRRegionalLink" name="el-GR" tabindex="49"
            lang="el-GR">Ελληνικά - Ελλάδα</a>
        </li>
        <li id="bg-BGListItem" class="ltrtext">
          <a class="btn btn-link" href="/?omkt=bg-BG" id="bg-BGRegionalLink" name="bg-BG" tabindex="50"
            lang="bg-BG">Български - България</a>
        </li> -->
        <li id="ru-RUListItem" class="ltrtext">
          <a class="btn btn-link" href="/ru-ru/windowsinsider/?omkt=ru-RU" id="ru-RURegionalLink" name="ru-RU" tabindex="51"
            lang="ru-RU">Русский - Россия</a>
        </li>
        <!-- <li id="uk-UAListItem" class="ltrtext">
          <a class="btn btn-link" href="/?omkt=uk-UA" id="uk-UARegionalLink" name="uk-UA" tabindex="52"
            lang="uk-UA">Українська - Україна</a>
        </li>
        <li id="he-ILListItem" class="rtltext">
          <a class="btn btn-link" href="/?omkt=he-IL" id="he-ILRegionalLink" name="he-IL" tabindex="53"
            lang="he-IL">עברית - ישראל</a>
        </li>
        <li id="arListItem" class="rtltext">
          <a class="btn btn-link" href="/?omkt=ar" id="arRegionalLink" name="ar" tabindex="54" lang="ar">عربي - المنطقة
            العربية</a>
        </li>
        <li id="th-THListItem" class="ltrtext">
          <a class="btn btn-link" href="/?omkt=th-TH" id="th-THRegionalLink" name="th-TH" tabindex="55" lang="th-TH">ไทย
            - ไทย</a>
        </li>
        <li id="ko-KRListItem" class="ltrtext">
          <a class="btn btn-link" href="/?omkt=ko-KR" id="ko-KRRegionalLink" name="ko-KR" tabindex="56" lang="ko-KR">한국어
            - 대한민국</a>
        </li> -->
        <li id="zh-CNListItem" class="ltrtext">
          <a class="btn btn-link" href="/zh-cn/windowsinsider/?omkt=zh-CN" id="zh-CNRegionalLink" name="zh-CN" tabindex="57"
            lang="zh-CN">中文(中国</a>
        </li>
        <li id="ja-JPListItem" class="ltrtext">
          <a class="btn btn-link" href="/ja-jp/windowsinsider/?omkt=ja-JP" id="ja-JPRegionalLink" name="ja-JP" tabindex="58" lang="ja-JP">日本語
            - 日本</a>
        </li>
        <!-- <li id="zh-TWListItem" class="ltrtext">
          <a class="btn btn-link" href="/?omkt=zh-TW" id="zh-TWRegionalLink" name="zh-TW" tabindex="59"
            lang="zh-TW">繁體中文 - 台灣</a>
        </li>
        <li id="zh-HKListItem" class="ltrtext">
          <a class="btn btn-link" href="/?omkt=zh-HK" id="zh-HKRegionalLink" name="zh-HK" tabindex="60"
            lang="zh-HK">繁體中文 - 香港特別行政區</a>
        </li> -->
      </ul>
    </div>
  </div>
</section>

<ng-container *ngIf="contentLoading">
  <shared-spinner></shared-spinner>
</ng-container>
