import { Component, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { take } from 'rxjs/operators';
import { AppInsightsErrorHandlerService } from 'src/app/core/app-insights-error-handler/app-insights-error-handler.service';
import { ContentService } from 'src/app/core/contentRT/contentRT.service';
import { winPrefix } from 'src/app/win-sv/win-prefix';
import { LocalizationService } from '../../core/localization/localization.service';
import { TelemetryService } from '../../core/telemetry/telemetry.service';

@Component({
  selector: 'app-socialfooter',
  templateUrl: './socialfooter.component.html',
  styleUrls: ['./socialfooter.component.scss']
})
export class SocialfooterComponent implements OnInit {

  public contentLoading = true;
  public socialfooter: any;
  private contentString = '';
  public showDisclaimer = false;

  constructor(
    private contentService: ContentService,
    private telemetryService: TelemetryService,
    private localizationService: LocalizationService,
    private _router: Router,
    private appInsightsErrorHandler: AppInsightsErrorHandlerService
  ) {
    this.showDisclaimer = this._router.routerState.snapshot.url.includes(winPrefix);
  }

  ngOnInit(): void {
    this._router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        this.showDisclaimer = event.url.includes(winPrefix);
      }
    });

    this.content();
    this.getContent();
  }

  private content(): void {
    const language = this.localizationService.getLanguage();
    // tslint:disable-next-line: max-line-length
    this.contentString = `footer/social.html&language=${language}`;
  }

  private getContent(): void {
    this.contentService.getCmsContent(this.contentString)
      .pipe(take(1))
      .subscribe((result) => {

        if (result.documents[0]) {
          this.socialfooter = result.documents[0].document;
        }

        this.contentLoading = false;
      }, err => {
        this.telemetryService.logError('Error geting developers list: ' + JSON.stringify(err));
        this.appInsightsErrorHandler.handleError('Error geting developers list: ' + JSON.stringify(err));
        this.contentLoading = false;
      });
  }
}
