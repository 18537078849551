import { Injectable } from "@angular/core";
import { Meta, Title } from "@angular/platform-browser";
import { environment } from "src/environments/environment";

@Injectable({
  providedIn: "root",
})
export class SeoService {
  constructor(private metaService: Meta,private title: Title) {}

  updateTitle(title: string) {
    this.title.setTitle(title);
  }

  updateOgUrl(url: string) {
    this.metaService.updateTag({ name: 'og:url', content: url })
  }

  updateDescription(desc: string) {
    this.metaService.updateTag({ name: 'description', content: desc })
  }

  updateOgDescription(ogdesc: string) {
    this.metaService.updateTag({ name: 'og:description', content: ogdesc })
  }

  updateOgImage(img: string) {
    this.metaService.updateTag({name: 'og:image', content: img})
  }
  updateOgSecureImage(img: string) {
    this.metaService.updateTag({name: 'og:image:secure_url', content: img})
  }
  updateOgTitle(title: string) {
    this.metaService.updateTag({ name: 'og:title', content: title })
  }

  updateTwitterTitle(title: string) {
    this.metaService.updateTag({ name: 'twitter:title', content: title })
  }
  updateTwitterDescription(desc: string) {
    this.metaService.updateTag({ name: 'twitter:description', content: desc })
  }

  updateTwitterImage(img: string) {
    this.metaService.updateTag({ name: 'twitter:image', content: img })
  }

  updateTwitterCard(card: string) {
    this.metaService.updateTag({ name: 'twitter:card', content: card })
  }

  updateTwitterUrl(url: string) {
    this.metaService.updateTag({ name: 'twitter:url', content: url })
  }

  updateTwitterSecureImage(img: string) {
    this.metaService.updateTag({name: 'twitter:image:secure_url', content: img})
  }
  
  public setRobots() {
    if (environment.production) {
      this.metaService.updateTag({
        name: "robots",
        content: "index, follow",
      });
    }
  }
}


// import { Injectable } from "@angular/core";
// import { Meta, Title } from "@angular/platform-browser";
// import { environment } from "src/environments/environment";

// @Injectable({
//   providedIn: "root",
// })
// export class SeoService {
//   // constructor(private metaService: Meta) {}

//   constructor(private title: Title, private meta: Meta) { }

//   // public setRobots() {
//   //   if (environment.production) {
//   //     this.metaService.updateTag({
//   //       name: "robots",
//   //       content: "index, follow",
//   //     });
//   //   }
//   // }

//   updateTitle(title: string) {
//     this.title.setTitle(title);
//   }

//   updateOgUrl(url: string) {
//     this.meta.updateTag({ name: 'og:url', content: url })
//   }

//   updateDescription(desc: string) {
//     this.meta.updateTag({ name: 'description', content: desc })
//   }
// }
