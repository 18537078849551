import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root',
})
export class LocalizationService {

    public language: string;
    public market: string;
    public setLanguage(lang: string) {
        if ((lang === 'de-at') || (lang === 'de-ch')) {
            this.language = 'de-de';
            // tslint:disable-next-line: max-line-length
        } else if ((lang === 'es') || (lang === 'es/') || (lang === 'es-ar') || (lang === 'es-cl') || (lang === 'es-co') || (lang === 'es-mx')) {
            this.language = 'es-es';
        } else if ((lang === 'fr') || (lang === 'fr/') || (lang === 'fr-be') || (lang === 'fr-ca') || (lang === 'fr-ch')) {
            this.language = 'fr-fr';
            // tslint:disable-next-line: max-line-length
        } else if ((lang === 'en-ca') || (lang === 'en-gb') || (lang === 'en-au') || (lang === 'en-ie') || (lang === 'en-in') || (lang === 'en-my') || (lang === 'en-nz') || (lang === 'en-sg') || (lang === 'en-za')) {
            this.language = 'en-us';
        } else if ((lang === 'ar') || (lang === 'ar/')) {
            this.language = 'ar-sa';
        } else if (lang === 'nl-be') {
            this.language = 'nl-nl';
        } else if (lang === 'zh-hk') {
            this.language = 'zh-tw';
        } else {
            this.language = lang;
        }
        if (this.language === 'sr-latn-rs') {
            this.market = 'rs';
        } else {
            this.market = this.language.slice(3);
        }
    }

    /**
 * 1	English US	EN-US
2	German	DE-DE
3	Spanish	ES-ES
4	French	FR-FR
5	Japanese	JA-JP
6	Portuguese	PT-BR
7	Russian	RU-RU
8	Chinese (Simplified)	ZH-CN 
9	Italian	IT-IT

 */
    public getLanguage() {
        const lang = this.language?.toLowerCase();
        if (lang === 'en-us') {
            return 'en-us';
        }

        // fr-FR, de-DE, es-ES, pt-BR
        if (lang === 'de-de' || lang === 'ja-jp' || lang === 'fr-fr' || lang === 'pt-br'
            || lang === 'zh-cn' || lang === 'es-es' || lang === 'it-it' || lang === 'ru-ru') {
            return this.language;
        } else {
            return 'en-us';
        }
    }

    /**
     * Some things are not localized like Podcasts so always get English
     */
    public getEnUsLanguage() {
        return 'en-us';
    }



    public getMarket() {
        return this.market;
    }

    public getLocaleCookie() {
        let locale = this.getCookie('locale');

        if (locale === '') {
            locale = 'en-us';
        }

        return locale;
    }

    private getCookie(cname: string) {
        const name = cname + '=';
        const ca = document.cookie.split(';');
        for (let i = 0; i < ca.length; i++) {
            let c = ca[i];
            while (c.charAt(0) === ' ') {
                c = c.substring(1);
            }
            if (c.indexOf(name) === 0) {
                return c.substring(name.length, c.length);
            }
        }
        return '';
    }
}
