import { DOCUMENT } from "@angular/common";
import {
  Component,
  EventEmitter,
  Inject,
  Input,
  NgZone,
  OnDestroy,
  OnInit,
  Output,
  PLATFORM_ID
} from "@angular/core";
import { DomSanitizer, SafeHtml } from "@angular/platform-browser";
import { Router } from "@angular/router";
import { AuthProviderConfigType } from "@mecontrol/public-api";
import { Subscription } from "rxjs";
import { environment } from "src/environments/environment";
import { AppInsightsErrorHandlerService } from "../../app-insights-error-handler/app-insights-error-handler.service";
import { MsalHelperService } from "../../auth/msalHelper.service";
import { IFlightSettings } from "../../core.model";
import { TelemetryService } from "../../telemetry/telemetry.service";
import { UhfService } from "../uhf.service";
import { WindowRef } from "../windowref";
import { HeaderLoaderService } from "../header-loader.service";

declare var window: any;

@Component({
  selector: "shared-uhf-header",
  templateUrl: "./uhf-header.component.html",
})
export class UhfHeaderComponent implements OnInit, OnDestroy {
  private authCompletedSubscription: Subscription;
  @Input() user: IFlightSettings;
  public loading = true;
  public cssLoading = true;
  public uhfNav: SafeHtml;
  public uhfJavaScript: string;
  public uhfCss: string;
  public lang: string;

  @Output() uhfLoading = new EventEmitter();
  @Output() setPageMeta = new EventEmitter();

  constructor(
    private uhfService: UhfService,
    private msalHelperService: MsalHelperService,
    private sanitizer: DomSanitizer,
    private windowRef: WindowRef,
    private router: Router,
    private zone: NgZone,
    private telemetryService: TelemetryService,
    private appInsightsErrorHandler: AppInsightsErrorHandlerService,
    @Inject(PLATFORM_ID) private platformId: any,
    @Inject(DOCUMENT) private document: any,
    private headerLoaderService : HeaderLoaderService
  ) {
    this.loadWCP();
   }

  ngOnInit() {
    this.lang = this.getCookie("locale");

    if (this.lang === "") {
      this.lang = "en-us";
    }

    let targeting = "UserRegistered";
    // if ((this.user === undefined || this.user === null) || (this.user !== null && !this.user.isRegisteredPilot)) {
    //   targeting = 'UserNotRegistered';
    // }

    this.uhfService.getUhf(this.lang, targeting).subscribe(
      (result) => {
        if (result.communityHeaderHtml !== "") {
          this.setUhfSections(result);
        } else {
          this.uhfService.getUhf("en-us", targeting).subscribe((res) => {
            this.setUhfSections(res);
          });
        }
      },
      (err) => {
        this.telemetryService.logError(err);
        this.appInsightsErrorHandler.handleError(err);
      }
    );
  }

  ngOnDestroy() {
    this.authCompletedSubscription.unsubscribe();
  }

  getMeta() {
    this.setPageMeta.emit();
  }

  private getCookie(cname: string) {
    const name = cname + "=";
    const ca = document.cookie.split(";");
    for (let i = 0; i < ca.length; i++) {
      let c = ca[i];
      while (c.charAt(0) === " ") {
        c = c.substring(1);
      }
      if (c.indexOf(name) === 0) {
        return c.substring(name.length, c.length);
      }
    }
    return "";
  }

  private setUhfSections(uhfReturn) {
    const headerElement = uhfReturn.communityHeaderHtml;
    this.uhfNav = this.sanitizer.bypassSecurityTrustHtml(headerElement);

    const jsElement = uhfReturn.javaScriptIncludes;
    this.uhfJavaScript = jsElement;

    this.loadUhfJs();

    const cssElement = uhfReturn.cssIncludes;
    this.uhfCss = cssElement;

    const footerElement = uhfReturn.footerHtml;
    const uhfFooter = this.sanitizer.bypassSecurityTrustHtml(footerElement);
    this.uhfService.setFooterContent(uhfFooter);

    this.loadUhfCss();

    setTimeout(() => {
      this.loading = false;
    }, 1);

    setTimeout(() => {
      this.loadShellWrapper();
    }, 1);
  }

  // Function to load the shell
  private loadShellWrapper() {
    if (window.msCommonShell != null) {
      this.loadShell();
    } else {
      window.onShellReadyToLoad = () => {
        window.onShellReadyToLoad = null;
        this.loadShell();
      };
    }
    const uhfElement = this.document.getElementById("uhf-header");
    uhfElement.style.display = "block";
  }

  private loadUhfJs() {
    // const head = document.getElementsByTagName("head")[0];
    const div = document.createElement("div");
    div.innerHTML = this.uhfJavaScript;
    const initialNodeCount = div.childNodes.length - 1; // do not use this in the loop below because it changes
    for (let i = 0; i <= initialNodeCount; i++) {
      // this div.childNodes[0] needs to be 0 and not i
      // Details here https://developer.mozilla.org/en-US/docs/Web/API/Node/appendChild
      const newScript = document.createElement("script");
      newScript.src = (div.childNodes[i] as HTMLScriptElement).src;

      if (newScript.src.indexOf("mem.gfx.ms", 0) === -1 && newScript.src.indexOf('wcpstatic.microsoft.com', 0) === -1) {
        document.head.insertBefore(newScript, document.head.childNodes[0]);
      }
    }
    setTimeout(() => {
      this.loadOptIn();
      // console.log("UHF JS loaded");
    }, 100);

    let counter = 0;
    const uhfHeaderIntervalLoop = setInterval(
      () => {
        counter++;
        if(window.WcpConsent && window.WcpConsent.siteConsent && window.WcpConsent.siteConsent.getConsent){
          this.headerLoaderService.notifyHeaderLoaded();
          clearInterval(uhfHeaderIntervalLoop);
          // console.log(window.WcpConsent.siteConsent.getConsent);
        } else if(counter >= 1000){
          this.headerLoaderService.notifyHeaderLoaded();
          this.telemetryService.logError("window.WcpConsent.siteConsent.getConsent is not getting initialized");
          this.appInsightsErrorHandler.handleError("window.WcpConsent.siteConsent.getConsent is not getting initialized");
          clearInterval(uhfHeaderIntervalLoop);
        }
      },10
    )

  }

  private loadUhfCss() {
    const div = document.createElement("div");
    div.innerHTML = this.uhfCss;
    const initialNodeCount = div.childNodes.length - 1; // do not use this in the loop below because it changes
    for (let i = 0; i <= initialNodeCount; i++) {
      // this div.childNodes[0] needs to be 0 and not i
      // Details here https://developer.mozilla.org/en-US/docs/Web/API/Node/appendChild
      const link = document.createElement("link");
      link.rel = "stylesheet";
      link.type = "text/css";
      link.href = (div.childNodes[i] as HTMLLinkElement).href;
      link.media = "all";
      //  document.head.insertBefore(link, document.head.childNodes[0]);
      // document.head.appendChild(link);
    }
  }

  private loadOptIn() {
   if (environment.production) {
      const newScript = document.createElement("script");
      newScript.type = "text/javascript";
      newScript.src = "../windowsinsider/assets/optin.js";
      document.head.appendChild(newScript);
    }
  }

  private loadWCP() {
    const newScript = document.createElement("script");
    newScript.type = "text/javascript";
    newScript.src = environment.wcpConsentJSUrl;
    document.body.appendChild(newScript);
   }

  

  // Function to load the Microsoft Common Shell
  private loadShell(): void {
    var shellOptions = {
      meControlOptions: {
        apiGeneration: "GEN1",
        currentAccount: this.msalHelperService.getCurrentAccount(),
        authProviderConfig: {
          type: AuthProviderConfigType.PartnerManaged,
          // Remembered accounts config
          aad: this.msalHelperService.createRememeberedAADAccountsConfig(),
          signIn: this.msalHelperService.signInOrSwitch,
          signInTo: this.msalHelperService.signInToOrSwitchTo,
          signOutFromApp: this.msalHelperService.signOut,
          // switch: this.msalHelperService.signInSwitch,
          switchTo: this.msalHelperService.signInToOrSwitchTo,
          appSignInUrl: this.msalHelperService.signInOrSwitch,
          appSignInToUrl: this.msalHelperService.signInToOrSwitchTo,
          appSignOutUrl: this.msalHelperService.signOut,
          // appSwitchUrl: this.msalHelperService.signInSwitch,
          appSwitchToUrl: this.msalHelperService.signInToOrSwitchTo,
        },
      },
    };

    this.windowRef.nativeWindow.msCommonShell.load(shellOptions);
    
    // Below code checks whether the user is logged in or not and updates windows owap accordingly
    if(shellOptions && shellOptions.meControlOptions){
      if(shellOptions.meControlOptions.currentAccount && 
        shellOptions.meControlOptions.currentAccount['authenticatedState'] && 
        shellOptions.meControlOptions.currentAccount['authenticatedState'] === 'signedIn'){
          this.telemetryService.updateLoggedInOwap(true);
      } else {
        this.telemetryService.updateLoggedInOwap(false);

      }
    }

    this.overrideUHFNavigation();
  }

  // Function to override the UHF behavior that causes UHF reload on view change
  private overrideUHFNavigation() {
    // if (isPlatformBrowser(this.platformId)) {
    //   const ctaElement = this.document.getElementById('c-uhf-nav-cta');
    //   ctaElement.style.display = "none";
    // }

    let nav;

    nav = document.getElementById("uhf-g-nav");

    if (nav === undefined) {
      nav = document.getElementById("uhf-c-nav");
    }

    const anchors = nav.getElementsByTagName("a");
    const self = this;

    for (let i = 0; i < anchors.length; i++) {
      // Acitivty Component Pages and Blog need standard nav for proper functionality
      if ((anchors[i].id !== 'nav_flight-hub' && anchors[i].id !== 'nav-webcasts' && anchors[i].id !== 'nav-youtube' && anchors[i].id !== 'nav-answers-forum' && anchors[i].id !== 'nav_latest-features' && anchors[i].id !== 'nav_deeper-look-at-flighting' && anchors[i].id !== 'nav-deeper-look-at-feedback' && anchors[i].id !== 'nav_flight-blog-posts' && anchors[i].id !== 'nav-feedback-hub' && anchors[i].id !== 'nav-other-programs' && anchors[i].id !== 'nav-twitter' && anchors[i].id !== 'nav-facebook' && anchors[i].id !== 'nav-instagram')) {
        anchors[i].addEventListener(
          "click",
          (event) => {
            event.preventDefault();
            self.globalNavigationHandler(event.target.pathname);
          },
          false
        );
      }
    }
  }

  // Function to handle UHF navigation change
  private globalNavigationHandler(path: string) {
    const newPath = path.replace(this.lang, "").replace("//", "");
    this.zone.run(() => this.router.navigate([newPath]));
  }
}
