import { Component } from '@angular/core';

@Component({
    selector: 'shared-spinner',
    template: `
    <section class="container">
    <div class="row">
      <div class="col-12">
        
<div id="spinner-1" class="spinner" tabindex="-1" role="alert" aria-live="assertive" style="margin: 0 auto;margin-top: 5%;margin-bottom: 5%;">
<span class="sr-only">Content loading...</span>
<span role="presentation" class="spinner-dot spinner-dot-1"></span>
<span role="presentation" class="spinner-dot spinner-dot-2"></span>
<span role="presentation" class="spinner-dot spinner-dot-3"></span>
<span role="presentation" class="spinner-dot spinner-dot-4"></span>
<span role="presentation" class="spinner-dot spinner-dot-5"></span>
</div>
      </div>
    </div>
  </section>
    `,
    styleUrls: ['./spinner.component.scss']
})
export class SharedSpinnerComponent {
}
