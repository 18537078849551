import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { LocalizationService } from './../../core/localization/localization.service';
import { UtilityService } from './../../core/utility/utility.service';
import { IPodcasts } from './podcast.model';


@Injectable({
  providedIn: 'root'
})
export class PodcastsService {

  constructor(private http: HttpClient,
    private utilityService: UtilityService,
    private localizationService: LocalizationService) { }

  public getContents(page: number, slug: string = '', date: string = ''): Observable<IPodcasts> {
    let slugFilter = '';
    let dateFilter = '';
    const skip = Number(environment.pagingSize) * (page - 1);
    const pageSize = environment.pagingSize;

    if (slug) {
      slugFilter = `and slug eq '${slug}'`;
    }

    if (date) {
      dateFilter = this.utilityService.getDateFilterQuery(date);
    }

    // tslint:disable-next-line: max-line-length
    const searchUrl = `${environment.urls.azureSearchApiUrl}api-version=2019-05-06&$count=true&$top=${pageSize}&$skip=${skip}&$orderby=created_date desc&$filter=postType eq 'podcast' ${dateFilter}  and language eq '${this.localizationService.getEnUsLanguage()}' ${slugFilter}`;

    return this.http.get<IPodcasts>(searchUrl, {
      headers: new HttpHeaders().set('api-key', environment.azureSearchKey)
    });
  }
}
