import { Component, OnInit } from '@angular/core';
import { ContentService } from '../core/contentRT/contentRT.service';
import { LocalizationService } from '../core/localization/localization.service';
import { TelemetryService } from '../core/telemetry/telemetry.service';

@Component({
  selector: 'app-locale',
  templateUrl: './locale.component.html',
  styleUrls: ['./locale.component.scss']
})
export class LocaleComponent implements OnInit {

  public meta: any;
  public contentLoading = true;
  private contentString = '';

  constructor(private localizationService: LocalizationService, private contentService: ContentService,
    private telemetryService: TelemetryService) { }

  public ngOnInit() {
    //this.getContent();
    this.contentLoading = false;
  }

  // private getContent(): void {
  //   const language = this.localizationService.getLanguage();
  //   // tslint:disable-next-line: max-line-length
  //   this.contentString = `meta/meta.json&language=${language}`;

  //   this.contentService.getCmsContent(this.contentString)
  //     .pipe(take(1))
  //     .subscribe((result) => {
  //       this.meta = JSON.parse(result.documents[0].document);
  //       this.contentLoading = false;
  //     }, err => {
  //       this.telemetryService.logError('Error getting locale: ' + JSON.stringify(err));
  //       this.contentLoading = false;
  //     });
  // }

}
