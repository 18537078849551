import { Component, OnDestroy, OnInit } from '@angular/core';
import { SafeHtml } from "@angular/platform-browser";
import { Router, NavigationEnd } from '@angular/router';
import { Location } from '@angular/common';
import { Subscription } from 'rxjs';
import { UhfService } from '../uhf.service';
import { winPrefix } from '../../../win-sv/win-prefix';
import { AppInsightsErrorHandlerService } from '../../app-insights-error-handler/app-insights-error-handler.service';

@Component({
  selector: 'shared-uhf-footer',
  templateUrl: './uhf-footer.component.html'
})
export class UhfFooterComponent implements OnInit, OnDestroy {
  private uhfSubscription: Subscription;
  public uhfFooter: SafeHtml;
  constructor(private uhfService: UhfService, private _router: Router, private location: Location,private appInsightsErrorHandler: AppInsightsErrorHandlerService) {

  }



  ngOnInit() {

    this.uhfSubscription = this.uhfService.getFooterContent().subscribe(footer => {
      setTimeout(() => {
        this.uhfFooter = footer;
      }, 300);

    }, err => {
      
      //  this.telemetryService.logError('Error getting page title: ' + JSON.stringify(err));
      // console.log(err);
      this.appInsightsErrorHandler.handleError('Error getting page title: ' + JSON.stringify(err));
    });
  }

  public ngOnDestroy() {
    this.uhfSubscription.unsubscribe();
  }
}
