import { Component, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';
import { take } from 'rxjs/operators';
import { AppInsightsErrorHandlerService } from 'src/app/core/app-insights-error-handler/app-insights-error-handler.service';
import { ContentService } from 'src/app/core/contentRT/contentRT.service';
import { IFlightSettings } from 'src/app/core/core.model';
import { LocalizationService } from 'src/app/core/localization/localization.service';
import { TelemetryService } from 'src/app/core/telemetry/telemetry.service';
import { UserService } from 'src/app/core/user.service';

@Component({
  selector: 'app-open',
  templateUrl: './open.component.html',
  styleUrls: ['./open.component.scss']
})
export class FeedbackHubOpenComponent implements OnInit {

  public userSettingsSubscription: Subscription;
  public user: IFlightSettings;
  private contentString = '';
  public contentLoading = true;
  public pageContent: any;
  public redirectSrc = 'https://aka.ms/WIPFeedbackHub';

  constructor(
    private contentService: ContentService,
    private telemetryService: TelemetryService,
    private localizationService: LocalizationService,
    private userService: UserService,
    private activatedRoute: ActivatedRoute,
    private sanitizer: DomSanitizer,
    private appInsightsErrorHandler: AppInsightsErrorHandlerService
  ) { }

  ngOnInit(): void {
    this.getContent();
  }

  public sanitize(url:string){
    return this.sanitizer.bypassSecurityTrustUrl(url);
  }

  private openFeedbackHub() {
    this.checkQueryStrings();
  }


  private checkQueryStrings(): void {
    this.activatedRoute.queryParams
      // .filter(params => params.state)
      .subscribe(params => {
        if (params !== undefined && params !== null) {
          if(params.contenttype != undefined && params.contenttype == 'quest') {
            //?contentid=39ee648e-ed63-4127-bd87-676fb2046b92&contenttype=quest&utm_source=product-placement&utm_medium=feedback-hub&utm_campaign=feedback-hub-redirect
            this.redirectSrc = "insiderhub://quest/"+params.contentid;
            this.contentLoading = false;
            window.location.href = this.redirectSrc;
          } else if (params.contenttype != undefined && params.contenttype == 'announcements') {
            this.contentLoading = false;
              //contentid=beb5012f-e016-41af-b142-3c0aec50677e&contenttype=announcements&utm_source=product-placement&utm_medium=feedback-hub&utm_campaign=feedback-hub-redirect
              this.redirectSrc = "insiderhub://announcements/"+params.contentid;
              window.location.href = this.redirectSrc;
            } else {
              this.redirectSrc = "feedback-hub:?contextid="+params.contextid+"&feedbackid="+params.feedbackid+"&form=1";
              this.contentLoading = false;
              window.location.href = this.redirectSrc;
          }
        }
      });
  }

  private getContent(): void {
    const language = this.localizationService.getLanguage();
    this.contentString = `errors/open-feedback-hub.json&language=${language}`;
    
    this.contentService.getCmsContent(this.contentString)
      .pipe(take(1))
      .subscribe((result) => {
        this.pageContent = JSON.parse(result.documents[0].document);

        this.openFeedbackHub();
      }, err => {
        this.telemetryService.logError(`Error getting FeedbackHubOpenComponent: ${JSON.stringify(err)}`);
        this.appInsightsErrorHandler.handleError(`Error getting FeedbackHubOpenComponent: ${JSON.stringify(err)}`);
        this.contentLoading = false;
      });
  }

}
