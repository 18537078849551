import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ContentService } from '../../core/contentRT/contentRT.service';
import { LocalizationService } from '../../core/localization/localization.service';

@Injectable({
  providedIn: 'root'
})

export class ContestsService {

  constructor(
    private localizationService: LocalizationService,
    private contentService: ContentService) { }

  public getMetaSettings(): Observable<any> {
    const language = this.localizationService.getLanguage();
    const contentString = `contest/contest.html&language=${language}`;
    return this.contentService.getCmsContent(contentString);
  }
}
