import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { SafeHtml } from "@angular/platform-browser";
import { BehaviorSubject, Observable } from 'rxjs';
// import { OAuthService } from 'angular-oauth2-oidc';
import { environment } from '../../../environments/environment';
import { MsalHelperService } from '../auth/msalHelper.service';
import { IUhfContent, IUser } from '../core.model';
import { TelemetryService } from '../telemetry/telemetry.service';
import { UserService } from '../user.service';

@Injectable()
export class UhfService {

  private footerContentSource = new BehaviorSubject<SafeHtml>('');
  public meControlAuthState: number;
  public meControlProperties: IUser;
  public ufhContent: IUhfContent;

  constructor(private http: HttpClient,
    private telemetryService: TelemetryService,
    private userService: UserService,
    private authService: MsalHelperService) {
    this.meControlAuthState = 0;
  }

  public getUhf(lang: string, targeting: string): Observable<IUhfContent> {
    return this.http.get<IUhfContent>
      (environment.urls.uhf + lang + '&usedefault=false&overrideUhfConfig=true&shellPartnerId=' + environment.uhfPartnerId +
        '&shellFooterId=' + environment.uhfFooterId + '&shellHeaderId=' + environment.uhfHeaderId + '&useUHFCache=false&Targeting=' + targeting);
  }

  public getFooterContent(): Observable<SafeHtml> {
    return this.footerContentSource.asObservable();
  }

  public setFooterContent(footerContent: SafeHtml) {
    this.footerContentSource.next(footerContent);
  }


  public loginUrl(param = ''): string {
    let url = environment.oAuthLoginUrl
      + '?client_id=' + environment.msaClientId
      + '&scope=Ambassadors.API&response_type=token'
      + '&redirect_uri=' + environment.oAuthRedirectUrl;

    if (param === '') {
      return url;
    } else {
      url = url + '?' + param;
      return url;
    }
  }


  public getMeControlAuthState(): number {
    return this.authService.getCurrentAccount() ? 1 : 0;
  }
}
