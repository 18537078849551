import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, of, ReplaySubject, Subject } from 'rxjs';
import { environment } from '../../environments/environment';
import { FlightSettings, IFlightSettings, IUserEligibility } from './core.model';
import { LocalizationService } from './localization/localization.service';
import { TelemetryService } from './telemetry/telemetry.service';


@Injectable({
  providedIn: 'root',
})
export class UserService {

  private userSubject$: Subject<IFlightSettings> = new ReplaySubject(1);
  private processingUser$: BehaviorSubject<boolean> = new BehaviorSubject(false);
  private user: IFlightSettings;
  private isAuthenticated = false;

  constructor(
    private http: HttpClient,
    private localizationService: LocalizationService,
    private telemetryService: TelemetryService
  ) { }


  public processingUser(): Observable<boolean> {
    console.log('processingUser');
    return this.processingUser$.asObservable();
  }

  public setProcessingUser(status: boolean) {
    this.processingUser$.next(status);
    this.processingUser$.complete();
    return this.processingUser$;
  }

  public setIsAuthenticated(status: boolean) {
    this.isAuthenticated = status;
  }

  public getIsAuthenticated(): boolean {
    return this.isAuthenticated;
  }

  public setUser(user: IFlightSettings) {
    this.user = user;
  }

  public getUser(): IFlightSettings {
    return this.user;
  }

  // public getUserSettings(): Observable<IFlightSettings> {
  //   console.log('getUserSettings');
  //   return this.userSubject$.asObservable();
  // }

  public getUserSettings(forRefresh = false): Observable<IFlightSettings> {
    // console.log('getFlightUser');
    if (this.getIsAuthenticated()) {
      // this.initFetchingWindowsInsiderUser().subscribe(result => {
      //   this.setUser(result);
      //   return result;
      // })

      // Use the service cache.
      if (this.user) {
        // console.log("user from cache");
        return of<IFlightSettings>(this.user);
      } else {
        // console.log("user from service");
        return this.http.get<IFlightSettings>(environment.urls.windowsInsiderUrls.userSettings);
      }
    } else {
      // console.log('not authenticated default user object');
      const user = new FlightSettings(false,
        false,
        false,
        false,
        [],
        [],
        [],
        [],
        '',
        '',
        false,
        false,
        false,
        '',
        '',
        false);
      return of<IFlightSettings>(user);
    }
  }

  public setNewIdentity(user: IFlightSettings) {
    user.isRegisteredPilot = true;
    this.userSubject$.next(user);
    this.userSubject$.complete();
    return this.userSubject$;
  }

  public setUserSettings(user: IFlightSettings) {
    if (user === null) {
      this.user = null;
    }
    // console.log('setUserSettings ' + user);
    this.userSubject$.next(user);
    this.userSubject$.complete();
    return this.userSubject$
  }


  public initFetchingWindowsInsiderUser(): Observable<IFlightSettings> {
    // this.processingUser$.next(true);
    // this.processingUser$.complete();
    return this.http.get<IFlightSettings>(environment.urls.windowsInsiderUrls.userSettings);
  }

  public leaveProgram(): Observable<any> {
    this.setUser(null);
    return this.http.post(environment.urls.windowsInsiderUrls.leaveProgram, '');
  }

  public leaveOrgProgram(): Observable<any> {
    return this.http.post(environment.urls.windowsInsiderUrls.leaveOrgProgram, '');
  }

  public getTerms() {
    const language = this.localizationService.getLanguage();
    return this.http.get(environment.urls.windowsInsiderUrls.terms + `?language=${language}`, { responseType: 'text' });
  }

  public register(referralCode: string): Observable<any> {
    this.setUser(null);
    if (referralCode == '' || referralCode == null || referralCode == undefined) {
      return this.http.post<IFlightSettings>(environment.urls.windowsInsiderUrls.register, '');
    } else {
      return this.http.post<IFlightSettings>(environment.urls.windowsInsiderUrls.register + "/" + referralCode, '');
    }
  }

  public registerOrg(): Observable<any> {
    this.setUser(null);
    return this.http.post<IFlightSettings>(environment.urls.windowsInsiderUrls.registerOrg, '');
  }

  public eligibility(): Observable<any> {
    return this.http.get<IUserEligibility>(environment.urls.windowsInsiderUrls.eligibility);
  }
}
