import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class HeaderLoaderService {

  private headerLoaaderSubject = new Subject<void>();
  headerLoaded$ =  this.headerLoaaderSubject.asObservable();

  constructor() { }

  notifyHeaderLoaded(){
    this.headerLoaaderSubject.next();
  }
  
}
