<section id="search" class="container">

  <div class="row" *ngIf="!loading && meta">
    <div class="col-10">
      <h1 class="h2">{{meta.searchText}} {{searchText}}</h1>
    </div>
    <div class="col-2">
      <span>{{totalRecordsText}} {{meta.results}}</span>
    </div>
  </div>

  <!-- Loading Spinner -->
  <div *ngIf="loading" class="col-12">
    <shared-spinner></shared-spinner>
  </div>

  <!-- Search Results -->
  <div class="row" *ngIf="!loading">
    <div class="col-10">
      <!-- Actual Results -->
      <div *ngFor="let result of results">
        <div class="col-12">
          <h3 class="h4 mb-0">
            <a class="cta" (click)="goToResult(getUrl(result.postType, result.slug))" href="JavaScript:void(0);"
              style="text-decoration:none;">{{result.title}}</a>
          </h3>
          <span class="">
            <a style="text-decoration:none;" class="cta"
              href="{{getUrl(result.postType, result.slug)}}">{{getUrl(result.postType, result.slug)}}</a>
          </span>
          <p>{{result.excerpt}}</p>
        </div>
      </div>
    </div>


  </div>

  <!-- Paging Component -->
  <div *ngIf="!loading">
    <app-paging [data]="results" [totalRecords]="totalRecords" (pageAction)="pageAction($event)" [page]="page">
    </app-paging>
  </div>

</section>
