import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { UtilityService } from 'src/app/core/utility/utility.service';
import { PagingService } from 'src/app/shared/paging/paging.service';
import { environment } from 'src/environments/environment';
import { ContentService } from '../../core/contentRT/contentRT.service';
import { LocalizationService } from '../../core/localization/localization.service';
import { IArticles } from './article.model';


@Injectable({
  providedIn: 'root',
})
export class ArticleService {

  constructor(
    private http: HttpClient,
    private localizationService: LocalizationService,
    private contentService: ContentService,
    private pagingService: PagingService,
    private utilityService: UtilityService,
  ) { }

  // // call this when the application boots up and it will allow component pages to get paging settings from cache
  // public getArticleMetaSettings(): Observable<any> {
  //   const language = this.localizationService.getLanguage();
  //   const contentString = `information/info-meta.json&language=${language}`;
  //   return this.contentService.getCmsContent(contentString);
  // }

  public getArticles(page: number, slug: string = '', categories: string[] = [], date: string = ''): Observable<IArticles> {
    const todaysDate = new Date().toISOString().slice(0, 10);
    const skip = Number(environment.pagingSize) * (page - 1);
    let slugFilter = '';
    let dateFilter = '';
    let categoryFilter = '';
    const pageSize = environment.pagingSize;

    if (slug) {
      slugFilter = `and slug eq '${slug}'`;
    }

    if (date) {
      dateFilter = this.utilityService.getDateFilterQuery(date);
    }

    if (categories.length > 0) {
      categoryFilter = this.utilityService.getCategoryFilterQuery(categories);
    }

    // tslint:disable-next-line: max-line-length
    const searchUrl = `${environment.urls.azureSearchApiUrl}api-version=2019-05-06&$count=true&$top=${pageSize}&$skip=${skip}&$orderby=created_date desc&$filter=postType eq 'post' and language eq '${this.localizationService.getLanguage()}' ${slugFilter} ${categoryFilter} ${dateFilter}`;

    return this.http.get<IArticles>(searchUrl, {
      headers: new HttpHeaders().set('api-key', environment.azureSearchKey)
    });
  }
}
