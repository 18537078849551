import { AfterViewInit, Component, OnInit, Renderer2, ViewChild } from '@angular/core';
import { Subscription } from 'rxjs';
import { take } from 'rxjs/operators';
import { AppInsightsErrorHandlerService } from 'src/app/core/app-insights-error-handler/app-insights-error-handler.service';
import { MsalHelperService } from 'src/app/core/auth/msalHelper.service';
import { ContentService } from '../../core/contentRT/contentRT.service';
import { IFlightSettings } from '../../core/core.model';
import { LocalizationService } from '../../core/localization/localization.service';
import { PageNameTypes } from '../../core/telemetry/telemetry.model';
import { TelemetryService } from '../../core/telemetry/telemetry.service';
import { UserService } from '../../core/user.service';

declare var mwf: any;

@Component({
  selector: 'app-referral',
  templateUrl: './referral.component.html',
  styleUrls: ['./referral.component.scss']
})
export class ReferralComponent implements OnInit, AfterViewInit {

  @ViewChild('referralLinkCopy') referralLinkCopy: { nativeElement: any; };

  public referralLink = '';
  // tslint:disable-next-line: max-line-length
  // public referralEmail = 'mailto:?Subject=Join me in the Windows Insider Program&body=I love getting sneak previews and trying new features, which is why I am a Windows Insider. Join me to get early access to Windows 10 Insider Preview builds to try out upcoming features and give feedback that will help improve the Windows experience for everyone.%0D%0A%0D%0AUse this link to register and start using Insider previews now:%0D%0A';
  // tslint:disable-next-line: max-line-length
  // public referralTweet = 'https://twitter.com/intent/tweet?text=I love being a %23WindowsInsider who gets to try new features first and share feedback that helps shape the future of %23Windows. And I think you will love being an Insider too!%0a%0aUse my referral link to register and get started:&url=';
  public referralEmail: string;
  public referralTweet: string;

  public userSettingsSubscription: Subscription;
  public userSettings: IFlightSettings;
  public mouseLeaveListener: any;
  private contentString = '';
  public contentLoading = true;
  public metaData: any;
  public user: IFlightSettings;
  public userLoaded = false;
  public isRegistered = false;

  constructor(
    private telemetryService: TelemetryService,
    private userService: UserService,
    private localizationService: LocalizationService,
    private contentService: ContentService,
    private renderer: Renderer2,
    private msalService: MsalHelperService,
    private appInsightsErrorHandler: AppInsightsErrorHandlerService
  ) { }

  ngOnInit() {
    this.telemetryService.capturePageView(this.localizationService.getLanguage(), PageNameTypes.Referral);
    this.getContent();
  }

  clearTooltip() {
    this.renderer.removeClass(this.referralLinkCopy.nativeElement, 'tooltipped');
    this.renderer.removeClass(this.referralLinkCopy.nativeElement, 'tooltipped-s');
    this.renderer.removeAttribute(this.referralLinkCopy.nativeElement, 'aria-label');
  }

  copied(text: string) {
    this.renderer.addClass(this.referralLinkCopy.nativeElement, 'tooltipped');
    this.renderer.addClass(this.referralLinkCopy.nativeElement, 'tooltipped-s');
    this.renderer.setAttribute(this.referralLinkCopy.nativeElement, 'aria-label', 'Copied!');
  }

  ngAfterViewInit() {
    // this.mouseLeaveListener = this.renderer.listen(this.referralLinkCopy.nativeElement, 'mouseleave', this.clearTooltip.bind(this));
  }

  ngOnDestory() {
    this.mouseLeaveListener();
  }

  private getUserSettings() {
    this.userSettingsSubscription = this.userService.getUserSettings().subscribe((result) => {
      this.user = result;
      this.userLoaded = true;
      this.userService.setUser(this.user);
      if (this.user === null || (this.user !== null && !this.user.isRegisteredPilot)) {
        this.isRegistered = false;
      } else {
        this.isRegistered = true;
        // this.referralLink = `${this.referralLink}${this.user.myReferralCode}`;
        // tslint:disable-next-line: max-line-length
        this.referralLink = 'https://' + window.location.hostname + '/' + this.localizationService.getLanguage() + '/windowsinsider/about-windows-insider-program?referral=' + this.user.myReferralCode;
        this.referralEmail = this.referralEmail + " " + this.referralLink;
        this.referralTweet = `${this.referralTweet}${this.referralLink}`;
      }
      this.contentLoading = false;

      //this.initMwf();
    },
      err => {
        this.isRegistered = false;
        this.telemetryService.logError('UserSettings ' + err);
        this.appInsightsErrorHandler.handleError('UserSettings ' + err);
      });
  }

  public initMwf() {
    setTimeout(() => {
      mwf.Util.initializeComponent2('[data-toggle="tooltip"]', (node) => {
        // tslint:disable-next-line: no-unused-expression
        new mwf.Tooltip({ el: node });
      });
    },1);
  }

  public copyInputMessage(){
      var copyText = document.getElementById("referral-string-holder");
     // copyText.focus();
      navigator.clipboard.writeText(this.referralLink);


      // const popOver = new mwf.Popover({
      //   el: document.querySelector('#copy-referral-string')
      // });
      // popOver.show();
  } 


  private getContent(): void {
    this.contentString = `meta/meta.json`;

    this.contentService.getBlobContent(this.contentString)
      .pipe(take(1))
      .subscribe((result) => {
        this.metaData = JSON.parse(result.documents[0].document).referral;
        // tslint:disable-next-line: max-line-length
        this.referralEmail = 'mailto:?Subject=' + this.metaData.userRegistered.emailLink.referralEmailLink.subject + '&body=' + this.metaData.userRegistered.emailLink.referralEmailLink.body;
        // tslint:disable-next-line: max-line-length
        this.referralTweet = 'https://twitter.com/intent/tweet?text=' + encodeURIComponent(this.metaData.userRegistered.tweet.referralTweetLink.text) + '&url=' + this.metaData.userRegistered.tweet.referralTweetLink.url;
        this.getUserSettings();



      }, err => {
        this.telemetryService.logError('Error getting business list: ' + JSON.stringify(err));
        this.appInsightsErrorHandler.handleError('Error getting business list: ' + JSON.stringify(err));
        this.contentLoading = false;
      });
  }

  public signIn() {

    // Leave out the language in the redirect url as it is not a valid redirect.  The system will automatically add language back
    // tslint:disable-next-line: prefer-const
    // let loginRequest: AuthenticationParameters = {
    //   authority: environment.msaIssuer,
    //   scopes: environment.apiScopes,
    //   redirectUri: 'https://' + window.location.hostname + '/referral'
    // };

    this.msalService.loginRedirect();
  }
}
