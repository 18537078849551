<ng-container *ngIf="contentLoading">
    <shared-spinner></shared-spinner>
</ng-container>
<ng-container *ngIf="!contentLoading">
    <section class="container">
        <div class="area-heading">
            <div class="row">
                <div class="col-12 col-md-8 ">
                    <h1 class="h2">{{metaData.heading}}</h1>
                </div>
                <div class="col-12 col-md-8 col-xl-6 ">
                    <p class="pb-3">{{metaData.paragraph}}</p>

                    <div class="link-group">
                        <a class="btn btn-primary"
                            [attr.aria-label]="metaData.ariaLabel" [routerLink]="['/']">{{metaData.buttonText}}</a>
                    </div>
                </div>
            </div>
        </div>
    </section>
</ng-container>