<ng-container *ngIf="!contentLoading && userLoaded">
  <section>
    <div class="card-img-overlay">
      <div class="card-background">
        <picture>
          <source [srcset]="metaData.backgroundImage.vp5" media="(min-width: 1400px)" />
          <source [srcset]="metaData.backgroundImage.vp4" media="(min-width: 1084px)" />
          <source [srcset]="metaData.backgroundImage.vp3" media="(min-width: 860px)" />
          <source [srcset]="metaData.backgroundImage.vp2" media="(min-width: 540px)" />
          <img [src]="metaData.backgroundImage.vp1" [class]="metaData.backgroundImage.classes"
            [alt]="metaData.backgroundImage.altText" class="round-corners-none" />
        </picture>
      </div>
      <div class="card-foreground ">
        <div class="container">
            <div class="row row no-gutters mt-0 mt-md-n5 mt-lg-0">
                <div class="col-lg-4 align-self-center">
                    <div class="card material-md-card py-5 px-md-5 depth-md-16 ml-lg-5">
                        <div class="card-body">

                          <h1 class="h2">{{ metaData.heading.text }}</h1>

                          <div class="mb-4">
                              <p>{{ metaData.heading.content }}</p>
                          </div>

                </div>
              </div>
            </div>
          </div>
            </div>
          </div>
    </div>
    <div class="container p-5">
      <div *ngFor="let paragraph of metaData.body[0].paragraphs">
        <p class="my-3">{{ paragraph.content }}</p>
      </div>
      <div class="card-img-overlay">
        <div class="card material-card">
          <div class="card-foreground">
            <div class="m-4">

              <div class="row"><div class="col-12"> <h2 class="h3">{{ metaData.body[0].title.content }}</h2></div></div>
              <div class="row">
                <div class="col-12"  *ngIf="isRegistered">
            
                  <input class="form-control referral-input" type="text" id="referral-string-holder"
                  name="referral-string-holder" readonly="readonly" type="text" [value]="referralLink"
                  #referralLinkInput aria-disabled="true">
              </div>
              <div class="col-12 pt-2">
                <div id="userRegistered" class="list-inline mb-0 d-inline-block align-middle" *ngIf="isRegistered">
                  <ul class="tag-list">
                    <li class="tag-list-item right-margin">
                        <a href="javascript:void(0);" role="button" class="tag-header glyph-prepend copy-link" id="copy-referral-string"
                        (click)="copyInputMessage()"
                        #referralLinkCopy aria-label="{{ metaData.userRegistered.copyLink.ariaLabel }}"
                        data-toggle="popover" data-content="{{metaData.copiedText}}">
                          <span style="margin-right: 0.5em" class="icon-copy"></span> <span class="copy-link-text">{{ metaData.userRegistered.copyLink.text }}</span>
                    </a>
                    </li>

                    <li class="tag-list-item right-margin">
                      <a href="javascript:void(0);" id="mail-to-holder" class="tag-header glyph-prepend mail-link" role="button"
                      [href]="referralEmail" aria-label="{{ metaData.userRegistered.emailLink.ariaLabel }}">
                      <span style="margin-right: 0.5em" class="icon-mail"></span> <span class="mail-link-text">{{ metaData.userRegistered.emailLink.text }}</span></a>
                    </li>

                    <li class="tag-list-item right-margin">
                      <a href="javascript:void(0);" id="twitterlink" class="tag-header glyph-prepend" role="button" target="_blank" rel="noopener"
                      [href]="referralTweet" aria-label="{{metaData.userRegistered.tweet.ariaLabel}}"> 
                      <span style="margin-right: 0.5em" class="icon-twitter"></span> <span class="">{{ metaData.userRegistered.tweet.text }}</span></a>
                    </li>
                </ul>
                </div>
                <div id="userNotRegistered" *ngIf="!isRegistered">
                  <p>{{ metaData.userNotRegistered.paragraph.content }}</p>
                  <div class="link-group my-3">
                    <a href="https://microsoft.com/windowsinsider/login" oncontextmenu="return false" class="cta"
                      aria-label="{{metaData.heading.text}}">{{ metaData.userNotRegistered.link.text }}</a>
                  </div>
                </div>
              </div>
            </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</ng-container>
<ng-container *ngIf="contentLoading || !userLoaded">
  <shared-spinner></shared-spinner>
</ng-container>