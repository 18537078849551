
import { AfterViewInit, Component, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { take } from 'rxjs/operators';
import { AppInsightsErrorHandlerService } from '../core/app-insights-error-handler/app-insights-error-handler.service';
import { ContentService } from '../core/contentRT/contentRT.service';
import { IFlightSettings } from '../core/core.model';
import { LocalizationService } from '../core/localization/localization.service';
import { PageNameTypes } from '../core/telemetry/telemetry.model';
import { TelemetryService } from '../core/telemetry/telemetry.service';
import { UserService } from '../core/user.service';

declare var mwf: any;

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit, AfterViewInit {
  private contentString: string;
  public header: any;
  public section1: any;
  public section2: any;
  public section3: any;
  public section4: any;
  public section5: any;
  public section6: any;
  public userSettingsSubscription: Subscription;
  public user: IFlightSettings;
  public contentLoading = true;
  public userLoading = true;
  public carousel: any;
  public overviewContent: any;
  public CMSfolder = 'hero';

  constructor(
    private contentService: ContentService,
    private localizationService: LocalizationService,
    private telemetryService: TelemetryService,
    private userService: UserService,
    private appInsightsErrorHandler: AppInsightsErrorHandlerService
  ) { }

  public ngOnInit() {
    this.getUserSettings();
  }

  private getUserSettings() {
    this.userSettingsSubscription = this.userService.getUserSettings().subscribe((result) => {
      this.user = result;
      this.user.isAuthenticated = this.userService.getIsAuthenticated();
      this.userService.setUser(this.user);
      //this.telemetryService.onDSGlobal._config.isLoggedIn = this.user.isAuthenticated ? true : false;
      //this.telemetryService.updateLoggedInOwap(this.user.isAuthenticated);
      if (this.user !== null) {
        this.userLoading = false;
      } else {
        // console.log('User not logged in');
        this.userLoading = false;
      }
      this.setContentPath();
    },
      err => {
        this.telemetryService.logError(`UserSettings ${err}`);
        this.appInsightsErrorHandler.handleError(`UserSettings ${err}`);
      });
  }

  public ngAfterViewInit() {
  }


  private setContentPath() {
    const language = this.localizationService.getLanguage();
    // tslint:disable-next-line: max-line-length
    if (this.user !== null && this.user.isRegisteredPilot) {
      // tslint:disable-next-line: max-line-length
      this.contentString = `home/header-reg.html,home/section1-reg.html,home/section2-reg.html,home/section4-reg.html,home/section5.html,home/section6.html&language=${language}`;
    } else {
      // tslint:disable-next-line: max-line-length
      this.contentString = `home/header-unreg.html,home/section1-unreg.html,home/section2-unreg.html,home/section3-unreg.html,home/section4-unreg.html,home/section5.html,home/section6.html&language=${language}`;
    }
    this.getContent();
  }

  private init2() {

    setTimeout(() => {
      mwf.Util.initializeComponent2('[data-mount="carousel"],[data-ride="carousel"]', (node) => {
        // tslint:disable-next-line: no-unused-expression
        new mwf.Carousel({ el: node });
      });
    }, 1);

    // setTimeout(() => {
    //   var loopInterval = 6000;
    //   var myCarousel = new mwf.Carousel({ el: document.querySelector('.carousel') });
      
    //   setTimeout(yourFunction, loopInterval);

    //   function yourFunction(){
    //     myCarousel.controls.goToNextSlide();
    //     setTimeout(yourFunction, loopInterval);
    //   } 
    // })
  }

  private getContent() {
    this.contentService.getCmsContent(this.contentString)
      .pipe(take(1))
      .subscribe((result) => {
        const pageContent = result.documents;
        let i = 0;
        pageContent.forEach(contentBlock => {
          if (contentBlock.alias === 'home/header-reg.html') {
            this.header = contentBlock.document;
          } else if (contentBlock.alias === 'home/header-unreg.html') {
            this.header = contentBlock.document;
          } else if (contentBlock.alias === 'home/section1-reg.html') {
            this.section1 = contentBlock.document;
          } else if (contentBlock.alias === 'home/section1-unreg.html') {
            this.section1 = contentBlock.document;
          } else if (contentBlock.alias === 'home/section2-reg.html') {
            this.section2 = contentBlock.document;
          } else if (contentBlock.alias === 'home/section2-unreg.html') {
            this.section2 = contentBlock.document;
          } else if (contentBlock.alias === 'home/section3-unreg.html') {
            this.section3 = contentBlock.document;
          } else if (contentBlock.alias === 'home/section4-reg.html') {
            this.section4 = contentBlock.document;
          } else if (contentBlock.alias === 'home/section4-unreg.html') {
            this.section4 = contentBlock.document;
          } else if (contentBlock.alias === 'home/section5.html') {
            this.section5 = contentBlock.document;
          } else if (contentBlock.alias === 'home/section5-unreg.html') {
            this.section5 = contentBlock.document;
          } else if (contentBlock.alias === 'home/section6.html') {
            this.section6 = contentBlock.document;
          } else if (contentBlock.alias === 'home/section6-unreg.html') {
            this.section6 = contentBlock.document;
          } else {
            this.telemetryService.logError('Error getting content section - home: ' + contentBlock.alias);
            this.appInsightsErrorHandler.handleError('Error getting content section - home: ' + contentBlock.alias);
          }
          i++;
        });
        setTimeout(() => {
          if (i === pageContent.length) {
            this.contentLoading = false;
            this.init2();
          }
        }, 1);
      }, err => {
        this.telemetryService.logError(`Error getting home list: ${JSON.stringify(err)}`);
        this.appInsightsErrorHandler.handleError(`Error getting home list: ${JSON.stringify(err)}`);
        this.contentLoading = false;
      });

    // const role = 1;
    // this.contentService.getRTContent(this.contentString).pipe(take(1)).subscribe(result => {
    //   const pageContent = result.documents;
    //       let i = 0;
    //       pageContent.forEach(contentItem => {
    //         if (contentItem.document.alias === 'windows-insiders/json/hero/hero') {
    //           this.overviewContent = contentItem.document;
    //           console.log(JSON.stringify(this.overviewContent));
    //         }
    //       i++;
    //       });

    //       setTimeout(() => {
    //         if(i === pageContent.length) {
    //           this.contentLoading = false;
    //         }
    //       }, 300);
    // });
  }
}

