import { Component, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { take } from 'rxjs/operators';
import { AppInsightsErrorHandlerService } from 'src/app/core/app-insights-error-handler/app-insights-error-handler.service';
import { ContentService } from '../../core/contentRT/contentRT.service';
import { IFlightSettings } from '../../core/core.model';
import { LocalizationService } from '../../core/localization/localization.service';
import { TelemetryService } from '../../core/telemetry/telemetry.service';
import { UserService } from '../../core/user.service';

@Component({
  selector: 'app-hub-non-win10',
  templateUrl: './hub-non-win10.component.html',
  styleUrls: ['./hub-non-win10.component.scss']
})
export class FeedbackHubNonWin10Component implements OnInit {

  public userSettingsSubscription: Subscription;
  public user: IFlightSettings;
  private contentString = '';
  public contentLoading = true;
  public pageContent: any;

  constructor(
    private contentService: ContentService,
    private telemetryService: TelemetryService,
    private localizationService: LocalizationService,
    private userService: UserService,
    private appInsightsErrorHandler: AppInsightsErrorHandlerService
  ) { }

  ngOnInit(): void {
    this.getUserSettings();
  }

  private getUserSettings() {
    this.userSettingsSubscription = this.userService.getUserSettings().subscribe((result) => {
      this.user = result;
      this.user.isAuthenticated = this.userService.getIsAuthenticated();
      this.userService.setUser(this.user);
      this.content();
      this.getContent();
    },
      err => {
        this.telemetryService.logError(`UserSettings in FeedbackHubNonWin10Component:${err}`);
        this.appInsightsErrorHandler.handleError(`UserSettings in FeedbackHubNonWin10Component:${err}`);
      });
  }

  private content(): void {
    const language = this.localizationService.getLanguage();
    this.contentString = `errors/feedback-hub-win10.json&language=${language}`;
  }

  private getContent(): void {
    this.contentService.getCmsContent(this.contentString)
      .pipe(take(1))
      .subscribe((result) => {
        this.pageContent = JSON.parse(result.documents[0].document);
        this.contentLoading = false;
      }, err => {
        this.telemetryService.logError(`Error getting FeedbackHubNonWin10Component: ${JSON.stringify(err)}`);
        this.appInsightsErrorHandler.handleError(`Error getting FeedbackHubNonWin10Component: ${JSON.stringify(err)}`);
        this.contentLoading = false;
      });
  }
}
