import { DOCUMENT, isPlatformBrowser, isPlatformServer, Location, PlatformLocation } from '@angular/common';
import { Component, ComponentFactoryResolver, ComponentRef, ElementRef, HostListener, Inject, Input, Optional, PLATFORM_ID, Renderer2, RendererFactory2, ViewChild, ViewContainerRef } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { ActivatedRoute, NavigationEnd, Params, Route, Router } from '@angular/router';
import * as msal from "@azure/msal-browser";
import { Subscription } from 'rxjs';
import { filter, map, mergeMap } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { AppInsightsErrorHandlerService } from './core/app-insights-error-handler/app-insights-error-handler.service';
import { MsalHelperService } from './core/auth/msalHelper.service';
import { ContentService } from './core/contentRT/contentRT.service';
import { IFlightSettings, IUhfContent } from './core/core.model';
import { LocalizationService } from './core/localization/localization.service';
import { SeoService } from './core/seo/seo.service';
import { TelemetryService } from './core/telemetry/telemetry.service';
import { UhfService } from './core/uhf/uhf.service';
import { UserService } from './core/user.service';
import { UtilityService } from './core/utility/utility.service';
// import { NotificationComponent } from './shared/notification/notification.component';
import { PagingService } from './shared/paging/paging.service';
import { RESPONSE } from '@nguniversal/express-engine/tokens';
import { Response } from 'express';

declare var mwf: any;
declare let gtag: Function;

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  private subscription: Subscription;
  private renderer: Renderer2;
  private uhfSubscription: Subscription;
  public userSettingsSubscription: Subscription;
  private lastVisitedUrl = '';
  public uhfNav: SafeHtml;
  public uhfLoaded = false;
  public userLoaded = false;
  public msalCallBackFired = false;
  public uhfJavaScript: string;
  public uhfCss: string;
  public uhfFooter: SafeHtml;
  public hideTitleBar = false;
  public route: Route;
  public loading = true;
  public langParam: string;
  public title = 'Windows.Insider.Web';
  public isIframe = false;
  public loggedIn = false;
  public user: IFlightSettings;
  private msalInstance: msal.PublicClientApplication;
  private window: Window | null;
  // @ViewChild('notificationPopup', { read: ViewContainerRef }) containerRef;
  // newNotificationComponent: ComponentRef<NotificationComponent>;
  // private notificationPopupCounter = 0;


  constructor(
    public localizationService: LocalizationService,
    public contentService: ContentService,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private sanitizer: DomSanitizer,
    private uhfService: UhfService,
    private telemetryService: TelemetryService,
    private userService: UserService,
    private rendererFactory: RendererFactory2,
    private platformLocation: PlatformLocation,
    private msalService: MsalHelperService,
    private pagingService: PagingService,
    private utilitySevice: UtilityService,
    private seoService: SeoService,
    private location: Location,
    private appInsightsErrorHandler: AppInsightsErrorHandlerService,
    @Inject(PLATFORM_ID) private platformId: any,
    @Inject(DOCUMENT) private document: any,
    @Optional() @Inject(RESPONSE) private xpressResponse: Response
    // private componentFactoryResolver: ComponentFactoryResolver,
    // private elementRef: ElementRef
  ) {
    this.telemetryService.initialize1DsTelemetry();
    this.subscribeNavigationEnd();
    this.window = this.document.defaultView;
  }

  ngOnInit() {

    this.router.events.pipe(
      filter((event) => event instanceof NavigationEnd),
      map(() => this.activatedRoute),
      map((route) => {
        while (route.firstChild) route = route.firstChild;
        return route;
      }),
      filter((route) => route.outlet === 'primary'),
      mergeMap((route => route.data))
    )
      .subscribe((event) => {
        if(event && event['page'] && event['status'] && event['page'] === 'page-unavailable' && event['status'] === 'not-found'){
          if(isPlatformServer(this.platformId)){
            if (this.xpressResponse) {
              this.xpressResponse.status(404);
            }
          }
        } else {
          if (Object.keys(event).length > 0) {
            const rtUrlArray = this.router.url.split('/');
            if (rtUrlArray.length < 3) {
              this.seoService.updateTitle(event['title']);
              this.seoService.updateOgUrl(`https://${this.window.location.hostname}/${event['ogUrl']}`);
              this.seoService.updateOgDescription(event['ogDescription']);
              this.seoService.updateDescription(event['description']);
              this.seoService.updateOgTitle(event['ogTitle']);
              this.seoService.updateOgImage(event['ogImage']);
              this.seoService.updateOgSecureImage(event['ogImage']);
              //this.seoService.updateTwitterUrl(`https://${this.window.location.hostname}/${event['twitterUrl']}`);
              this.seoService.updateTwitterDescription(event['twitterDescription']);
              this.seoService.updateTwitterTitle(event['twitterTitle']);
              this.seoService.updateTwitterImage(event['twitterImage']);
              //this.seoService.updateTwitterSecureImage(event['twitterImage']);
              this.seoService.updateTwitterCard(event['twitterCard']);
            }
  
          }
        }

      });

    if (isPlatformBrowser(this.platformId)) {
      const msalConfig: msal.Configuration = {
        auth: {
          clientId: environment.msaClientId,
          authority: environment.msaIssuer,
          redirectUri: environment.oAuthRedirectUrl,
        },
        cache: {
          cacheLocation: "localStorage", // This configures where your cache will be stored
          storeAuthStateInCookie: false, // Set this to "true" if you are having issues on IE11 or Edge
        }
      };

      this.msalInstance = new msal.PublicClientApplication(msalConfig);

      this.msalService.setMsalInstance(this.msalInstance);

      // Register Callbacks for Redirect flow
      this.msalInstance
        .handleRedirectPromise()
        .then((tokenResponse) => {

          this.msalCallBackFired = true;

          if (tokenResponse !== null) {
            // Account object was retrieved, continue with app progress
            // console.log("id_token acquired at: " + new Date().toString());
            // showWelcomeMessage(accountObj);
            localStorage.setItem("current", tokenResponse.account.username);
            this.userService.setIsAuthenticated(true);
            this.getUserSettings();

          } else {
            // need to call getAccount here?
            const currentAccounts = this.msalInstance.getAllAccounts()
            if (currentAccounts === null || currentAccounts.length < 1) {
              this.userService.setIsAuthenticated(false);
              this.telemetryService.updateLoggedInOwap(false);
              this.getUserSettings();
            } else {
              this.userService.setIsAuthenticated(true);
              this.telemetryService.updateLoggedInOwap(true);
              this.getUserSettings();
            }
          }
        })
        .catch((error) => {
          console.log(error);
        });
    }


    //turn on robots for SEO in production
    this.seoService.setRobots();

    this.isIframe = this.window !== this.window.parent && !this.window.opener;

    this.checkQueryStrings();

    //setting the language at application start up
    this.localizationService.setLanguage(this.platformLocation.pathname.split('/')[1]);
  }

  // ngAfterViewChecked(){
  //   let headerContent = this.elementRef.nativeElement.querySelector('#headerUniversalHeader');
  //   if(this.userLoaded && this.msalCallBackFired && this.notificationPopupCounter <= 0 && headerContent){
  //     this.notificationPopupCounter ++;
  //     this.showNotification();
  //   };
  // }

  ngOnDestroy() {
    // this.broadcastService.getMSALSubject().next(1);
    // if (this.subscription) {
    //   this.subscription.unsubscribe();
    // }

    if (this.userSettingsSubscription) {
      this.userSettingsSubscription.unsubscribe();
    }
  }

  // Handle anchors
  // https://stackoverflow.com/questions/54533952/page-reloads-when-clicking-anchor-element

 //Below code append language code and subfolder along with the relative paths
 @HostListener('window:keyup', ['$event'])
 @HostListener('window:mousedown', ['$event'])
 appendBaseHref(e: any) {
   const el = e.target as HTMLAnchorElement;
   if (el.tagName === 'A') {
    let componentPath = '';
     const oldHref = el.getAttribute('href');
     const langCode = this.localizationService.getLanguage();
     if(oldHref && !oldHref.startsWith('http') && !oldHref.startsWith('#') && !oldHref.startsWith(`/${langCode}/windowsinsider`)){
       let newHref = oldHref;
       if(oldHref.startsWith(`/${langCode}`)){
         newHref = oldHref.replace(`/${langCode}`,'') 
       }
       const hrefParts = newHref.split('/').filter((e) => {
         if(e){
           return e;
         }
       });

       if(hrefParts[0] && hrefParts[0].includes('?')){
        componentPath = hrefParts[0].split('?')[0];
       } else {
        componentPath = hrefParts[0];

       }

       let isInternalLink = false
       this.router.config.find(
         (route) => {
           if (route.path === componentPath) {
             isInternalLink = true;
           }
         }
       );

       if(!newHref.startsWith('/')){
         newHref = `/${newHref}`
       }

       if(isInternalLink){
         el.setAttribute('href',`/${langCode}/windowsinsider${newHref}`) ;
         return;         
       } else if(componentPath.includes('#')){
         el.setAttribute('href',`${this.platformLocation.protocol}//${this.platformLocation.hostname}/${langCode}/windowsinsider${newHref}`);
         return;
       } else {
         return;
       }
     } else {
       return;
     }
   }
 }




  @HostListener('window:click', ['$event'])
  onClick(e: any) {
    if (e.composedPath === undefined) {
      return;
    }

    const el = e.target as HTMLElement;
    if (el !== undefined) {
      //we have a tab anchor
      const tab = el.getAttribute("tab-anchor");

      if (tab !== undefined && tab !== null) {
        //we have a tab anchor
        const anchor = el.getAttribute("href");
        const tab = new mwf.Tab({
          el: document.querySelector(anchor),
        });

        tab.show();
        el.scrollIntoView();
      }
    }

    if (el !== undefined) {
      //we have a tab anchor
      const loginLink = el.getAttribute("login");

      if (loginLink !== undefined && loginLink !== null) {
        //we have login link
        e.preventDefault();
        this.msalService.loginRedirect();
      }
    }

  //Below code checks for any query parameter in URL. This code only checks for the internal URLS and redirects with queryParams
    if (el && el.getAttribute('href')) {
      const oldHref = el.getAttribute('href');
      const langCode = this.localizationService.getLanguage();
      if (oldHref.includes('?') && oldHref.startsWith(`/${langCode}/windowsinsider`)) {
        const componentPath = oldHref.split('?')[0];
        const componentName = componentPath.split('/')[componentPath.split('/').length - 1];
        const qryString = oldHref.split('?')[1];
        let isInternalLink = false
        this.router.config.find(
          (route) => {
            if (route.path === componentName) {
              isInternalLink = true;
            }
          }
        );

        if(isInternalLink){
          const qryParams = [];
          if(qryString.includes('&')){
            qryString.split('&').forEach((param)=>{
              const qryParam = {
                'key' : param.split('=')[0],
                'value' : param.split('=')[1]
              };
              qryParams.push(qryParam)
            })
          } else {
            const qryParam = {
              'key' : qryString.split('=')[0],
              'value' : qryString.split('=')[1]
            };
            qryParams.push(qryParam)
          }

          if(qryParams.length > 0){
            const qryParamObject = {};
            qryParams.forEach((item)=>{
              qryParamObject[item['key']]=item['value'];
            });

            e.preventDefault();
            this.router.navigate([componentName],{queryParams:qryParamObject});
            return;
          }
        }

      }
    }


    // if (el !== undefined) {
    //   //Capturing remove btn for closing the notification popup
    //   const notificationCloseBtnClass = el.getAttribute("id");
    //   if(notificationCloseBtnClass === 'remove-notification-popup-btn' ){
    //     this.elementRef.nativeElement.querySelector('#notification-banner-container').classList.add('notification-remove-animation');
    //     setTimeout(() => this.newNotificationComponent.destroy(),500);
        
    //   }
    // }


    //is this a TAB?
    const path = e.composedPath() as Array<any>;
    const firstAnchor = path.find(p => p.tagName !== undefined && p.tagName.toLowerCase() === 'a');

    if (firstAnchor === undefined) {
      return;
    }
    const pathName = firstAnchor.pathname;
    if (pathName !== undefined && pathName !== "") {
      const newPathName = pathName.replace('/' + this.localizationService.getLanguage() + '/windowsinsider/', '');
      if (newPathName === '') {
        const hash = firstAnchor.hash.toString();
        if (hash !== undefined && hash !== null && hash !== "") {
          // this.router.navigateByUrl(href);
          var element_to_scroll_to = document.getElementById(hash.replace('#', ''));
          element_to_scroll_to.scrollIntoView();

          e.preventDefault();
        }
      }
      //instead of reloading the page navigating to the internal url.
       else  {
        if (el.getAttribute('href') && (el.getAttribute('href').startsWith('https://') ||
        el.getAttribute('href').startsWith('http://') ||
        el.getAttribute('href').startsWith('#') || el.getAttribute('href').startsWith('ms-settings')
      )) {
          if(el.getAttribute('href').endsWith('/windowsinsider/login')){
            e.preventDefault();
            this.msalService.loginRedirect();
            return;
          } else {
            return;
          }
        } else if (!el.getAttribute('href')) {
          return;
        } else {
          if (newPathName) {
            const navComponent = newPathName.replaceAll('/', '');
            let anchorHasTargetBlank = false;
            if(el.getAttribute('target') && el.getAttribute('target') === '_blank'){
              anchorHasTargetBlank = true;
            }
            let isInternalLink = false
            this.router.config.find(
              (route) => {
                if (route.path === navComponent) {
                  isInternalLink = true;
                }
              }
            );
            if (isInternalLink) {
              if(anchorHasTargetBlank){
                e.preventDefault();
                this.window.open(`${this.platformLocation.protocol}//${this.platformLocation.hostname}${this.platformLocation.getBaseHrefFromDOM()}${navComponent}`,'_blank');
                return;
              } else {
                  e.preventDefault();
                  this.router.navigateByUrl(navComponent);
                return;
              }

            } else if (newPathName.startsWith('/articles') ||
              newPathName.startsWith('/mvps') ||
              newPathName.startsWith('/blogs') ||
              newPathName.startsWith('/podcasts')||
              newPathName.startsWith('/insidewindows11')) {
                if(anchorHasTargetBlank){
                  e.preventDefault();
                  const relativeUrl = newPathName.replace(/\//,'');
                  this.window.open(`${this.platformLocation.protocol}//${this.platformLocation.hostname}${this.platformLocation.getBaseHrefFromDOM()}${relativeUrl}`,'_blank');
                } else {
                  e.preventDefault();
                  this.router.navigateByUrl(newPathName);
                }

            } else {
              return;
            }

          }
        }
      }
    }

    const hash = firstAnchor.hash.toString();
    if (hash !== undefined && hash !== null && hash !== "") {
      // this.router.navigateByUrl(href);
      var element_to_scroll_to = document.getElementById(hash.replace('#', ''));
      element_to_scroll_to.scrollIntoView();

      e.preventDefault();
    }
  }

  public focusMainContent() {
    this.renderer = this.rendererFactory.createRenderer(null, null);
    const element = this.renderer.selectRootElement('#mainContent');
    element.focus();
    setTimeout(() => element.focus(), 0);
  }

  private getUserSettings() {
    this.userSettingsSubscription = this.userService.getUserSettings().subscribe((result) => {
      if (this.msalCallBackFired) {
        this.userService.setUser(result);
        this.user = result;
        this.user.isAuthenticated = this.userService.getIsAuthenticated();
        // console.log(this.user);
        // console.log(this.user.isRegisteredPilot);

        setTimeout(() => {
          this.userLoaded = true;
          // if (isPlatformBrowser(this.platformId)) {
          //   const ctaElement = this.document.getElementById('c-uhf-nav-cta');
          //   ctaElement.style.display = "inline-block";
          // }
        }, 100);
      }
    },
      err => {
        this.telemetryService.logError('UserSettings ' + err);
        this.appInsightsErrorHandler.handleError('UserSettings ' + err);
        // this.maslService.signOut();
        // this.maslService.loginRedirect();
      });
  }

  private subscribeNavigationEnd() {

    this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        if (typeof (gtag) !== 'undefined' && gtag) {
          gtag('config', environment.googleAnalytics,
            {
              'page_path': event.urlAfterRedirects
            }
          );
        }
      }
    }
    );
    this.router.events
      .pipe(filter(e => e instanceof NavigationEnd))
      .pipe(map(() => this.activatedRoute))
      .pipe(map(route => {
        if (route.firstChild) {
          route = route.firstChild;
        }
        let path = this.window.location.pathname.replace("/" + this.localizationService.getLanguage(), '');
        this.telemetryService.extendCoreData(path);
        return route;
      }))
      .subscribe(r => {
        if (r.snapshot.url.length === 0) {
          this.hideTitleBar = true;
        }

        // Set the language and region for localziation
        this.langParam = this.platformLocation.pathname;
        if (this.langParam.indexOf('/') === 0) {
          const paramLength = this.langParam.length;
          this.langParam = this.langParam.slice(1, paramLength);
        }
        if (this.langParam.indexOf('/') > 0) {
          this.langParam = this.langParam.slice(0, this.langParam.indexOf('/'));
        }
        this.localizationService.setLanguage(this.langParam);

        // bootstrap the paging values.  This always has to be done after we set the language param in the line above
        this.utilitySevice.initMonthsYears();
        this.utilitySevice.initFilterConfig();
        this.utilitySevice.getUnavailablePageConfig();
        this.contentService.initCmsConfig();
        this.contentService.initCategories();
        this.contentService.initMetaConfigs();


        const htmlLang = this.document.getElementById('html');
        htmlLang.setAttribute('lang', this.langParam);
        const baseHref = this.document.getElementById('baseHref');
        baseHref.setAttribute('href', '/' + this.langParam + '/windowsinsider/');
        if ((this.langParam === 'ar') || (this.langParam === 'ar/') || (this.langParam === 'ar-sa') || (this.langParam === 'he-il')) {
          htmlLang.setAttribute('dir', 'rtl');
          const rtlClass = this.document.getElementById('appBody');
          rtlClass.setAttribute('class', 'rtlLang');

          this.contentService.setRtl(true);
        }
      });
  }

  private loadUhf(targetting: string): void {
    this.uhfSubscription = this.uhfService.getUhf(this.langParam, targetting).subscribe(
      (result: IUhfContent) => {
        const headerElement = result.communityHeaderHtml;
        this.uhfNav = this.sanitizer.bypassSecurityTrustHtml(headerElement);

        const jsElement = result.javaScriptIncludes;
        this.uhfJavaScript = jsElement;

        const cssElement = result.cssIncludes;
        this.uhfCss = cssElement;

        const footerElement = result.footerHtml;
        this.uhfFooter = this.sanitizer.bypassSecurityTrustHtml(footerElement);

        this.loadUhfCss();

        // get rid of the CSS transition flicker
        setTimeout(() => {
          this.loading = false;
          this.uhfLoaded = true;
        }, 1);
      },
      error => {
        this.telemetryService.logError(
          'Error getting UHF: ' + JSON.stringify(error)
        );
        this.appInsightsErrorHandler.handleError('Error getting UHF: ' + JSON.stringify(error));
      }
    );
  }

  private checkQueryStrings(): void {
    this.activatedRoute.queryParams
      // .filter(params => params.state)
      .subscribe(params => {
        if (params !== undefined && params !== null) {
          if (params.lastVisitedUrl !== undefined && this.lastVisitedUrl != null) {
            this.lastVisitedUrl = params.lastVisitedUrl;
          }
          if (params.referral != undefined) {

            const d = new Date();
            d.setTime(d.getTime() + (1 * 24 * 60 * 60 * 1000));
            const expires = 'expires=' + d.toUTCString();

            document.cookie = 'referral=' + params.referral + ';' + expires + '; path=/';
          }
        }
      });
  }

  private loadUhfCss() {
    const div = document.createElement('div');
    div.innerHTML = this.uhfCss;
    const initialNodeCount = div.childNodes.length - 1;  // do not use this in the loop below because it changes
    for (let i = 0; i <= initialNodeCount; i++) {
      // this div.childNodes[0] needs to be 0 and not i
      // Details here https://developer.mozilla.org/en-US/docs/Web/API/Node/appendChild
      const link = document.createElement('link');
      link.rel = 'stylesheet';
      link.type = 'text/css';
      link.href = (div.childNodes[i] as HTMLLinkElement).href;
      link.media = 'all';
      document.head.insertBefore(link, document.head.childNodes[0]);
    }
  }

  onActivate() {
    this.window.scroll(0, 0);
  }

  // showNotification() {
  //   const componentsFactory = this.componentFactoryResolver.resolveComponentFactory(NotificationComponent);
  //   this.containerRef.clear();
  //   this.newNotificationComponent= this.containerRef.createComponent(componentsFactory);
  // }
}
