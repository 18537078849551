import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AuthenticationResult } from '@azure/msal-browser';
import { from, Observable, throwError as observableThrowError } from 'rxjs';
import { catchError, mergeMap } from 'rxjs/operators';
import { AppInsightsErrorHandlerService } from '../app-insights-error-handler/app-insights-error-handler.service';
import { TelemetryService } from '../telemetry/telemetry.service';
import { MsalHelperService } from './msalHelper.service';




@Injectable()
export class TokenInterceptor implements HttpInterceptor {

  constructor(
    private telemetryService: TelemetryService, private msalService: MsalHelperService,private appInsightsErrorHandler: AppInsightsErrorHandlerService    ) { }


    public intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

      const re = /identity/gi;

      // Exclude interceptor for identity request:
      if (req.url.search(re) === -1 ) {
       return next.handle(req);
      }


      return from(this.getToken().then(token => {
      
        const re = /identity/gi;

        // Exclude interceptor for identity request:
        if (req.url.search(re) === -1 ) {
          return req.clone({
        });
        } else {
          const JWT = `Bearer ${token.accessToken}`;
          return req.clone({
              setHeaders: {
                  Authorization: JWT,
              },
          });
        }
      })).pipe(mergeMap(r => next.handle(r)
      .pipe(catchError((err: any) => {
        if (err instanceof HttpErrorResponse) {
          if (err.status === 401) {
          //   window.location.href = environment.oAuthLoginUrl
          //     + '?client_id=' + environment.msaClientId
          //     + '&scope=Ambassadors.API&response_type=token'
          //     + '&redirect_uri=' + environment.oAuthRedirectUrl + this.tenantService.getTenantId();
          }
        }
        // Other case throw an error
        this.telemetryService.logError(err);
        this.appInsightsErrorHandler.handleError(err);
        return observableThrowError(err);
    }))));
  }

  private getToken(): Promise<AuthenticationResult> {
    return this.msalService.acquireTokenSilent();
  }
}