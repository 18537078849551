import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../environments/environment';
import { ContentService } from '../core/contentRT/contentRT.service';
import { LocalizationService } from '../core/localization/localization.service';
import { PagingService } from '../shared/paging/paging.service';

@Injectable({
  providedIn: 'root'
})
export class SearchService {
  constructor(
    private pagingService: PagingService,
    private http: HttpClient,
    private contentService: ContentService,
    private localizationService: LocalizationService
  ) { }

  public getMetaSettings(): Observable<any> {
    const language = this.localizationService.getLanguage();
    const contentString = `search/meta.json&language=${language}`;
    return this.contentService.getCmsContent(contentString);
  }

  public searchIndex(searchText: string, currentPage: number, pageSize: number): Observable<any> {
    const language = this.localizationService.getLanguage();
    const dateOrder = 'created_date desc';
    const filter = `language eq '${language}'`;
    if (currentPage < 1) {
      currentPage = 1;
    }
    const skip = (currentPage - 1) * pageSize;
    // tslint:disable-next-line: max-line-length
    const searchUrl = `${environment.urls.azureSearchApiUrl}api-version=2019-05-06&search=${searchText}&$orderby=${dateOrder}&$count=true&$skip=${skip}&$top=${pageSize}&$filter=${filter}`;
    const headers = new HttpHeaders();
    headers.set('api-key', environment.azureSearchKey);
    return this.http.get<any>(searchUrl, {
      headers: new HttpHeaders().set('api-key', environment.azureSearchKey)
    });
  }

  public getUrl(postType: string, slug: string) {
    // TODO: create an enum and a dictionary
    if (postType === 'post') {
      postType = 'articles';
    }

    if (postType === '' || slug === '') {
      return window.location.origin;
    }

    const lang = this.localizationService.getLanguage()
    const url = `${window.location.origin}/${lang}/${postType}/${slug}`;
    return url;
  }
}
