import { isPlatformBrowser } from '@angular/common';
import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { ApplicationInsights } from '@microsoft/applicationinsights-web';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class AppInsightServicesService {
  appInsights: ApplicationInsights;
  constructor(
    @Inject(PLATFORM_ID) private platformId: any,
  ) {
    this.appInsights = new ApplicationInsights({
      config: {
        instrumentationKey: environment.azureInstrumentationKey,
        enableAutoRouteTracking: true
      }
    });

    if (isPlatformBrowser(this.platformId)) {
      this.appInsights.loadAppInsights();
    }

  }
  logPageView(name?: string, url?: string) {

    if (isPlatformBrowser(this.platformId)) {
      this.appInsights.trackPageView({
        name: name,
        uri: url
      });
    }


  }

  logEvent(name: string, properties?: { [key: string]: any }) {
    if (isPlatformBrowser(this.platformId)) {
      this.appInsights.trackEvent({ name: name }, properties);
    }

  }

  logMetric(name: string, average: number, properties?: { [key: string]: any }) {
    if (isPlatformBrowser(this.platformId)) {
      this.appInsights.trackMetric({ name: name, average: average }, properties);

    }
  }

  logException(exception: Error, severityLevel?: number) {
    if (isPlatformBrowser(this.platformId)) {
      this.appInsights.trackException({ exception: exception, severityLevel: severityLevel });

    }
  }

  logTrace(message: string, properties?: { [key: string]: any }) {
    if (isPlatformBrowser(this.platformId)) {
      this.appInsights.trackTrace({ message: message }, properties);

    }
  }
}
