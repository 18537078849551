import { Inject, Injectable, OnDestroy, PLATFORM_ID } from "@angular/core";
import * as msal from "@azure/msal-browser";
import { AccountType, AuthenticatedState, CommandType, IAadWebIdpConfig, IAccount, IMeControlConfiguration, ISignInArgs, ISignInToArgs, ISwitchToArgs } from "@mecontrol/public-api";
import { Subscription } from 'rxjs';
import { environment } from "../../../environments/environment";
import { TelemetryService } from '../telemetry/telemetry.service';
import { UserService } from '../user.service';
// import { Account } from "./mecontrol.models";



@Injectable({
  providedIn: "root",
})
export class MsalHelperService implements OnDestroy {
  private msalInstance: msal.PublicClientApplication;
  public userSettingsSubscription: Subscription;

  constructor(private userService: UserService,
    private telemetryService: TelemetryService,
    @Inject(PLATFORM_ID) private platformId: any) {


  }

  public ngOnDestroy() {
    if (this.userSettingsSubscription !== undefined) {
      this.userSettingsSubscription.unsubscribe();
    }
  }


  public setMsalInstance(instance: msal.PublicClientApplication) {
    this.msalInstance = instance;
  }

  public acquireTokenSilent(): Promise<msal.AuthenticationResult> {
    if (!this.userService.getIsAuthenticated()) {
      return null;
    }

    return this.msalInstance.acquireTokenSilent(this.getSilentFlowRequest())
      .catch((error) => {
        console.log(error);

        this.loginRedirect();

        // if (error instanceof msal.InteractionRequiredAuthError) {
        //       console.log("token expired need to refresh.  Send to login screen.")
        //       this.loginRedirect();
        // }

        throw error;
      })
  }


  public loginRedirect() {
    const loginRequest = {
      scopes: environment.apiScopes
    };
    this.msalInstance.loginRedirect(loginRequest);
  }

  /**
   * Add a query paramter to a provided URI. If the parameter already exists, then its
   * value will be overwritten.
   * @param uri URI to add query parameters to
   * @param key Key of the query parameter to add
   * @param value Value of the query parameter to add
   */
  private setQP(uri: string, key: string, value: string): string {
    let re = new RegExp("([?&])" + key + "=.*?(&|$)", "i");
    let separator = uri.indexOf("?") !== -1 ? "&" : "?";
    if (uri.match(re)) {
      return uri.replace(re, "$1" + key + "=" + value + "$2");
    } else {
      return uri + separator + key + "=" + value;
    }
  }

  /**
   * Add query parameters to a provided URI. If any of the passed parameters already
   * exist in the object, then their values are replaced.
   * @param uri URI to add query parameters to
   * @param parameters An object containing the query parameters to add to the URI. Each
   * this object's properties will be added as a query parameter, with the name of the
   * property used as the corresponding parameter's key.
   */
  public setQPs(uri: string, parameters: Record<string, any>): string {
    let tempUri = uri;
    for (let param in parameters) {
      if (parameters.hasOwnProperty(param)) {
        tempUri = this.setQP(tempUri, param, parameters[param].toString());
      }
    }
    return tempUri;
  }

  /** Handle signIn and switch actions */
  // public signIn(): void {
  //     this.login()
  //         .then(this.setAccountOnMeControl);
  // }

  public signInOrSwitch = (args: ISignInArgs) => {
    this.login();
    //then(this.setAccountOnMeControl);
  };

  public signInSwitch = () => {
    this.logout();
    // this.loginRedirect();
  }

  /**
   * Handle signInTo or switchTo actions
   * @param args Arguments for the auth action, which will include what account
   * we will be signing in/switching to
   */
  public signInToOrSwitchTo = (args: ISignInToArgs | ISwitchToArgs) => {
    this.login(args.nextAccount.memberName);

    //.then(this.setAccountOnMeControl);
  };

  /** Handle signOutFromApp action */
  public signOut = () => {
    this.logout();
  };

  /** Get the currently signed used from ADAL JS, if any is avaialable */
  public getCurrentAccount(): msal.AccountInfo | undefined {
    let userInfo = this.getUser();
    if (userInfo) {
      return this.getUser()
    }
    return null;
  }

  /**
   * Set the current active account on MeControl by transforming the user info obtained
   * from ADAL JS into an account object for the control and calling the right API
   * @param userInfo User information provided by ADAL JS
   */
  public setAccountOnMeControl(userInfo: IAccount): void {
    if (userInfo) {
      let account = this.mapUserInfoToAccount(userInfo);
      window.MeControl.API.setActiveAccount(account);
    }
  }

  public login2() {
    // this.msalService.loginRedirect();
    // console.log("login2");
  }

  /**
   * Create the config section of our MSAL JS auth provider that handles remembered accounts
   * by talking to the AAD endpoint. Relies on having an appId and wreply readily available.
   */
  public createRememeberedAADAccountsConfig(): IAadWebIdpConfig {
    const aadSignOutUrl =
      "https://login.microsoftonline.com/common/oauth2/v2.0/uxlogout";
    const aadForgetUrl =
      "https://login.microsoftonline.com/common/oauth2/v2.0/forgetuser";
    const aadOtherAccountsUrl =
      "https://login.microsoftonline.com/common/oauth2/v2.0/savedusers";

    const appid = environment.msaClientId;
    const wreply = environment.oAuthRedirectUrl;

    return {
      signOutUrl: this.setQPs(aadSignOutUrl, {
        appid,
        wreply,
        shouldForgetUser: false,
      }),
      signOutAndForgetUrl: this.setQPs(aadSignOutUrl, {
        appid,
        wreply,
        shouldForgetUser: true,
      }),
      forgetUrl: aadForgetUrl,
      rememberedAccountsUrl: null
    };
  }

  /* Create the config section of our MSAL JS auth provider that handles remembered accounts
   * by talking to the AAD endpoint. Relies on having an appId and wreply readily available.
   */
  public createRememeberedMSAAccountsConfig() {
    //  const aadSignOutUrl = "https://login.microsoftonline.com/common/oauth2/v2.0/uxlogout";
    //  const aadForgetUrl = "https://login.microsoftonline.com/common/oauth2/v2.0/forgetuser";
    //  const aadOtherAccountsUrl = "https://login.microsoftonline.com/common/oauth2/v2.0/savedusers";

    const appid = environment.msaClientId;
    const wreply = environment.oAuthRedirectUrl;

    return {
      signOutAndForgetUrl:
        "https://login.live.com/logout.srf?ct=1544212397&rver=6.7.6631.0&id=000000&ru=" +
        wreply,
      rememberedAccountsUrl:
        "https://login.live.com/Me.srf?wa=wsignin1.0&rpsnv=13&ct=1544212396&rver=6.7.6631.0&wp=MBI_SSL&wreply=" +
        wreply,
    };
  }

  public createConfig(
    authProviderConfig: any,
    currentAccount?: IAccount,
    containerId: string = "me-control-container"
  ): IMeControlConfiguration {
    return {
      apiGeneration: "GEN2",
      containerId,
      currentAccount,
      authProviderConfig,
      appContextConfig: {
        commands: [
          {
            text: "Microsoft.com",
            id: "link1",
            ariaLabel: "Microsoft.com",
            openInNewTab: false,
            url: "http://www.microsoft.com",
            type: CommandType.Link,
          },
          {
            text: "Office 365",
            id: "link2",
            ariaLabel: "Office 365",
            openInNewTab: false,
            url: "https://www.office.com/",
            type: CommandType.Link,
          },
          {
            text: "Azure Portal",
            id: "link3",
            ariaLabel: "Azure Portal",
            openInNewTab: true,
            url: "https://portal.azure.com",
            type: CommandType.Link,
          },
          {
            text: "Xbox",
            id: "link4",
            ariaLabel: "Xbox Site",
            openInNewTab: true,
            url: () => "https://www.xbox.com",
            onClick: () => {
              //tslint:disable-next-line:no-console
              console.log("Xbox link has been clicked");
            },
            type: CommandType.Link,
          }
        ],
      },
    };
  }

  // /**
  //  * Transform a user information object from ADAL JS into an account object
  //  * used by MeControl
  //  * @param userInfo User information as provided by ADAL JS
  //  */
  // public mapUserInfoToAccount(userInfo: MsaUserInfo | AadUserInfo): IAccount {
  //     if (isMsaUserInfo(userInfo)) {
  //         return {
  //             type: AccountType.MSA_FED,
  //             authenticatedState: AuthenticatedState.SignedIn,
  //             memberName: userInfo.userName,
  //             firstName: userInfo.profile.given_name,
  //             lastName: userInfo.profile.family_name,
  //             pictureUrl: userInfo.profile.picture,
  //             cid:''
  //         };
  //     }
  //     else {
  //         return {
  //             type: AccountType.AAD,
  //             authenticatedState: AuthenticatedState.SignedIn,
  //             memberName: userInfo.userName,
  //             firstName: userInfo.profile.given_name,
  //             lastName: userInfo.profile.family_name,
  //             displayName: userInfo.profile.name,
  //             pictureUrl: userInfo.profile.picture
  //         };
  //     }
  // }

  /**
   * Transform a user information object from ADAL JS into an account object
   * used by MeControl
   * @param userInfo User information as provided by ADAL JS
   */
  public mapUserInfoToAccount(userInfo: IAccount): IAccount {
    return {
      type: AccountType.AAD,
      authenticatedState: AuthenticatedState.SignedIn,
      memberName: userInfo.memberName,
      firstName: userInfo.firstName,
      lastName: userInfo.lastName,
      displayName: userInfo.displayName,
      pictureUrl: userInfo.pictureUrl
    };
  }

  /**
   * Login with a given user and return the account information. If no account ID is provided,
   * the standard login screen will be used to prompt the user for login
   * @param memberName (Optional) Email or number of the account to sign in as
   */
  private login(memberName?: string) {

    this.loginRedirect();
  }


  private getSilentFlowRequest(): msal.SilentRequest {
    const currentUser = localStorage.getItem("current");

    const silentRequest: msal.SilentRequest = {
      scopes: environment.apiScopes,
      account: this.msalInstance.getAccountByUsername(currentUser)
    };

    return silentRequest;
  }

  private logout(): void {
    window.MeControl.API.setActiveAccount(null);
    this.msalInstance.logout();
  }

  /**
   * Get the user information from ADAL JS. If no information is available, then undefined
   * will be returned
   */
  private getUser() {
    const currentUser = localStorage.getItem("current");
    let userInfo = this.msalInstance.getAccountByUsername(currentUser);

    if (userInfo == null) {
      return null;
    }
    let account = {
      username: userInfo.username,
      homeAccountId: userInfo.homeAccountId,
      environment: userInfo.environment,
      localAccountId: userInfo.localAccountId,
      tenantId: userInfo.tenantId,
      displayName: userInfo.username,
      authenticatedState: this.userService.getIsAuthenticated() ? AuthenticatedState.SignedIn : AuthenticatedState.NotSignedIn
    };
    return account;
  }

  /**
   * Determine whether a UserInfo object is for an MSA or AAD account
   * @param userInfo User info obtained from ADAL JS
   */
  // private isMsaUserInfo(userInfo: Account): userInfo is Account {
  //   return false;
  //   //return (userInfo as Account).idp === "live.com";
  // }

  /**
   * Function to create a Promise around the loginInProgess() function from ADAL JS
   */
  // private waitForLogin(): Promise<void> {
  //     const waitCheckMs = 500;

  //     if (this.msalInstance.get.getLoginInProgress()) {
  //         return new Promise(resolve => {
  //             const checkLoginStatus = () => {
  //                 if (this.msalService.getLoginInProgress()) {
  //                     setTimeout(checkLoginStatus, waitCheckMs);
  //                 }
  //                 else {
  //                     resolve();
  //                 }
  //             };

  //             setTimeout(checkLoginStatus, waitCheckMs);
  //         });
  //     }
  //     else {
  //         return Promise.resolve();
  //     }
  // }

  // /** Clear ADAL JS config tweaks that may have been done before */
  // private clearExtraConfig(): void {
  //     delete this.msalService.clearCacheForScope();
  // }
}
