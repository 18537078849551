import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { take } from 'rxjs/operators';
import { TelemetryService } from '../core/telemetry/telemetry.service';
import { PagingService } from '../shared/paging/paging.service';
import { SearchService } from './search.service';
import { ContentService } from '../core/contentRT/contentRT.service';
import { environment } from 'src/environments/environment';
import { AppInsightsErrorHandlerService } from '../core/app-insights-error-handler/app-insights-error-handler.service';

@Component({
  selector: 'app-search',
  templateUrl: './search.component.html',
  styleUrls: ['./search.component.css']
})
export class SearchComponent implements OnInit {
  public results: any;
  public meta: any;
  public searchText = '';
  public loading = false;
  public page = 1;
  public pageSize = 10;
  public totalRecords = 1;
  public totalRecordsText = '';

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private searchService: SearchService,
    private telemetryService: TelemetryService,
    private pagingService: PagingService,
    private contentService: ContentService,
    private appInsightsErrorHandler: AppInsightsErrorHandlerService
  ) { }

  ngOnInit(): void {
    this.loadMetaData();        
    this.activatedRoute.queryParamMap.subscribe(params => {
      this.searchText = params.get('q');
      // if(this.searchText != ''){
      this.search();
      // }
    });
  }

  private loadMetaData() {
    this.contentService.getMetaConfigs().pipe(take(1)).subscribe((data) => {
      this.meta = data.search;
    },
      (err: any) => {
        this.telemetryService.logError('Error while getting search meta : ' + JSON.stringify(err));
        this.appInsightsErrorHandler.handleError('Error while getting search meta : ' + JSON.stringify(err));
      });
  }


  public search() {
    this.loading = true;

    if((environment.azureSearchKey || "").length < 5){
      this.contentService.getCmsConfig()
        .pipe(take(1))
        .subscribe((result) => {
          environment.azureSearchKey = result.azureSearchKey;
          this.searchService.searchIndex(this.searchText, this.page, this.pageSize)
            // .pipe(take(1))
            .subscribe((data) => {
              this.totalRecords = data['@odata.count'];
              this.results = data.value; // .filter((a) => a.title != null);
              this.setTotalRecordsText();
              this.loading = false;
            },
              (err: any) => {
                this.loading = false;
                this.telemetryService.logError(`Error while searching: ${JSON.stringify(err)}`);
                this.appInsightsErrorHandler.handleError(`Error while searching: ${JSON.stringify(err)}`);
              });
        }, err => {
          this.telemetryService.logError(`Error getting page-unavailable : ${JSON.stringify(err)}`);
          this.appInsightsErrorHandler.handleError(`Error getting page-unavailable : ${JSON.stringify(err)}`);
        });
    } else {
      this.searchService.searchIndex(this.searchText, this.page, this.pageSize)
        // .pipe(take(1))
        .subscribe((data) => {
          this.totalRecords = data['@odata.count'];
          this.results = data.value; // .filter((a) => a.title != null);
          this.setTotalRecordsText();
          this.loading = false;
        },
          (err: any) => {
            this.loading = false;
            this.telemetryService.logError(`Error while searching: ${JSON.stringify(err)}`);
            this.appInsightsErrorHandler.handleError(`Error while searching: ${JSON.stringify(err)}`);
          });
    }
  }

  public setTotalRecordsText() {
    const first = (this.page - 1) * this.pageSize;
    const last = first + this.pageSize;
    this.totalRecordsText = `${first} to ${last} of ${this.totalRecords}`;
  }

  public getUrl(postType: string, slug: string) {
    return this.searchService.getUrl(postType, slug);
  }

  public goToResult(url: any) {
    this.router.navigate([url]);
  }

  // Emits from the paging component
  public pageAction(page: number) {
    this.page = page;
    this.pagingService.getPager(this.totalRecords, this.page);
    this.search();
  }

  public onKeyUp(event: KeyboardEvent) {
    if (event.keyCode === 13) {
      this.search();
    }
  }

  public onButtonClicked() {
    this.search();
  }
}
