import { PlatformLocation } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
// import { BlogsService } from 'src/app/community/blogs/blogs.service';
import { MVPService } from 'src/app/community/mvps/mvps.service';
import { PodcastsService } from 'src/app/community/podcasts/podcasts.service';
import { ContentService } from 'src/app/core/contentRT/contentRT.service';
import { SeoService } from 'src/app/core/seo/seo.service';
import { ArticleService } from 'src/app/features/articles/article.service';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ApiMetaDataService {

  constructor(
    private router: Router,
    private http: HttpClient,
    private activatedRoute: ActivatedRoute,
    private platformLocation: PlatformLocation,
    private seoService: SeoService,
    private articleService: ArticleService,
    private contentService: ContentService,
    private mvpService: MVPService,
    private podcastService: PodcastsService,
    // private blogService: BlogsService
  ) {

  }

  getSlug() {

    return new Promise(
      (resolve, reject) => {
        const rtUrlArray = this.platformLocation.pathname.split('/').filter(
          (item) => {
            if(item){
              return item;
            }
          }
        );
        const pageType = rtUrlArray[2];
        if ((rtUrlArray.includes('mvps') || rtUrlArray.includes('articles') ||
          rtUrlArray.includes('blogs') || rtUrlArray.includes('podcasts')) &&
          (pageType === 'mvps' || pageType === 'articles' || pageType === 'podcasts' || pageType === 'blogs')
          && rtUrlArray.length > 3) {
          let titleSlug = rtUrlArray[rtUrlArray.length - 1];
          if ((environment.azureSearchKey || "").length < 5) {
            this.contentService.getCmsConfig().subscribe(
              (res) => {
                environment.azureSearchKey = res.azureSearchKey;

                switch (pageType) {
                  case 'articles': {
                    this.setArticleMetadata(titleSlug);
                    resolve(true)
                  }
                    break;
                  case 'mvps': {
                    this.setMvpMetadata(titleSlug);
                    resolve(true)
                  }
                    break;
                  case 'podcasts': {
                    this.setPodcastsMetadata(titleSlug);
                    resolve(true)
                  }
                    break;
                  case 'blogs': {
                    // this.setBlogsMetadata(titleSlug);
                    // resolve(true)
                  }
                    break;
                  default: {
                    resolve(true);
                  }
                    break;
                }
              },
              (error) => {
                console.log("Error", error);
                resolve(true);
              }
            )
          } else {
            switch (pageType) {
              case 'articles': {
                this.setArticleMetadata(titleSlug);
                resolve(true)
              }
                break;
              case 'mvps': {
                this.setMvpMetadata(titleSlug);
                resolve(true)
              }
                break;
              case 'podcasts': {
                this.setPodcastsMetadata(titleSlug);
                resolve(true)
              }
                break;
              case 'blogs': {
                // this.setBlogsMetadata(titleSlug);
                // resolve(true);
              }
                break;
              default: {
                resolve(true);
              }
                break;
            }
          }

        } else {
          resolve(true);
        }

      }
    )

  }

  setArticleMetadata(slug: string) {
    this.articleService.getArticles(1, slug).subscribe(
      (response) => {
        const seoData = response['value'][0]['seo'];
        const seoImage = response['value'][0]['feed_vp1_517x291'] ? response['value'][0]['feed_vp1_517x291'].replace('https://wipwebprodcdnv2.blob.core.windows.net', environment.assetsBasePath) : '';
        this.updateSeoMetaData(seoData['title'], seoData['metaDesciptionText'], seoImage, seoData['twitterCardType']);
      },
      (error) => {
        console.log("error", error);
      }
    );
  }

  setMvpMetadata(slug: string) {
    this.mvpService.getContents(1, slug).subscribe(
      (response) => {
        const seoData = response['value'][0]['seo'];
        const seoImage = response['value'][0]['personaImage'];
        this.updateSeoMetaData(seoData['title'], seoData['metaDesciptionText'], seoImage, seoData['twitterCardType']);
      },
      (error) => {
        console.log("error", error);
      }
    );
  }

  setPodcastsMetadata(slug: string) {
    this.podcastService.getContents(1, slug).subscribe(
      (response) => {
        const seoData = response['value'][0]['seo'];
        const seoImage = response['value'][0]['feed_vp1_517x291'];
        this.updateSeoMetaData(seoData['title'], seoData['metaDesciptionText'], seoImage, seoData['twitterCardType']);
      },
      (error) => {
        console.log("error", error);
      }
    );
  }

  // setBlogsMetadata(slug: string) {
  //   this.blogService.getBlogs(1, slug).subscribe(
  //     (response) => {
  //       const seoData = response['value'][0];
  //       const seoImage = 'https://wipwebprodcdnv2.blob.core.windows.net/wipmedia/sites/8/2022/12/windows-placeholder.jpg';
  //       this.updateSeoMetaData(seoData['title'], '', seoImage, 'summary_large_image');
  //     },
  //     (error) => {
  //       console.log("error", error);
  //     }
  //   );
  // }

  updateSeoMetaData(title: string, description: string, imageUrl: string, twitterCardType: string) {
    this.seoService.updateTitle(title);
    this.seoService.updateOgTitle(title);
    this.seoService.updateOgDescription(description);
    this.seoService.updateDescription(description);
    this.seoService.updateOgImage(imageUrl);
    this.seoService.updateOgSecureImage(imageUrl);
    this.seoService.updateTwitterTitle(title);
    this.seoService.updateTwitterImage(imageUrl);
    this.seoService.updateTwitterDescription(description);
    this.seoService.updateTwitterCard(twitterCardType);
  }
}
