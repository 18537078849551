import { Component, Inject, OnInit, Optional, PLATFORM_ID } from '@angular/core';
import { UtilityService } from '../../core/utility/utility.service';
import { TelemetryService } from '../../core/telemetry/telemetry.service';
import { Router } from '@angular/router';
import { AppInsightsErrorHandlerService } from 'src/app/core/app-insights-error-handler/app-insights-error-handler.service';

@Component({
  selector: 'app-page-unavailable',
  templateUrl: './page-unavailable.component.html',
  styleUrls: ['./page-unavailable.component.scss']
})
export class PageUnavailableComponent implements OnInit {

  public contentLoading = true;
  public metaData: any;
  constructor(
    private router: Router,
    private utilityService: UtilityService,
    private telemetryService: TelemetryService,
    private appInsightsErrorHandler: AppInsightsErrorHandlerService,
  ) { }

  ngOnInit(): void {
    this.getErrorPageConfig();
  }
  private getErrorPageConfig() {
    this.utilityService.getPageUnavailable().subscribe(result => {
      this.metaData = result;
      this.contentLoading = false;
    }, err => {
      this.telemetryService.logError('Error getting page unavailable: ' + JSON.stringify(err));
      this.appInsightsErrorHandler.handleError('Error getting page unavailable: ' + JSON.stringify(err));
      this.contentLoading = false;
    });
  }
}
