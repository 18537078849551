import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { environment } from '../../../environments/environment';
import { ContentService } from '../../core/contentRT/contentRT.service';
import { LocalizationService } from '../../core/localization/localization.service';
import { Countries, IMVPs, IProfile } from './mvps.model';
import { DeviceDetectorService } from 'ngx-device-detector';




@Injectable(
  { providedIn: 'root'}
)
export class MVPService {

  constructor(public http: HttpClient,
    private localizationService: LocalizationService,
    private contentService: ContentService,
    private deviceService: DeviceDetectorService) { }

  // // call this when the application boots up and it will allow component pages to get paging settings from cache
  // public getMVPsMetaSettings(): Observable<any> {
  //   const language = this.localizationService.getLanguage();
  //   const contentString = `mvp/mvp-meta.json&language=${language}`;
  //   return this.contentService.getCmsContent(contentString);
  // }

  public GetAllProfiles(): Observable<IProfile[]> {
    // return this.http.get<IProfile[]>(environment.urls.api + 'MvpProfiles');
    return this.http.get('./assets/data/mvp-profiles.json').pipe(
      map(res => res as IProfile[]));
  }

  public getSingleProfile(profileId: string): Observable<IProfile> {
    // return this.http.get<IProfile>(environment.urls.api + 'MvpProfiles/' + profileId);

    return this.http.get<IProfile>('./assets/data/mvp-profiles.json?id=' + profileId).pipe(
      map(res => res as IProfile), filter(<IProfile>(a) => a.id === profileId))[0];
  }

  public FilterProfiles(tags: string): Observable<IProfile[]> {
    // filter Data will work on server side
    return this.http.get('./assets/data/mvp-profiles.json?tag=' + tags).pipe(
      map(res => res as IProfile[]));
  }

  public getCountries(): Observable<Countries> {
    // tslint:disable-next-line: max-line-length
    const searchUrl = `${environment.urls.azureSearchApiUrl}api-version=2019-05-06&$select=country&$top=5000&$filter=postType eq 'mvp' and language eq '${this.localizationService.getLanguage()}' `;

    return this.http.get<IMVPs>(searchUrl, {
      headers: new HttpHeaders().set('api-key', environment.azureSearchKey)
    });
  }

  public getContents(page: number, slug: string = '', country: string = ''): Observable<IMVPs> {
    let pagingSize = this.deviceService.isDesktop() ? environment.mvpPageSizeDesktop : '0';
    const skip = (Number(pagingSize) + 2) * (page - 1);
    let slugFilter = '';
    let countryFilter = '';
    const pageSize = Number(pagingSize) + 2;

    if (slug) {
      slugFilter = `and slug eq '${slug}'`;
    }
    if (country) {
      countryFilter = `and country eq '${country}'`;
    }
    // tslint:disable-next-line: max-line-length
    const searchUrl = `${environment.urls.azureSearchApiUrl}api-version=2019-05-06&$count=true&$top=${pageSize}&$skip=${skip}&$orderby=lastName asc &$filter=postType eq 'mvp' ${countryFilter} ${slugFilter} and language eq '${this.localizationService.getLanguage()}' `;

    return this.http.get<IMVPs>(searchUrl, {
      headers: new HttpHeaders().set('api-key', environment.azureSearchKey)
    });
  }
}
