import { isPlatformBrowser } from '@angular/common';
import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { ApplicationInsights, IContentUpdateOverrideValues, IExceptionTelemetry, IExtendedConfiguration, IPageViewOverrideValues } from '@microsoft/1ds-analytics-web-js';
import { environment } from '../../../environments/environment';
import { LocalizationService } from '../localization/localization.service';
import { ClientError, ContentBlob, PageAction } from './telemetry.model';
import { HeaderLoaderService } from '../uhf/header-loader.service';
import { Subject } from 'rxjs';

declare var window: any;
declare var GPC_DataSharingOptIn: boolean;

@Injectable({
  providedIn: 'root'
})
export class TelemetryService {
  onDSGlobal: any;
  private oneDSLoaaderSubject = new Subject<void>();
  oneDSLoaded$ =  this.oneDSLoaaderSubject.asObservable();
  private isLoggedIn : boolean;

  constructor(
    private localizationService: LocalizationService,
    @Inject(PLATFORM_ID) private platformId: any,
    private headerLoaderService: HeaderLoaderService,
  ) {
  }

  //Initialize SDK

  /* Start 1DS methods */
  initialize1DsTelemetry() {
    if (isPlatformBrowser(this.platformId)) {

      this.headerLoaderService.headerLoaded$.subscribe(
        () => {
          const siteConsent = window.WcpConsent.siteConsent.getConsent;
          const analytics: ApplicationInsights = new ApplicationInsights();
          const coreConfig: IExtendedConfiguration = {
            instrumentationKey: environment.instrumentationKey1DS,
            channelConfiguration: { // Post channel configuration
              eventsLimitInMem: 5000
            },
            propertyConfiguration: { // Properties Plugin configuration
              gpcDataSharingOptIn: GPC_DataSharingOptIn,
              callback: {
                userConsentDetails: siteConsent ? siteConsent : undefined
              },
            },
            webAnalyticsConfiguration: { // Web Analytics Plugin configuration
              useShortNameForContentBlob: true,
              urlCollectQuery: true,
              urlCollectHash: true,
              isLoggedIn: this.isLoggedIn,
              autoCapture: {
                scroll: true,
                pageView: false,
                lineage: true,
                onLoad: true,
                onUnload: true,
                click: true,
                resize: true,
                jsError: true
              }
            }
          };
          // Initialize SDK
          analytics.initialize(coreConfig, []);
          if (GPC_DataSharingOptIn !== null || GPC_DataSharingOptIn != undefined) {
            analytics.getPropertyManager().getPropertiesContext().web.gpcDataSharingOptIn = GPC_DataSharingOptIn;
          }
          window.owap = analytics; //this is for UHF
          this.onDSGlobal = analytics.getWebAnalyticsExtension();
          this.onDSGlobal.config.isLoggedIn = this.isLoggedIn;
          this.loadAdobeLaunch();
        }
      )
    }
  }

  public updateLoggedInOwap(isAuthenticated) {
    if (isPlatformBrowser(this.platformId)) {
      this.isLoggedIn = (isAuthenticated ? true : false);
    }
  }

  public captureContentUpdate1DS(pageName: string): void {
    if (isPlatformBrowser(this.platformId)) {
      this.headerLoaderService.headerLoaded$.subscribe(
        () => {
          const overrideValues: IContentUpdateOverrideValues = {
            isAuto: false,
            behavior: 0,
            pageName: pageName,
          };
          //this.onDSGlobal.captureContentUpdate(overrideValues);
        }
      )
    }


  }

  public capturePageView(language = '', pageName = ''): void {
    if (isPlatformBrowser(this.platformId)) {
      this.headerLoaderService.headerLoaded$.subscribe(
        () => {
          const overrideValues: IPageViewOverrideValues = {
            isAuto: false,
            behavior: 0,
            pageName: pageName,
            pageTags: { market: language, aud: "consumer", state: pageName }
          };
          //this.onDSGlobal.capturePageView(overrideValues); //1DS
        }
      )
    }

  }


  public trackException1DS(errorInfo: string) {
    if (isPlatformBrowser(this.platformId)) {
      this.headerLoaderService.headerLoaded$.subscribe(
        () => {
          if (!environment.production) {
            console.error(errorInfo);
          }
    
          const exception: IExceptionTelemetry = {
            exception: {
              name: 'Ms.Webi.ClientError',
              message: errorInfo
            }
          };
    
          this.onDSGlobal.trackException(exception);
        }
      )
    }

  }

  /* End 1DS methods */

  /**
 /**
 *
 * @param pageName
 * @param queryString
 */
  public extendCoreData(pageName: string, pageType = '', queryString = ''): void {
    if (isPlatformBrowser(this.platformId)) {
      this.headerLoaderService.headerLoaded$.subscribe(
        () => {
          this.overrideOneDS(pageName, pageType, queryString);
        }
      )
      if (window.WcpConsent && window.WcpConsent.siteConsent && window.WcpConsent.siteConsent.getConsent) {
        this.overrideOneDS(pageName, pageType, queryString);
      }
    }

  }

  public captureContentUpdate(pageName: string): void {

    if (isPlatformBrowser(this.platformId)) {
      this.headerLoaderService.headerLoaded$.subscribe(
        () => {
          const overrideValues = {
            isAuto: false,
            behavior: 0,
            isManual: true,
            pageName: pageName,
            uri: location.pathname.substring(1),
            referrerUri: document.referrer
          };
    
          this.captureContentUpdate1DS(pageName);
        }
      )
    }

  }

  public logError(errorInfo: string, wasDisplayed = false) {

    if (isPlatformBrowser(this.platformId)) {

      this.headerLoaderService.headerLoaded$.subscribe(
        () => {
          if (!environment.production) {
            console.log(errorInfo);
          }
    
          const error: ClientError = {
            wasDisplayed: wasDisplayed,
            errorInfo: errorInfo,
            customSessionGuid: '',
            impressionGuid: ''
          };
    
          this.trackException1DS(errorInfo); // 1DS
        }
      )
    }


  }

  private overrideOneDS(pageName: string, pageType = '', queryString = '') {

    const jsllProps = {
      referrerUri: document.referrer,
      requestUri: window.location.href,
      pageName: pageName,
      env: environment.awaEnv,
      product: 'Windows Insider',
      market: this.localizationService.getLanguage(),
      pageType: pageType,
      pageTags: { state: queryString }
    };

    this.onDSGlobal.updateCoreDataConfig(jsllProps);

    const overrideValues: IPageViewOverrideValues = {
      isAuto: false,
      behavior: 0,
      pageName: pageName,
      pageTags: { market: this.localizationService.getLanguage(), aud: "consumer", state: queryString }
    };

    //Below code is used to add scenario and scenario step page tags in page web view payload 1DS
    const scnPageRoute = pageName.split('/');
    const scnPageName = scnPageRoute[scnPageRoute.length - 1].toLowerCase();
    if (scnPageName === 'register') {
      overrideValues.pageTags['scn'] = 'Register';
      overrideValues.pageTags['scnstp'] = 'Register for the Windows Insider Program';
    } else if (scnPageName === 'for-business-getting-started' ||
      scnPageName === 'getting-started' ||
      scnPageName === 'for-developers-getting-started') {
      overrideValues.pageTags['scn'] = 'Register';
      overrideValues.pageTags['scnstp'] = 'Begin to Preview Windows';
    }


    this.onDSGlobal.capturePageView(overrideValues); //1DS

    //this.capturePageView(pageName, queryString); // 1DS method to capture page view
  }

  private loadAdobeLaunch() {
    const newScript = document.createElement("script");
    newScript.type = "text/javascript";
    newScript.src = environment.adobeLaunchScriptUrl;
    document.body.appendChild(newScript);

    let counter = 0;
    const satelliteIntervalLoop = setInterval(
      () => {
        counter++;
        if(window._satellite){
          const newScript = document.createElement("script");
          newScript.type = "text/javascript";
          newScript.innerText = '_satellite.pageBottom();';
          document.body.appendChild(newScript);
          clearInterval(satelliteIntervalLoop);
        } else if(counter >= 500){
          clearInterval(satelliteIntervalLoop);
        }
      },10
    )

   }

}
