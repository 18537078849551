<!-- 1 Page -->
<div class="col-12" *ngIf="meta">
  <nav aria-label="">

    <!-- <nav> -->
    <ul *ngIf="pager.pages && pager.pages.length" class="col-12 my-5 pagination" style="text-align: center;">
      <li class="page-item" *ngIf="pager.currentPage != 1">
        <a href="javascript:void(0);" (click)="pageAction.emit(1)" class="page-link"
          [attr.aria-label]="meta.firstAriaLabel">{{meta.first}}</a>
      </li>
      <li class="page-item" [ngClass]="{disabled:pager.currentPage === 1}" *ngIf="pager.currentPage != 1">
        <a href="javascript:void(0);" class="page-link c-glyph" (click)="pageAction.emit(pager.currentPage - 1)"
          [attr.aria-label]="meta.previousAriaLabel">{{meta.previous}}</a>
      </li>
      <li class="page-item" *ngFor="let page of pager.pages" [ngClass]="{'active':pager.currentPage === page}">
        <a class="page-link" href="javascript:void(0);" *ngIf="pager.currentPage === page"
          [attr.aria-label]="pager.currentPage === page ? 'Active Page Number ' + page : 'Page Number ' + page">{{page}}</a>
        <a class="page-link" href="javascript:void(0);" (click)="pageAction.emit(page)"
          *ngIf="pager.currentPage !== page" [attr.aria-label]="'Page Number ' + page">{{page}}</a>
      </li>
      <li class="page-item" [ngClass]="{disabled:pager.currentPage === pager.totalPages}"
        *ngIf="pager.currentPage != pager.endPage">
        <a href="javascript:void(0);" class="page-link c-glyph" (click)="pageAction.emit(pager.currentPage + 1)"
          [attr.aria-label]="meta.nextAriaLabel">{{meta.next}}</a>
      </li>
      <li class="page-item" [ngClass]="{disabled:pager.currentPage === pager.totalPages}"
        *ngIf="pager.currentPage != pager.endPage">
        <a href="javascript:void(0);" class="page-link c-glyph" (click)="pageAction.emit(pager.totalPages)"
          [attr.aria-label]="meta.lastAriaLabel">{{meta.last}}</a>
      </li>
    </ul>
  </nav>
</div>
