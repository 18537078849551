import { Injectable } from '@angular/core';
import { AppInsightsErrorHandlerService } from 'src/app/core/app-insights-error-handler/app-insights-error-handler.service';
import { TelemetryService } from 'src/app/core/telemetry/telemetry.service';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class UmpVideoPlayerService {

  constructor(
    private appInsightsErrorHandler: AppInsightsErrorHandlerService,
    private telemetryService: TelemetryService,
  ) {

  }

  public addUMPVideoPlayer(doc) {
    const videoWrapperStr = doc.match(/<div\s.*id="video-.*"\s+options='\{[^}]*\}'><\/div>/ig);
    if (videoWrapperStr && videoWrapperStr.length > 0) {
      try {

        videoWrapperStr.forEach((videoItem) => {
          const domParser = new DOMParser();
          const videoWrapperElm = domParser.parseFromString(videoItem, 'text/html').querySelector('div.embed-responsive-item');
          const videoId = videoWrapperElm.getAttribute('id').replace('video-', '');
          const videoPlayerOption = videoWrapperElm.getAttribute('options');
  
          doc = doc.replaceAll(videoItem, this.createUmpPlayer(videoId, videoPlayerOption));
        });
        return doc;
      } catch (error) {
        this.telemetryService.logError(`Error loading videos : ${JSON.stringify(error)}`);
        this.appInsightsErrorHandler.handleError(`Error loading videos : ${JSON.stringify(error)}`);
        return doc;
      }

    } else {
      return doc;
    }
  }

  public createUmpPlayer(videoId, options) {

    const playerOption = {
      partnerName: "WindowsInsiderProgram",
      autoplay: false,
      title: videoId,
      language: "en-us",
      reporting: true,
      sources: [],
      ccFiles: [],
      poster:''

    }

    try {
      if(typeof options === 'string'){
        options = JSON.parse(options);
      }

      if (options.hasThumbnail) {
        playerOption['poster'] = `${environment.assetsBasePath}/video-thumbnails/${videoId}.jpg`;
      }

      if (options.hasVideo) {
        playerOption.sources.push({
          src: `${environment.assetsBasePath}/videos/${videoId}.mp4`,
          type: "video/mp4",
          quality: "HD",
        });
      }

      if (options.hasCaption) {
        playerOption.ccFiles.push({
          url: `${environment.assetsBasePath}/closed-captions/${videoId}.ttml`,
          locale: "en-us",
          ccType: "TTML",
        });
      }


      const umpVideoPlayer = `<div class="embed-responsive-item" id="video-${videoId}"><universal-media-player id="ump-video-${videoId}" options='${JSON.stringify(playerOption)}'></universal-media-player></div>`;

      return umpVideoPlayer;
    } catch (error) {
      this.telemetryService.logError(`Error creating player : ${JSON.stringify(error)}`);
      this.appInsightsErrorHandler.handleError(`Error creating player  : ${JSON.stringify(error)}`);
      const umpVideoPlayer = `<div class="embed-responsive-item" id="video-${videoId}"><universal-media-player id="ump-video-${videoId}" options='${JSON.stringify(playerOption)}'></universal-media-player></div>`;

      return umpVideoPlayer;
    }



  }
}
