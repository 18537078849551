import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { PagingService } from 'src/app/shared/paging/paging.service';
import { BusinessDeployComponent } from './business-deploy-nav/business-deploy.component';
import { CenteredHeadingComponent } from './centered-heading/center-heading.component';
import { AppliedFilterComponent } from './filters/applied-filter/applied-filter.component';
import { AudienceComponent } from './filters/audience/audience.component';
import { CategoryComponent } from './filters/category/category.component';
import { CountryComponent } from './filters/country/country.component';
import { DateComponent } from './filters/date/date.component';
import { FiltersComponent } from './filters/filters.component';
import { SearchContentComponent } from './filters/search-content/search-content.component';
import { LeftHeadingComponent } from './left-heading/left-heading.component';
import { NewsletterComponent } from './newsletter/newsletter.component';
import { NoRecordsFoundComponent } from './no-records-found/no-records-found.component';
import { PageContentComponent } from './page-content/page-content.component';
import { PageUnavailableComponent } from './page-unavailable/page-unavailable.component';
import { PagingComponent } from './paging/paging.component';
import { PlatformFilterPipe } from './platform.filter';
import { SafeHtmlPipe } from './safehtml.pipe';
import { SocialfooterComponent } from './socialfooter/socialfooter.component';
import { SharedSpinnerComponent } from './spinner/spinner.component';
import { SupplementalNavHandbookComponent } from './supplemental-nav-handbook/supplemental-nav-handbook.component';
import { SupplementalNavComponent } from './supplemental-nav/supplemental-nav.component';
import { WindowRef } from './windowref';
import { AcrylicComponent } from './acrylic/acrylic.component';
// import { NotificationComponent } from './notification/notification.component';
@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    FormsModule
  ],
  declarations: [
    AcrylicComponent,
    SafeHtmlPipe,
    PlatformFilterPipe,
    SupplementalNavComponent,
    LeftHeadingComponent,
    CenteredHeadingComponent,
    SupplementalNavHandbookComponent,
    BusinessDeployComponent,
    SharedSpinnerComponent,
    SearchContentComponent,
    SocialfooterComponent,
    PageContentComponent,
    PagingComponent,
    FiltersComponent,
    DateComponent,
    CountryComponent,
    AudienceComponent,
    CategoryComponent,
    PageUnavailableComponent,
    AppliedFilterComponent,
    NoRecordsFoundComponent,
    NewsletterComponent,
    // NotificationComponent
  ],
  providers: [
    WindowRef,
    PagingService
  ],
  exports: [
    AcrylicComponent,
    SafeHtmlPipe,
    PlatformFilterPipe,
    SupplementalNavComponent,
    LeftHeadingComponent,
    CenteredHeadingComponent,
    SupplementalNavHandbookComponent,
    BusinessDeployComponent,
    SharedSpinnerComponent,
    SearchContentComponent,
    SocialfooterComponent,
    PagingComponent,
    FiltersComponent,
    DateComponent,
    CountryComponent,
    AudienceComponent,
    CategoryComponent,
    AppliedFilterComponent,
    NoRecordsFoundComponent,
    NewsletterComponent
  ]
})
export class SharedModule { }
