import { DOCUMENT, PlatformLocation, isPlatformBrowser } from '@angular/common';
import { Inject, Injectable, PLATFORM_ID } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class BaseUrlService {
  private window: Window | null;
  constructor(
    @Inject(DOCUMENT) 
    private document: Document,
    @Inject(PLATFORM_ID) private platformId: any,
    private platformLocation: PlatformLocation
  ) {
    if (isPlatformBrowser(this.platformId)){
    this.window = this.document.defaultView;
    }
   }

   getCookie(cname) {
    const name = cname + '=';
    const ca = this.document.cookie.split(';');
    for (let i = 0; i < ca.length; i++) {
      let c = ca[i];
      while (c.charAt(0) === ' ') {
        c = c.substring(1);
      }
      if (c.indexOf(name) === 0) {
        return c.substring(name.length, c.length);
      }
    }
    return '';
  }
  
  getQuerysString() {
    const qs = this.window.location.search;
    if (qs.indexOf('?omkt=') >= 0) { // we are looking for the locale picker QS only.  Ignoring others.
      return qs.replace('?omkt=', '').toLowerCase();
    } else {
      return '';
    }
  }
  
  getBaseUrl() {

    if (isPlatformBrowser(this.platformId)){
      const pathArray = this.window.location.pathname.split('/');
  
    // if(lang === 'de-de' || lang === 'ja-jp' || lang === 'fr-fr' || lang === 'pt-br'
    // || lang === 'zh-cn' || lang === 'es-es' || lang === 'it-it' || lang === 'ru-ru') {
  
    const languages = [
      'EN-US',
      'PT-BR',
      'DE-DE',
      'RU-RU',
      'ES-ES',
      'IT-IT',
      'JA-JP',
      'FR-FR',
      'ZH-CN'
    ];
  
  
    const d = new Date();
    d.setTime(d.getTime() + (1 * 24 * 60 * 60 * 1000));
    const expires = 'expires=' + d.toUTCString();
  
    // if we came from the locale picker
    // but make sure it is not one of the temporay unsupport locales so that those can be redirected below
    const mktQueryString = this.getQuerysString();
    if (mktQueryString !== 'fr' && mktQueryString !== 'es' && mktQueryString !== 'en' && !mktQueryString.startsWith('locale=')) {
  
      if (mktQueryString !== '') {
        this.document.cookie = 'locale=' + mktQueryString + ';' + expires + '; path=/';
        return '/' + mktQueryString;
      }
    }
  
    // check for en-us
    if (pathArray[1].toLowerCase() === 'en-us') {
      this.document.cookie = 'locale=en-us;' + expires + '; path=/';
      return '/en-us';
    }
  
    // Temporary checks until UHF can undo the fallback mappings
    // check for ar
  
    // Right to left is not completed so forward to en-us
    // if (pathArray[1].toLowerCase() === "ar" || pathArray[1].toLowerCase() === "ar-sa" || pathArray[1].toLowerCase() === "he-il") {
    //   document.cookie = 'locale=en-us;' + expires + '; path=/';
    //   window.location.href = 'https://' + window.location.hostname + '/en-us/';
    // }
  
    // if (mktQueryString === "ar" || mktQueryString === "ar-sa" || mktQueryString === "he-il") {
    //   document.cookie = 'locale=en-us;' + expires + '; path=/';
    //   window.location.href = 'https://' + window.location.hostname + '/en-us/';
    // }
  
    // check for en-us
    if (pathArray[1].toLowerCase() === 'en' || mktQueryString === 'en') {
      this.document.cookie = 'locale=en-us;' + expires + '; path=/';
      this.window.location.href = 'https://' + this.window.location.hostname + '/en-us/windowsinsider';
    }
  
    // check for fr
    if (pathArray[1].toLowerCase() === 'fr' || mktQueryString === 'fr') {
      this.document.cookie = 'locale=fr-fr;' + expires + '; path=/';
      this.window.location.href = 'https://' + this.window.location.hostname + '/fr-fr/windowsinsider';
    }
  
    // check for es
    if (pathArray[1].toLowerCase() === 'es' || mktQueryString === 'es') {
      this.document.cookie = 'locale=es-es;' + expires + '; path=/';
      this.window.location.href = 'https://' + this.window.location.hostname + '/es-es/windowsinsider';
    }
    // Temporary checks until UHF can undo the fallback mappings
  
    // URL has segements
    if (pathArray[0].toLowerCase() === '' && pathArray[1].toLowerCase() !== '' && pathArray[1].toLowerCase() !== 'windowsinsider') {
      let isSupported = false;
      // console.log(pathArray);
      if (languages.indexOf(pathArray[1].toLocaleUpperCase()) >= 0) {
        isSupported = true;
      }
  
      if (isSupported) {
        this.document.cookie = 'locale=' + pathArray[1].toLowerCase() + ';' + expires + '; path=/';
        return '/' + pathArray[1].toLowerCase();
      } else {
        const localeCookie = this.getCookie('locale');
  
        if (localeCookie !== '' && languages.indexOf(localeCookie.toUpperCase()) >= 0) {
          this.document.cookie = 'locale=' + localeCookie.toLowerCase() + ';' + expires + '; path=/';
          return '/' + localeCookie.toLowerCase();
        }
      }
    }
  
    // URL does not have segments
    if (pathArray[1].toLowerCase() === 'windowsinsider' && pathArray[0].toLowerCase() === '') {
  
      // We currently have not found a supported language in the URL
      // nothing in the URL so check.
      // 1. check cookie first because they may have hand entered a local
      // 2.  Then check browser language
      const localeCookie = this.getCookie('locale');
  
      if (localeCookie !== '' && languages.indexOf(localeCookie.toUpperCase()) >= 0) {
        this.document.cookie = 'locale=' + localeCookie.toLowerCase() + ';' + expires + '; path=/';
        return '/' + localeCookie.toLowerCase();
      }
  
      // nothing in the URL so check browser setting
      const browserLanguage = navigator.language.toLowerCase();
  
      // Did not find a browswer language setting so return the default en-us
      if (browserLanguage === '' && browserLanguage === undefined || browserLanguage === 'en-us') {
        this.document.cookie = 'locale=en-us;' + expires + '; path=/';
        return '/en-us';
      }
  
  
      if (languages.indexOf(browserLanguage.toUpperCase()) >= 0) {
        this.document.cookie = 'locale=' + browserLanguage.toLowerCase() + ';' + expires + '; path=/';
        return '/' + browserLanguage.toLowerCase();
      } else {
        this.document.cookie = 'locale=en-us;' + expires + '; path=/';
        return '/en-us';
      }
    }
    }
    
  }


  getServerBaseUrl(){

    let baseUrl = `/en-us/windowsinsider`

    const languages = [
      'EN-US',
      'PT-BR',
      'DE-DE',
      'RU-RU',
      'ES-ES',
      'IT-IT',
      'JA-JP',
      'FR-FR',
      'ZH-CN',
    ];

    if(this.platformLocation.pathname){
      const languageCode = this.platformLocation.pathname.split('/')[1];
      if(languageCode) {
        if(languages.includes(languageCode.toUpperCase())){
          return `/${languageCode}/windowsinsider`
        } else {
          return baseUrl;
        }
      } else {
        return baseUrl;
      }

    } else {
      return baseUrl;
    }
    
  }
}
