import { CommonModule } from '@angular/common';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { SharedModule } from '../shared/shared.module';
import { AuthGuard } from './auth/auth-guard.service';
import { MsalHelperService } from './auth/msalHelper.service';
import { TokenInterceptor } from './auth/token.interceptor';
import { MWFHeadingComponent } from './MWF/heading/heading.component';
import { MWFParagraphComponent } from './MWF/paragraph/paragraph.component';
import { UhfFooterComponent } from './uhf/footer/uhf-footer.component';
import { UhfHeaderComponent } from './uhf/header/uhf-header.component';
import { UhfService } from './uhf/uhf.service';


@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    SharedModule
  ],
  declarations: [
    UhfFooterComponent,
    UhfHeaderComponent,
    MWFHeadingComponent,
    MWFParagraphComponent
  ],
  exports: [
    UhfFooterComponent,
    UhfHeaderComponent,
    MWFHeadingComponent,
    MWFParagraphComponent
  ],
  providers: [
    MsalHelperService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: TokenInterceptor,
      multi: true
    },
    AuthGuard,
    UhfService
  ]
})
export class CoreModule { }
// export class CoreModule {
//   static forRoot() {
//     return {
//       ngModule: CoreModule,
//       providers: [  ]
//     };
//   }
//  }
