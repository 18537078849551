import { Component, EventEmitter, Input, Output, OnChanges } from '@angular/core';
import { take } from 'rxjs/operators';

import { TelemetryService } from '../../core/telemetry/telemetry.service';
import { PagingService } from '../../shared/paging/paging.service';
import { ContentService } from 'src/app/core/contentRT/contentRT.service';
import { AppInsightsErrorHandlerService } from 'src/app/core/app-insights-error-handler/app-insights-error-handler.service';


@Component({
  selector: 'app-paging',
  templateUrl: './paging.component.html',
  styleUrls: ['./paging.component.scss']
})

export class PagingComponent implements OnChanges {
  @Input() data: any[];
  @Input() totalRecords: number;
  @Input() page: number;
  @Input() pageSize: number;
  @Output() pageAction = new EventEmitter();
  public pager: any = {};
  public meta: any;
  public pagedItems: any[];

  constructor(private pagerService: PagingService,
    private telemetryService: TelemetryService,
    private contentService: ContentService,
    private appInsightsErrorHandler: AppInsightsErrorHandlerService) { }

  public ngOnChanges() {
    this.loadMetaData();
    this.setPage();
  }

  private loadMetaData() {
    this.contentService.getMetaConfigs().pipe(take(1)).subscribe((data) => {
      this.meta = data.paging;
    },
      (err: any) => {
        this.telemetryService.logError('Error while getting paging meta : ' + JSON.stringify(err));
        this.appInsightsErrorHandler.handleError('Error while getting paging meta : ' + JSON.stringify(err))
      });
  }

  private setPage() {

    if (this.page < 1 || this.page > this.pager.totalPages) {
      return;
    }
    // get pager object from service
    this.pager = this.pagerService.getPager(this.totalRecords, this.page, this.pageSize);

    // get current page of items
    this.pagedItems = this.data.slice(this.pager.startIndex, this.pager.endIndex + 1);
  }
}
