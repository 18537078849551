import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot } from '@angular/router';
// import { OAuthService } from 'angular-oauth2-oidc';
import { Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { AppInsightsErrorHandlerService } from '../app-insights-error-handler/app-insights-error-handler.service';
import { IFlightSettings } from '../core.model';
import { TelemetryService } from '../telemetry/telemetry.service';
import { UserService } from '../user.service';
import { MsalHelperService } from './msalHelper.service';


@Injectable()
export class AuthGuard  {
    public user: IFlightSettings;

    constructor(private userService: UserService,
        private appInsightsErrorHandler: AppInsightsErrorHandlerService,
        private telemetryService: TelemetryService,
        private msalService: MsalHelperService,
        private router: Router) { }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
        return this.userService.getUserSettings().pipe(
            map((user: IFlightSettings) => {
                this.user = user;
                this.userService.setUser(this.user);
                if (this.user != null) {
                    if (!this.user.isAuthenticated) {
                        // this.msalService.loginRedirect();
                        this.router.navigateByUrl('/getting-started#register');
                    } else {
                        return true;
                    }
                }
            }),
            catchError((error: any) => {
                this.telemetryService.logError(JSON.stringify(error));
                this.appInsightsErrorHandler.handleError(JSON.stringify(error));
                return of(false);
            })
        );
    }
}
