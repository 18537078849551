import { ErrorHandler, Injectable, Injector } from '@angular/core';
import { environment } from 'src/environments/environment';
import { AppInsightServicesService } from '../app-insights-services/app-insight-services.service';

@Injectable({
  providedIn: 'root'
})
export class AppInsightsErrorHandlerService implements ErrorHandler {

  constructor(
    private appInsightService: AppInsightServicesService
  ) {
  }

  handleError(error: any) {
    this.appInsightService.logException(error);
    if(!environment.production){
      console.log(error);
    }
  }
}
