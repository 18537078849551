import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { ReferralComponent } from './community/referral/referral.component';
import { FeedbackHubErrorComponent } from './feedback-hub/fb-error/fb-error.component';
import { FeedbackHubNonWin10Component } from './feedback-hub/hub-non-win10/hub-non-win10.component';
import { FeedbackHubOpenComponent } from './feedback-hub/open/open.component';
import { HomeComponent } from './home/home.component';
import { StudentComponent } from './about/student/student.component';
import { SearchComponent } from './search/search.component';
import { PageUnavailableComponent } from './shared/page-unavailable/page-unavailable.component';
import { winPrefix } from './win-sv/win-prefix';;
import { environment } from 'src/environments/environment';
const appRoutes: Routes = [
  {
    path: 'windowsinsider',
    redirectTo: '',
    pathMatch: 'full',
  },
  {
    path: 'windowsinsider/:component',
    redirectTo: ':component',
    pathMatch: 'full',
  },
  {
    path: 'windowsinsider/:component/:id',
    redirectTo: ':component/:id',
    pathMatch: 'full',
  },
  {
    path: 'understand-flighting',
    redirectTo: 'preview-windows',
    pathMatch: 'full',
  },
  {
    path: 'understand-feedback',
    redirectTo: 'feedback',
    pathMatch: 'full',
  },
  {
    path: '',
    component: HomeComponent
  },
  {
    path: 'home',
    component: HomeComponent,
    data: {
      title: 'The Windows Insider Program',
      ogTitle: 'The Windows Insider Program',
      description: "Be the first to see what's next for Windows in the Windows Insider Program. Join the community, provide feedback to help make Windows even better.",
      ogDescription: "Be the first to see what's next for Windows in the Windows Insider Program. Join the community, provide feedback to help make Windows even better.",
      ogUrl: '',
      ogImage: environment.assetsBasePath + '/wipmedia/sites/8/2023/04/i1_1200.png',
      ogSecureImage: environment.assetsBasePath + '/wipmedia/sites/8/2023/04/i1_1200.png',
      twitterCard: 'summary_large_image',
      twitterTitle: 'The Windows Insider Program',
      twitterDescription: "Be the first to see what's next for Windows in the Windows Insider Program. Join the community, provide feedback to help make Windows even better.",
      twitterUrl: '',
      twitterImage: environment.assetsBasePath + '/wipmedia/sites/8/2023/04/i1_twitter.png',
      twitterSecureImage: environment.assetsBasePath + '/wipmedia/sites/8/2023/04/i1_twitter.png'
    }
  },
  {
    path: 'referral',
    component: ReferralComponent,
    data: {
      title: 'Share the Windows Insider Program',
      ogTitle: 'Share the Windows Insider Program',
      description: 'Share your love of getting Windows Insider Preview Builds and trying new features with your friends and family.',
      ogDescription: 'Share your love of getting Windows Insider Preview Builds and trying new features with your friends and family.',
      ogUrl: 'referral',
      ogImage: 'https://wip-web-cdn.azureedge.net/wipmedia/compass/1354b52c-5459-4a17-b0a9-58a548f77cdc.jpg?n=Referral%202%20-%20Highlight%20feature%20slim%20vp2.jpg',
      ogSecureImage: 'https://wip-web-cdn.azureedge.net/wipmedia/compass/1354b52c-5459-4a17-b0a9-58a548f77cdc.jpg?n=Referral%202%20-%20Highlight%20feature%20slim%20vp2.jpg',
      twitterCard: 'summary_large_image',
      twitterTitle: 'Share the Windows Insider Program',
      twitterDescription: 'Share your love of getting Windows Insider Preview Builds and trying new features with your friends and family.',
      twitterUrl: 'referral',
      twitterImage: 'https://wip-web-cdn.azureedge.net/wipmedia/compass/1354b52c-5459-4a17-b0a9-58a548f77cdc.jpg?n=Referral%202%20-%20Highlight%20feature%20slim%20vp2.jpg',
      twitterSecureImage: 'https://wip-web-cdn.azureedge.net/wipmedia/compass/1354b52c-5459-4a17-b0a9-58a548f77cdc.jpg?n=Referral%202%20-%20Highlight%20feature%20slim%20vp2.jpg'
    }
  },
  // {
  //   path: 'studentambassadors',
  //   component: StudentComponent,
  //   data: {
  //     title: 'The Windows Insider Program for Student Ambassadors',
  //     ogTitle: 'The Windows Insider Program for Student Ambassadors',
  //     description: 'Windows Insider Program has partnered with Microsoft Learn Student Ambassadors to empower students to achieve more. Learn more about program benefites.',
  //     ogDescription: 'Windows Insider Program has partnered with Microsoft Learn Student Ambassadors to empower students to achieve more. Learn more about program benefites.',
  //     ogUrl: 'studentambassadors',
  //     ogImage: environment.assetsBasePath + '/wipmedia/sites/8/2022/04/Landing-Page-Hero-Image-539x440-1.jpg',
  //     ogSecureImage: environment.assetsBasePath + '/wipmedia/sites/8/2022/04/Landing-Page-Hero-Image-539x440-1.jpg',
  //     twitterCard: 'summary_large_image',
  //     twitterTitle: 'The Windows Insider Program for Student Ambassadors',
  //     twitterDescription: 'Windows Insider Program has partnered with Microsoft Learn Student Ambassadors to empower students to achieve more. Learn more about program benefites.',
  //     twitterUrl: 'studentambassadors',
  //     twitterImage: environment.assetsBasePath + '/wipmedia/sites/8/2022/04/Landing-Page-Hero-Image-539x440-1.jpg',
  //     twitterSecureImage: environment.assetsBasePath + '/wipmedia/sites/8/2022/04/Landing-Page-Hero-Image-539x440-1.jpg'
  //   }
  // },
  {
    path: 'feedbackhub/fb',
    component: FeedbackHubOpenComponent,
    data: { title: '' }
  },
  {
    path: 'fb',
    component: FeedbackHubOpenComponent,
    data: { title: '' }
  },
  {
    path: 'fb-error',
    component: FeedbackHubErrorComponent,
    data: { title: '' }
  },
  {
    path: 'ih',
    component: FeedbackHubOpenComponent,
    data: { title: '' }
  },
  {
    path: 'ih-error',
    component: FeedbackHubErrorComponent,
    data: { title: '' }
  },
  {
    path: 'feedbackhub/ih',
    component: FeedbackHubOpenComponent,
    data: { title: '' }
  },
  {
    path: 'fb-non-win-10',
    component: FeedbackHubNonWin10Component,
    data: { title: '' }
  },
  {
    path: 'official-rules',
    loadChildren: () => import('./pages/pages.module').then(m => m.PagesModule)
  },
  {
    path: 'for-business',
    loadChildren: () => import('./about/business/business.module').then(m => m.BusinessModule),
    data: {
      title: 'The Windows Insider Program for Business',
      ogTitle: 'The Windows Insider Program for Business',
      description: 'Explore the latest Windows features, validate your apps and infrastructure, and deploy faster as a Windows Insider for your organisation.',
      ogDescription: 'Explore the latest Windows features, validate your apps and infrastructure, and deploy faster as a Windows Insider for your organisation.',
      ogUrl: 'for-business',
      ogImage: 'https://wip-web-cdn.azureedge.net/wipmedia/compass/710156a7-504a-474b-9fc3-1b67ccb5d2fa.png?n=Biz%20offset%20feature%20vp2.png',
      ogSecureImage: 'https://wip-web-cdn.azureedge.net/wipmedia/compass/710156a7-504a-474b-9fc3-1b67ccb5d2fa.png?n=Biz%20offset%20feature%20vp2.png',
      twitterCard: 'summary_large_image',
      twitterTitle: 'The Windows Insider Program for Business',
      twitterDescription: 'Explore the latest Windows features, validate your apps and infrastructure, and deploy faster as a Windows Insider for your organisation.',
      twitterUrl: 'for-business',
      twitterImage: 'https://wip-web-cdn.azureedge.net/wipmedia/compass/710156a7-504a-474b-9fc3-1b67ccb5d2fa.png?n=Biz%20offset%20feature%20vp2.png',
      twitterSecureImage: 'https://wip-web-cdn.azureedge.net/wipmedia/compass/710156a7-504a-474b-9fc3-1b67ccb5d2fa.png?n=Biz%20offset%20feature%20vp2.png'
    }
  },
  {
    path: 'for-developers',
    loadChildren: () => import('./about/developers/developers.module').then(m => m.DevelopersModule),
    data: {
      title: 'The Windows Insider Program for Developers',
      ogTitle: 'The Windows Insider Program for Developers',
      description: 'The Windows Insider Program for Developers has tools to help you bring better applications to your customers faster.',
      ogDescription: 'The Windows Insider Program for Developers has tools to help you bring better applications to your customers faster.',
      ogUrl: 'for-developers',
      ogImage: 'https://wip-web-cdn.azureedge.net/wipmedia/compass/ac34365f-9415-44e1-9185-6b81217365f1.jpg?n=Dev%20offset%20feature%20vp2.jpg',
      ogSecureImage: 'https://wip-web-cdn.azureedge.net/wipmedia/compass/ac34365f-9415-44e1-9185-6b81217365f1.jpg?n=Dev%20offset%20feature%20vp2.jpg',
      twitterCard: 'summary_large_image',
      twitterTitle: 'The Windows Insider Program for Developers',
      twitterDescription: 'The Windows Insider Program for Developers has tools to help you bring better applications to your customers faster.',
      twitterUrl: 'for-developers',
      twitterImage: 'https://wip-web-cdn.azureedge.net/wipmedia/compass/ac34365f-9415-44e1-9185-6b81217365f1.jpg?n=Dev%20offset%20feature%20vp2.jpg',
      twitterSecureImage: 'https://wip-web-cdn.azureedge.net/wipmedia/compass/ac34365f-9415-44e1-9185-6b81217365f1.jpg?n=Dev%20offset%20feature%20vp2.jpg'
    }
  },
  {
    path: 'microsoft-user-research-customer-calls',
    loadChildren: () => import('./intercept/intercept.module').then(m => m.InterceptModule)
  },
  {
    path: 'about-windows-insider-program',
    loadChildren: () => import('./about/program/program.module').then(m => m.ProgramModule),
    data: {
      title: 'What is the Windows Insider Program?',
      ogTitle: 'What is the Windows Insider Program?',
      description: 'Learn how to join the Windows Insider Program, install the early version of Windows, preview Windows, and provide feedback.',
      ogDescription: 'Learn how to join the Windows Insider Program, install the early version of Windows, preview Windows, and provide feedback.',
      ogUrl: 'about-windows-insider-program',
      ogImage: environment.assetsBasePath + '/wipmedia/sites/8/2023/04/i1_1200.png',
      ogSecureImage: environment.assetsBasePath + '/wipmedia/sites/8/2023/04/i1_1200.png',
      twitterCard: 'summary_large_image',
      twitterTitle: 'What is the Windows Insider Program?',
      twitterDescription: 'Learn how to join the Windows Insider Program, install the early version of Windows, preview Windows, and provide feedback.',
      twitterUrl: 'about-windows-insider-program',
      twitterImage: environment.assetsBasePath + '/wipmedia/sites/8/2023/04/i1_twitter.png',
      twitterSecureImage: environment.assetsBasePath + '/wipmedia/sites/8/2023/04/i1_twitter.png'
    }
  },
  {
    path: 'podcasts',
    loadChildren: () => import('./community/podcasts/podcasts.module').then(m => m.PodcastsModule),
    data: {
      title: 'Windows Insider Podcast',
      ogTitle: 'Windows Insider Podcast',
      description: 'Listen to the Windows Insider Podcast, where leaders from Microsoft and Windows Insiders discuss tech trends, careers, and innovation.',
      ogDescription: 'Listen to the Windows Insider Podcast, where leaders from Microsoft and Windows Insiders discuss tech trends, careers, and innovation.',
      ogUrl: 'podcasts',
      ogImage: 'https://wip-web-cdn.azureedge.net/wipmedia/compass/01ef3b7a-da47-44df-989c-18c9ef15fd04.jpg?n=Podcasts%20-%20Highlight-feature-slim-vp2.jpg',
      ogSecureImage: 'https://wip-web-cdn.azureedge.net/wipmedia/compass/01ef3b7a-da47-44df-989c-18c9ef15fd04.jpg?n=Podcasts%20-%20Highlight-feature-slim-vp2.jpg',
      twitterCard: 'summary_large_image',
      twitterTitle: 'Windows Insider Podcast',
      twitterDescription: 'Listen to the Windows Insider Podcast, where leaders from Microsoft and Windows Insiders discuss tech trends, careers, and innovation.',
      twitterUrl: 'podcasts',
      twitterImage: 'https://wip-web-cdn.azureedge.net/wipmedia/compass/01ef3b7a-da47-44df-989c-18c9ef15fd04.jpg?n=Podcasts%20-%20Highlight-feature-slim-vp2.jpg',
      twitterSecureImage: 'https://wip-web-cdn.azureedge.net/wipmedia/compass/01ef3b7a-da47-44df-989c-18c9ef15fd04.jpg?n=Podcasts%20-%20Highlight-feature-slim-vp2.jpg'
    }
  },
  {
    path: 'contests',
    loadChildren: () => import('./community/contests/contests.module').then(m => m.ContestsModule)
  },
  {
    path: 'articles',
    loadChildren: () => import('./features/articles/articles.module').then(m => m.ArticlesModule),
    data: {
      title: 'Windows Insider Program Articles',
      ogTitle: 'Windows Insider Program Articles',
      description: "Read what's next for the Windows Insider Program, including what changes Windows Insiders have helped drive, new features, tips and tricks, Insider stories.",
      ogDescription: "Read what's next for the Windows Insider Program, including what changes Windows Insiders have helped drive, new features, tips and tricks, Insider stories.",
      ogUrl: 'articles',
      ogImage: 'https://wip-web-cdn.azureedge.net/wipmedia/compass/2f1f5239-4310-4eff-96b6-d61e2528f7f8.jpg?n=Articles%20-%20Highlight%20feature%20slim%20vp2.jpg',
      ogSecureImage: 'https://wip-web-cdn.azureedge.net/wipmedia/compass/2f1f5239-4310-4eff-96b6-d61e2528f7f8.jpg?n=Articles%20-%20Highlight%20feature%20slim%20vp2.jpg',
      twitterCard: 'summary_large_image',
      twitterTitle: 'Windows Insider Program Articles',
      twitterDescription: "Read what's next for the Windows Insider Program, including what changes Windows Insiders have helped drive, new features, tips and tricks, Insider stories.",
      twitterUrl: 'articles',
      twitterImage: 'https://wip-web-cdn.azureedge.net/wipmedia/compass/2f1f5239-4310-4eff-96b6-d61e2528f7f8.jpg?n=Articles%20-%20Highlight%20feature%20slim%20vp2.jpg',
      twitterSecureImage: 'https://wip-web-cdn.azureedge.net/wipmedia/compass/2f1f5239-4310-4eff-96b6-d61e2528f7f8.jpg?n=Articles%20-%20Highlight%20feature%20slim%20vp2.jpg',
    }
  },
  {
    path: 'about-windows-insider-program',
    loadChildren: () => import('./about/program/program.module').then(m => m.ProgramModule)
  },
  {
    path: 'for-developers',
    loadChildren: () => import('./about/developers/developers.module').then(m => m.DevelopersModule)
  },
  {
    path: 'for-business',
    loadChildren: () => import('./about/business/business.module').then(m => m.BusinessModule)
  },
  {
    path: 'events',
    loadChildren: () => import('./community/events/events.module').then(m => m.EventsModule),
    data: {
      title: 'Windows Insider Program Events',
      ogTitle: 'Windows Insider Program Events',
      description: 'Windows Insider Program calendar of conferences, global tours, contests, webinar, and user research sessions.',
      ogDescription: 'Windows Insider Program calendar of conferences, global tours, contests, webinar, and user research sessions.',
      ogUrl: 'events',
      ogImage: 'https://wip-web-cdn.azureedge.net/wipmedia/compass/013b9c9d-ed00-49b1-b30b-958cd3421c03.jpg?n=Events%20-%20Highlight%20feature%20slim%20vp2.jpg',
      ogSecureImage: 'https://wip-web-cdn.azureedge.net/wipmedia/compass/013b9c9d-ed00-49b1-b30b-958cd3421c03.jpg?n=Events%20-%20Highlight%20feature%20slim%20vp2.jpg',
      twitterCard: 'summary_large_image',
      twitterTitle: 'Windows Insider Program Events',
      twitterDescription: 'Windows Insider Program calendar of conferences, global tours, contests, webinar, and user research sessions.',
      twitterUrl: '',
      twitterImage: 'https://wip-web-cdn.azureedge.net/wipmedia/compass/013b9c9d-ed00-49b1-b30b-958cd3421c03.jpg?n=Events%20-%20Highlight%20feature%20slim%20vp2.jpg',
      twitterSecureImage: 'https://wip-web-cdn.azureedge.net/wipmedia/compass/013b9c9d-ed00-49b1-b30b-958cd3421c03.jpg?n=Events%20-%20Highlight%20feature%20slim%20vp2.jpg'
    }
  },
  {
    path: 'referral',
    loadChildren: () => import('./community/referral/referral.module').then(m => m.ReferralModule),
    data: {
      title: 'Share the Windows Insider Program',
      ogTitle: 'Share the Windows Insider Program',
      description: 'Share your love of getting Windows Insider Preview Builds and trying new features with your friends and family.',
      ogDescription: 'Share your love of getting Windows Insider Preview Builds and trying new features with your friends and family.',
      ogUrl: 'referral',
      ogImage: 'https://wip-web-cdn.azureedge.net/wipmedia/compass/1354b52c-5459-4a17-b0a9-58a548f77cdc.jpg?n=Referral%202%20-%20Highlight%20feature%20slim%20vp2.jpg',
      ogSecureImage: 'https://wip-web-cdn.azureedge.net/wipmedia/compass/1354b52c-5459-4a17-b0a9-58a548f77cdc.jpg?n=Referral%202%20-%20Highlight%20feature%20slim%20vp2.jpg',
      twitterCard: 'summary_large_image',
      twitterTitle: 'Share the Windows Insider Program',
      twitterDescription: 'Share your love of getting Windows Insider Preview Builds and trying new features with your friends and family.',
      twitterUrl: '',
      twitterImage: 'https://wip-web-cdn.azureedge.net/wipmedia/compass/1354b52c-5459-4a17-b0a9-58a548f77cdc.jpg?n=Referral%202%20-%20Highlight%20feature%20slim%20vp2.jpg',
      twitterSecureImage: 'https://wip-web-cdn.azureedge.net/wipmedia/compass/1354b52c-5459-4a17-b0a9-58a548f77cdc.jpg?n=Referral%202%20-%20Highlight%20feature%20slim%20vp2.jpg'
    }
  },
  // {
  //   path: 'mvps',
  //   loadChildren: () => import('./community/mvps/mvps.module').then(m => m.MvpsModule),
  //   data: {
  //     title: 'Windows Insider Most Valuable Professional',
  //     ogTitle: 'Windows Insider Most Valuable Professional',
  //     description: 'Learn how to become The Windows Insider Most Valuable Professional (MVP), gain program benefites, and meet Windows Insider MVPs near you.',
  //     ogDescription: 'Learn how to become The Windows Insider Most Valuable Professional (MVP), gain program benefites, and meet Windows Insider MVPs near you.',
  //     ogUrl: 'mvps',
  //     ogImage: environment.assetsBasePath + '/wipmedia/sites/8/2022/07/VP5_800x450_Windows_Insiders_London.jpg',
  //     ogSecureImage: environment.assetsBasePath + '/wipmedia/sites/8/2022/07/VP5_800x450_Windows_Insiders_London.jpg',
  //     twitterCard: 'summary_large_image',
  //     twitterTitle: 'Windows Insider Most Valuable Professional',
  //     twitterDescription: 'Learn how to become The Windows Insider Most Valuable Professional (MVP), gain program benefites, and meet Windows Insider MVPs near you.',
  //     twitterUrl: 'mvps',
  //     twitterImage: environment.assetsBasePath + '/wipmedia/sites/8/2022/07/VP5_800x450_Windows_Insiders_London.jpg',
  //     twitterSecureImage: environment.assetsBasePath + '/wipmedia/sites/8/2022/07/VP5_800x450_Windows_Insiders_London.jpg'
  //   }
  // },
  // {
  //   path: 'blogs',
  //   loadChildren: () => import('./community/blogs/blogs.module').then(m => m.BlogsModule),
  //   data: {
  //     title: 'Windows Insider Blogs',
  //     ogTitle: 'Windows Insider Blogs',
  //     description: 'Windows Insider Blogs',
  //     ogDescription: 'Windows Insider Blogs',
  //     ogUrl: 'blogs',
  //     ogImage: environment.assetsBasePath + '/wipmedia/sites/8/2022/07/VP5_800x450_Windows_Insiders_London.jpg',
  //     ogSecureImage: environment.assetsBasePath + '/wipmedia/sites/8/2022/07/VP5_800x450_Windows_Insiders_London.jpg',
  //     twitterCard: 'summary_large_image',
  //     twitterTitle: 'Windows Insider Blogs',
  //     twitterDescription: 'Windows Insider Blogs',
  //     twitterUrl: 'blogs',
  //     twitterImage: environment.assetsBasePath + '/wipmedia/sites/8/2022/07/VP5_800x450_Windows_Insiders_London.jpg',
  //     twitterSecureImage: environment.assetsBasePath + '/wipmedia/sites/8/2022/07/VP5_800x450_Windows_Insiders_London.jpg'
  //   }
  // },
  {
    path: 'feedback',
    loadChildren: () => import('./feedback/giving/giving.module').then(m => m.GivingModule),
    data: {
      title: 'Provide feedback as a Windows Insider',
      ogTitle: 'Provide feedback as a Windows Insider',
      description: 'As a Windows Insider, share feedback to influence changes in the product development stage and help Microsoft build the future of Windows.',
      ogDescription: 'As a Windows Insider, share feedback to influence changes in the product development stage and help Microsoft build the future of Windows.',
      ogUrl: 'feedback',
      ogImage: environment.assetsBasePath + '/wipmedia/sites/8/2022/06/VP2_859x540_feedback.jpg',
      ogSecureImage: environment.assetsBasePath + '/wipmedia/sites/8/2022/06/VP2_859x540_feedback.jpg',
      twitterCard: 'summary_large_image',
      twitterTitle: 'Provide feedback as a Windows Insider',
      twitterDescription: 'As a Windows Insider, share feedback to influence changes in the product development stage and help Microsoft build the future of Windows.',
      twitterUrl: 'feedback',
      twitterImage: environment.assetsBasePath + '/wipmedia/sites/8/2022/06/VP2_859x540_feedback.jpg',
      twitterSecureImage: environment.assetsBasePath + '/wipmedia/sites/8/2022/06/VP2_859x540_feedback.jpg'
    }
  },
  {
    path: 'cleaninstall',
    loadChildren: () => import('./community/clean-install/clean-install.module').then(m => m.CleanInstallModule),
    data: {
      title: 'Windows 11 Clean Installation',
      ogTitle: 'Windows 11 Clean Installation',
      description: 'Step by step instructions to clean install Windows 11 to switch Insider Preview Channel.',
      ogDescription: 'Step by step instructions to clean install Windows 11 to switch Insider Preview Channel.',
      ogUrl: 'cleaninstall',
      ogImage: environment.assetsBasePath + '/wipmedia/sites/8/2023/01/Clean-Instal-Meta-Image.png',
      ogSecureImage: environment.assetsBasePath + '/wipmedia/sites/8/2023/01/Clean-Instal-Meta-Image.png',
      twitterCard: 'summary_large_image',
      twitterTitle: 'Windows 11 Clean Installation',
      twitterDescription: 'Step by step instructions to clean install Windows 11 to switch Insider Preview Channel.',
      twitterUrl: 'cleaninstall',
      twitterImage: environment.assetsBasePath + '/wipmedia/sites/8/2023/01/Clean-Instal-Meta-Image.png',
      twitterSecureImage: environment.assetsBasePath + '/wipmedia/sites/8/2023/01/Clean-Instal-Meta-Image.png'
    }
  },
  {
    path: 'leave-program',
    loadChildren: () => import('./feedback/leave-the-program/leave-the-program.module').then(m => m.LeaveTheProgramModule),
    data: {
      title: 'Leave the Windows Insider Program',
      ogTitle: 'Leave the Windows Insider Program',
      description: 'Windows Insiders help shape the future of Windows. But if you wish to stop getting emails and Windows Insider Preview Builds from us, you can leave the program.',
      ogDescription: 'Windows Insiders help shape the future of Windows. But if you wish to stop getting emails and Windows Insider Preview Builds from us, you can leave the program.',
      ogUrl: 'leave-program',
      ogImage: environment.assetsBasePath + '/wipmedia/sites/8/2022/07/VP2_859x540_gettingstarted.jpg',
      ogSecureImage: environment.assetsBasePath + '/wipmedia/sites/8/2022/07/VP2_859x540_gettingstarted.jpg',
      twitterCard: 'summary_large_image',
      twitterTitle: 'Leave the Windows Insider Program',
      twitterDescription: 'Windows Insiders help shape the future of Windows. But if you wish to stop getting emails and Windows Insider Preview Builds from us, you can leave the program.',
      twitterUrl: 'leave-program',
      twitterImage: environment.assetsBasePath + '/wipmedia/sites/8/2022/07/VP2_859x540_gettingstarted.jpg',
      twitterSecureImage: environment.assetsBasePath + '/wipmedia/sites/8/2022/07/VP2_859x540_gettingstarted.jpg'
    }
  },
  {
    path: 'preview-windows',
    loadChildren: () => import('./flighting/understand-flighting/understand-flighting.module').then(m => m.UnderstandFlightingModule),
    data: {
      title: 'Preview Windows',
      ogTitle: 'Preview Windows',
      description: 'Help shape the future of Windows by running Windows Insider Preview Builds. Join the program.',
      ogDescription: 'Help shape the future of Windows by running Windows Insider Preview Builds. Join the program.',
      ogUrl: 'preview-windows',
      ogImage: environment.assetsBasePath + '/wipmedia/sites/8/2022/02/PreviewWindowsVP2.png',
      ogSecureImage: environment.assetsBasePath + '/wipmedia/sites/8/2022/02/PreviewWindowsVP2.png',
      twitterCard: 'summary_large_image',
      twitterTitle: 'Preview Windows',
      twitterDescription: 'Help shape the future of Windows by running Windows Insider Preview Builds. Join the program.',
      twitterUrl: 'preview-windows',
      twitterImage: environment.assetsBasePath + '/wipmedia/sites/8/2022/09/Tips-and-tricks-original-scaled.jpg',
      twitterSecureImage: environment.assetsBasePath + '/wipmedia/sites/8/2022/09/Tips-and-tricks-original-scaled.jpg',
      
    }
  },
  {
    path: winPrefix,
    loadChildren: () => import('./win-sv/win-sv.module').then(m => m.WinSVModule),
    data: {
      title: 'Inside Windows 11',
      ogTitle: 'Inside Windows 11',
      description: 'Want to learn how we made Windows 11? Check out our Inside Windows 11 website to hear and meet Microsoft makers from Engineering, Design and Research teams.',
      ogDescription: 'Want to learn how we made Windows 11? Check out our Inside Windows 11 website to hear and meet Microsoft makers from Engineering, Design and Research teams.',
      ogUrl: winPrefix,
      ogImage: environment.assetsBasePath + '/wipmedia/sites/8/2023/04/week16_maker12@2x.png',
      ogSecureImage: environment.assetsBasePath + '/wipmedia/sites/8/2023/04/week16_maker12@2x.png',
      twitterCard: 'summary_large_image',
      twitterTitle: 'Inside Windows 11',
      twitterDescription: 'Want to learn how we made Windows 11? Check out our Inside Windows 11 website to hear and meet Microsoft makers from Engineering, Design and Research teams.',
      twitterUrl: winPrefix,
      twitterImage: environment.assetsBasePath + '/wipmedia/sites/8/2023/04/week16_maker12@2x.png',
      twitterSecureImage: environment.assetsBasePath + '/wipmedia/sites/8/2023/04/week16_maker12@2x.png'
    }
  },
  {
    path: 'for-business-getting-started',
    loadChildren: () => import('./quickstart/business/business.module').then(m => m.BusinessModule),
    data: {
      title: 'Getting started with the Windows Insider Program for Business',
      ogTitle: 'Getting started with the Windows Insider Program for Business',
      description: "Be the first to see what's next for Windows, validate apps and infrastructure, deploy the next version of Windows faster.",
      ogDescription: "Be the first to see what's next for Windows, validate apps and infrastructure, deploy the next version of Windows faster.",
      ogUrl: 'for-business-getting-started',
      ogImage: environment.assetsBasePath + '/wipmedia/sites/8/2022/07/VP2_859x540_gettingstarted.jpg',
      ogSecureImage: environment.assetsBasePath + '/wipmedia/sites/8/2022/07/VP2_859x540_gettingstarted.jpg',
      twitterCard: 'summary_large_image',
      twitterTitle: 'Getting started with the Windows Insider Program for Business',
      twitterDescription: "Be the first to see what's next for Windows, validate apps and infrastructure, deploy the next version of Windows faster.",
      twitterUrl: 'for-business-getting-started',
      twitterImage: environment.assetsBasePath + '/wipmedia/sites/8/2022/09/Tips-and-tricks-original-scaled.jpg',
      twitterSecureImage: environment.assetsBasePath + '/wipmedia/sites/8/2022/09/Tips-and-tricks-original-scaled.jpg'
    }
  },
  {
    path: 'getting-started',
    loadChildren: () => import('./quickstart/consumer/consumer.module').then(m => m.ConsumerModule),
    data: {
      title: 'Getting started with the Windows Insider Program',
      ogTitle: 'Getting started with the Windows Insider Program',
      description: "Be the first to see what's next for Windows, provide feedback to help us improve Windows, and join our inspiring community in the Windows Insider Program.",
      ogDescription: "Be the first to see what's next for Windows, provide feedback to help us improve Windows, and join our inspiring community in the Windows Insider Program.",
      ogUrl: 'getting-started',
      ogImage: environment.assetsBasePath + '/wipmedia/sites/8/2022/07/VP2_859x540_gettingstarted.jpg',
      ogSecureImage: environment.assetsBasePath + '/wipmedia/sites/8/2022/07/VP2_859x540_gettingstarted.jpg',
      twitterCard: 'summary_large_image',
      twitterTitle: 'Getting started with the Windows Insider Program',
      twitterDescription: "Be the first to see what's next for Windows, provide feedback to help us improve Windows, and join our inspiring community in the Windows Insider Program.",
      twitterUrl: 'getting-started',
      twitterImage: environment.assetsBasePath + '/wipmedia/sites/8/2022/07/VP2_859x540_gettingstarted.jpg',
      twitterSecureImage: environment.assetsBasePath + '/wipmedia/sites/8/2022/07/VP2_859x540_gettingstarted.jpg'
    }
  },
  {
    path: 'for-developers-getting-started',
    loadChildren: () => import('./quickstart/developers/developers.module').then(m => m.DevelopersModule),
    data: {
      title: 'Getting started with the Windows Insider Program for Developers',
      ogTitle: 'Getting started with the Windows Insider Program for Developers',
      description: 'Bring better apps to your customers faster with Windows SDK Insider Preview Builds and tools to collect feedback in the Windows Insider Program for Developers.',
      ogDescription: 'Bring better apps to your customers faster with Windows SDK Insider Preview Builds and tools to collect feedback in the Windows Insider Program for Developers.',
      ogUrl: 'for-developers-getting-started',
      ogImage: environment.assetsBasePath + '/wipmedia/sites/8/2022/07/VP2_859x540_gettingstarted.jpg',
      ogSecureImage: environment.assetsBasePath + '/wipmedia/sites/8/2022/07/VP2_859x540_gettingstarted.jpg',
      twitterCard: 'summary_large_image',
      twitterTitle: 'Getting started with the Windows Insider Program for Developers',
      twitterDescription: 'Bring better apps to your customers faster with Windows SDK Insider Preview Builds and tools to collect feedback in the Windows Insider Program for Developers.',
      twitterUrl: 'for-developers-getting-started',
      twitterImage: environment.assetsBasePath + '/wipmedia/sites/8/2022/07/VP2_859x540_gettingstarted.jpg',
      twitterSecureImage: environment.assetsBasePath + '/wipmedia/sites/8/2022/07/VP2_859x540_gettingstarted.jpg'
    }
  },
  {
    path: 'locale',
    loadChildren: () => import('./locale/locale.module').then(m => m.LocaleModule)
  },
  {
    path: 'for-business-getting-started-server',
    loadChildren: () => import('./quickstart/server/server.module').then(m => m.ServerModule),
    data: {
      title: 'Getting started with the Windows Insider Program for Windows Server',
      ogTitle: 'Getting started with the Windows Insider Program for Windows Server',
      description: 'Get exclusive access to Windows Server Insider Previews and Remote Server Administration tools. Influence the future of Windows Server.',
      ogDescription: 'Get exclusive access to Windows Server Insider Previews and Remote Server Administration tools. Influence the future of Windows Server.',
      ogUrl: 'for-business-getting-started-server',
      ogImage: environment.assetsBasePath + '/wipmedia/sites/8/2022/07/VP2_859x540_gettingstarted.jpg',
      ogSecureImage: environment.assetsBasePath + '/wipmedia/sites/8/2022/07/VP2_859x540_gettingstarted.jpg',
      twitterCard: 'summary_large_image',
      twitterTitle: 'Getting started with the Windows Insider Program for Windows Server',
      twitterDescription: 'Get exclusive access to Windows Server Insider Previews and Remote Server Administration tools. Influence the future of Windows Server.',
      twitterUrl: 'for-business-getting-started-server',
      twitterImage: environment.assetsBasePath + '/wipmedia/sites/8/2022/07/VP2_859x540_gettingstarted.jpg',
      twitterSecureImage: environment.assetsBasePath + '/wipmedia/sites/8/2022/07/VP2_859x540_gettingstarted.jpg'
    }
  },
  { path: 'register', loadChildren: () => import('./register/register.module').then(m => m.RegisterModule) },
  // tslint:disable-next-line: max-line-length
  { path: 'program-agreement', loadChildren: () => import('./program-agreement/program-agreement.module').then(m => m.ProgramAgreementModule) },
  { path: 'search', component: SearchComponent, data: { title: 'Search' } },
  {
    path: '**', component: PageUnavailableComponent, data: {page:'page-unavailable',status: 'not-found'}
  }
];

@NgModule({
  imports: [RouterModule.forRoot(appRoutes, { preloadingStrategy: PreloadAllModules, useHash: false, scrollPositionRestoration: 'disabled', anchorScrolling: 'enabled' })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
