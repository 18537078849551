export interface IUhfElement {
  id: string;
  value: string;
}

export interface IUhfContent {
  cssIncludes: string;
  javaScriptIncludes: string;
  communityHeaderHtml: string;
  footerHtml: string;
}

export interface IMeControlUserData {
  idp: string;
  nickName: string;
  tileUrl: string;
  firstName: string;
  lastName: string;
  memberName: string;
  cid: string;
  authenticatedState: number;
}


// {"IsRegisteredPilot":true,"IsExternal":true,
// "CreatedDate":"11/11/2019 1:12:22 PM",
// "AreasOfInterest":[],"InsiderCommunities":[{"Name":"Windows","Order":0,"Value":true},{"Name":"3D","Order":1,"Value":false}],
// "SurveyAnswers":[],"Tags":[{"Namespace":"Registration","Name":"RegistrationSource","Value":"2"},{"Namespace":"Registration","Name":"ReferrerUser","Value":"246295"}],
// "Group":"Insider","GlobalDeviceId":null,"IsRecoveryMode":false,"IsRegisteredByOrg":false,"IsRegisteredByIndividual":true,"MyReferralCode":"8V7JB7PW","ReferrerReferralCode":null}
export interface IFlightSettings {
  isRegisteredPilot: boolean;
  isExternal: boolean;
  isGlobalAadAdmin: boolean;
  isMsa: boolean;
  areasOfInterest: [];
  insiderCommunities: [];
  surveyAnswers: [];
  tags: [];
  group: string;
  globalDeviceId: string;
  isRecoveryMode: boolean;
  isRegisteredByOrg: boolean;
  isRegisteredByIndividual: boolean;
  myReferralCode: string;
  referrerReferralCode: string;
  isAuthenticated: boolean;
}

export class FlightSettings implements IFlightSettings {
  isRegisteredPilot: boolean;
  isExternal: boolean;
  isGlobalAadAdmin: boolean;
  isMsa: boolean;
  areasOfInterest: [];
  insiderCommunities: [];
  surveyAnswers: [];
  tags: [];
  group: string;
  globalDeviceId: string;
  isRecoveryMode: boolean;
  isRegisteredByOrg: boolean;
  isRegisteredByIndividual: boolean;
  myReferralCode: string;
  referrerReferralCode: string;
  isAuthenticated: boolean;

  constructor(
    isRegisteredPilot: boolean,
    isExternal: boolean,
    isGlobalAadAdmin: boolean,
    isMsa: boolean,
    areasOfInterest: [],
    insiderCommunities: [],
    surveyAnswers: [],
    tags: [],
    group: string,
    globalDeviceId: string,
    isRecoveryMode: boolean,
    isRegisteredByOrg: boolean,
    isRegisteredByIndividual: boolean,
    myReferralCode: string,
    referrerReferralCode: string,
    isAuthenticated: boolean
  ) {
    this.isRegisteredPilot = isRegisteredPilot,
      this.isExternal = isExternal,
      this.isGlobalAadAdmin = isGlobalAadAdmin,
      this.isMsa = isMsa,
      this.areasOfInterest = areasOfInterest,
      this.insiderCommunities = insiderCommunities,
      this.surveyAnswers = surveyAnswers,
      this.tags = tags,
      this.group = group,
      this.globalDeviceId = globalDeviceId,
      this.isRecoveryMode = isRecoveryMode,
      this.isRegisteredByOrg = isRegisteredByOrg,
      this.isRegisteredByIndividual = isRegisteredByIndividual,
      this.myReferralCode = myReferralCode,
      this.referrerReferralCode = referrerReferralCode,
      this.isAuthenticated = isAuthenticated;
  }
}




export interface IUser {
  displayName: string;
  displayPic: string;
  firstName: string;
  lastName: string;
  emailAddress: string;
}

export interface IBackGroundCheckDetails {
  isBackgroundCheckPass: boolean;
  backgroundCheckResults: ISpecializationRequirementValidationResult[];
  lastUpdated: Date;
}

export interface ISpecializationRequirementValidationResult {
  requirementType: number;
  validationPassed: boolean;
  actualValue: string;
}

export interface IUserEligibility {
  isEligible: boolean;
}

export class GroupFiltered {
  filterBy: string;
  filteredRemoveId: string;
  filteredId: string;
  filteredName: string;
}
