<div id="app-container">
  <button class="m-skip-to-main" (click)="focusMainContent()" style="z-index: 3000002;">Skip to main content</button>
  <shared-uhf-header [user]="user" *ngIf="msalCallBackFired"></shared-uhf-header>
  <!-- <ng-template #notificationPopup></ng-template> -->

  <a id="mainContent" name="mainContent" tabindex="0"></a>
  <main id="body-content" *ngIf="userLoaded && msalCallBackFired">
    <router-outlet (activate)="onActivate($event)"></router-outlet>
  </main>

  <ng-container *ngIf="!userLoaded">
    <shared-spinner></shared-spinner>
  </ng-container>

  <shared-uhf-footer *ngIf="userLoaded"></shared-uhf-footer>
</div>