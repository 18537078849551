import { Environment } from './enviornment.interface';

const baseUrl = 'https://dev-insider.window.com/';
//const serviceFabricApi = 'https://localhost:44348';
const serviceFabricApi = 'https://wip-api-dev.azurewebsites.net';
//const serviceFabricApi = 'https://wip-api-prod.azurewebsites.net';

export const environment: Environment = {
  production: false,
  appTitleName: 'Windows Insider [preview]',
  awaEnv: 'pre-prod',
  urls: {
    uhf: serviceFabricApi + '/api/uhf?preview=true&locale=',
    uhfCssUrl: baseUrl + 'assets/data/uhfCss.json',
    uhfJsUrl: baseUrl + 'assets/data/uhfJs.json',
    uhfHeaderUrl: baseUrl + 'assets/data/uhfHeader.json',
    uhfFooterUrl: baseUrl + 'assets/data/uhfFooter.json',
    identityUrl: serviceFabricApi + '/api/identity',
    contentUrl: serviceFabricApi + '/api/content?useCache=true&documentAliases=',
    m365TestApiUrl: 'http://localhost:62868/api/content?useCache=false&documentAliases=',
    categoriesUrl: serviceFabricApi + '/api/content/categories?usecache=true',
    categoryFilterUrl: serviceFabricApi + '/api/content/categoryfiltered/post?categoryids=',
    azureSearchApiUrl: serviceFabricApi + '/api/content/docs?useCache=true&',
    configUrl: serviceFabricApi + '/api/content/config?usecache=true',
    windowsInsiderUrls: {
      userSettings: serviceFabricApi + '/api/identity/flight',
      leaveProgram: serviceFabricApi + '/api/identity/unregister',
      leaveOrgProgram: serviceFabricApi + '/api/identity/unregisterorg',
      register: serviceFabricApi + '/api/identity/register',
      registerOrg: serviceFabricApi + '/api/identity/registerorg',
      eligibility: serviceFabricApi + '/api/identity/eligibility',
      terms: serviceFabricApi + '/api/identity/terms',
      newsletter: serviceFabricApi + '/api/crm'
    }
  },
  uhfPartnerId: 'WindowsInsider',
  uhfHeaderId: 'WindowsInsiderHeader_v2_SV',
  uhfFooterId: 'WindowsInsiderFooter_v2',
  webApi: '',
  serviceFabricApi: 'https://wip-api-dev.azurewebsites.net',
  msaIssuer: 'https://login.microsoftonline.com/common',
  msaClientId: '3cb0f710-95bd-4d6b-8351-0bbd5b529128',  // Windows Insider Test
  msaScope: 'Ambassadors.API',
  oAuthLoginUrl: 'https://login.live.com/oauth20_authorize.srf',
  oAuthLogoutUrl: 'https://login.live.com/oidc_logout.srf?post_logout_redirect_uri=https://dev-insider.windows.com',
  oAuthRedirectUrl: 'https://dev-insider.windows.com',
  tenant: 'windows-insider',
  apiScopes: ['cf05d06a-5c74-48b7-8c21-0d1ecbfa67f6/Users.Access'],
  wordPressImageCdn: 'wipwebppecdn.blob.core.windows.net/wipmedia',
  wordPressAuthoringUrl: 'wipaffirmadev.trafficmanager.net',
  pagingSize: '10',
  mvpPageSizeDesktop: '2',
  azureSearchKey: '',
  googleAnalytics: "UA-99817921-2",
  instrumentationKey1DS: "434bd312af6e4704b1167a4b76f31ffa-862fd1ee-ade8-4907-bf80-a4bce485e698-7658",
  azureInstrumentationKey: "fd18089e-9b39-48b5-9d38-5bfdd0fe78bb",
  adobeLaunchScriptUrl: 'https://assets.adobedtm.com/5ef092d1efb5/c0e4cfccb8fb/launch-747bd75ea62a.min.js',
  wcpConsentJSUrl: 'https://wcpstatic.microsoft.com/mscc/lib/v2/wcp-consent.js',
  assetsBasePath: 'https://wip-web-cdn.azureedge.net',
  envName: 'Preview'
};

