<ng-container class="1DS-iteration" *ngIf="!contentLoading && !userLoading">
  <ng-container *ngIf="user && user.isRegisteredPilot">
    <div *ngIf="header" [innerHTML]="header | safeHtml">
    </div>
    <div *ngIf="section1" [innerHTML]="section1 | safeHtml">
    </div>
    <div *ngIf="section2" [innerHTML]="section2 | safeHtml">
    </div>
    <div *ngIf="section3" [innerHTML]="section3 | safeHtml">
    </div>
    <div *ngIf="section4" [innerHTML]="section4 | safeHtml">
    </div>
    <!-- <div *ngIf="section5" [innerHTML]="section5 | safeHtml">
    </div>
    <div *ngIf="section6" [innerHTML]="section6 | safeHtml">
    </div> -->
  </ng-container>

  <ng-container *ngIf="!user || (user && !user.isRegisteredPilot)">
    <div *ngIf="header" [innerHTML]="header | safeHtml">
    </div>
    <div *ngIf="section2" [innerHTML]="section2 | safeHtml">
    </div>
    <div *ngIf="section1" [innerHTML]="section1 | safeHtml">
    </div>
    <div class="channel-info-section" *ngIf="section3" [innerHTML]="section3 | safeHtml">
    </div>
    <div *ngIf="section4" [innerHTML]="section4 | safeHtml">
    </div>
    <!-- <div *ngIf="section5" [innerHTML]="section5 | safeHtml">
    </div>
    <div *ngIf="section6" [innerHTML]="section6 | safeHtml">
    </div> -->
  </ng-container>

  <div *ngIf="section5" [innerHTML]="section5 | safeHtml">
  </div>
  <div *ngIf="section6" [innerHTML]="section6 | safeHtml">
  </div>
</ng-container>

<ng-container *ngIf="contentLoading ||  userLoading">
  <shared-spinner></shared-spinner>
</ng-container>
