export interface ContentBlob {
    contentName: string;
}

export interface PageAction {
    behavior: number;
    uri: string;
    referrerUri: string;
    pageName: string;
    actionType: string;
    content: ContentBlob;  // JSON blob
    targetUri: string;
    pageLoadTime?: number;
    isManual: boolean;
    pageTags?: any; // JSON blob
}

export enum PageActionEventTypes {
    LeaderboardLoadStart = 'LeaderboardLoadStart',
    LeaderboardLoadComplete = 'LeaderboardLoadComplete',
    LeaderboardSearchComplete = 'LeaderboardSearchComplete'
  }

export enum PageNameTypes {
    Home = 'home',
    Register = 'register',
    Leave = 'leave',
    Podcasts = 'podcast feed',
    Articles = 'article feed',
    Events = 'event feed',
    Referral = 'referral'
}

export interface ClientError {
    errorInfo: string;
    wasDisplayed: boolean;
    customSessionGuid: string;
    impressionGuid: string;
}

/**
 * Here is a sample from the docs
 * https://microsoft.sharepoint.com/sites/mscomdata/Pages/awa-page-action-tracking.aspx
 *
 * Behavior diction is here: https://microsoft.sharepoint.com/sites/mscomdata/Pages/awa-behavior-dictionary.aspx
 *
 * Action type dictionary is here: https://microsoft.sharepoint.com/sites/mscomdata/Pages/AWA-Action-Type-Dictionary.aspx
    const overrideValues = {
        behavior: 41,
        uri:"http://customuri",
        referrerUri:"http://bing.com?q=windowsstore",
        pageName: "mypagename",
        actionType:"CL",
        content:{scenarioName:"User signed in"},
        targetUri:"http://msn.com/targeturi.aspx",
        pageLoadTime:1235
    };
*/
