import { APP_BASE_HREF, DatePipe } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { APP_INITIALIZER, ErrorHandler, NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { UrlSerializer } from '@angular/router';
import { AppComponent } from './app.component';
import { AppRoutingModule } from './app.router';
import { ContestsModule } from './community/contests/contests.module';
import { CoreModule } from './core/core.module';
import { LowerCaseUrlSerializer } from './core/utility/lowercaseurl';
import { FeedbackHubErrorComponent } from './feedback-hub/fb-error/fb-error.component';
import { FeedbackHubNonWin10Component } from './feedback-hub/hub-non-win10/hub-non-win10.component';
import { FeedbackHubOpenComponent } from './feedback-hub/open/open.component';
import { HomeComponent } from './home/home.component';
import { LocaleModule } from './locale/locale.module';
import { NotFoundComponent } from './not-found/not-found.compnent';
import { SearchComponent } from './search/search.component';
import { SharedModule } from './shared/shared.module';
import { StudentComponent } from './about/student/student.component';
import { BaseUrlService } from './shared/base-url.service';
import { AppInsightsErrorHandlerService } from './core/app-insights-error-handler/app-insights-error-handler.service';
import { ApiMetaDataService } from './shared/api-meta-data-service/api-meta-data.service';
import { TelemetryService } from './core/telemetry/telemetry.service';
import { HeaderLoaderService } from './core/uhf/header-loader.service';
// import { NotificationComponent } from './shared/notification/notification.component';


export function loggerCallback(logLevel, message, piiEnabled) {
  // console.log('client logging' + message);
}


// export const protectedResourceMap:[string, string[]][]=[ ['https://ambassadorspreview.westus.cloudapp.azure.com:8637',['Ambassadors.API']] ,['https://buildtodoservice.azurewebsites.net/api/todolist',['api://a88bb933-319c-41b5-9f04-eff36d985612/access_as_user']] , ['https://graph.microsoft.com/v1.0/me', ['user.read']], ['https://localhost:44348/', ['api://a0992c0c-812e-470c-90e9-ee1c339517d7/Users.Read']] ];

// export const protectedResourceMap: [string, string[]][] = [
//   ['https://localhost:44348/', ['api://d56dfd76-c4f7-4ecf-b36a-133d7d86e64d/Ambassador.API']]
// ];


export const protectedResourceMap: [string, string[]][] = [
  ['https://localhost:44348/', ['api://cf05d06a-5c74-48b7-8c21-0d1ecbfa67f6/Users.Access']],
  ['https://insiderportal-ppe.microsoft.com', ['api://6734b643-6b71-442e-90c2-9585b0db0be6/Insider.Profile.Read']]
];

// export const protectedResourceMap: [string, string[]][] = [
//   ['https://localhost:44348/', ['api://a0992c0c-812e-470c-90e9-ee1c339517d7/Users.Read']]
// ];



// const isIE = window.navigator.userAgent.indexOf('MSIE ') > -1 || window.navigator.userAgent.indexOf('Trident/') > -1;

@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    NotFoundComponent,
    FeedbackHubErrorComponent,
    FeedbackHubNonWin10Component,
    FeedbackHubOpenComponent,
    FeedbackHubOpenComponent,
    SearchComponent,
    StudentComponent
  ],
  imports: [
    FormsModule,
    CoreModule,
    BrowserModule.withServerTransition({ appId: 'serverApp' }),
    HttpClientModule,
    SharedModule,
    AppRoutingModule,
    ContestsModule,
    LocaleModule
  ],
  providers: [
    TelemetryService,
    HeaderLoaderService,
    {
      provide: ErrorHandler,
      useClass: AppInsightsErrorHandlerService
    },
    DatePipe,
    {
      provide: UrlSerializer,
      useClass: LowerCaseUrlSerializer
    },
    {
      provide: APP_BASE_HREF, useFactory:
        (baseUrl: BaseUrlService) => {
          const localeCode = baseUrl.getBaseUrl();
          if(localeCode){
            return `${baseUrl.getBaseUrl()}/windowsinsider`;
          } else {
            return `${baseUrl.getServerBaseUrl()}`;
          }
        },
      deps: [BaseUrlService]
    },
    {
      provide: APP_INITIALIZER,
      useFactory: initializeApplicationMetadata,
      multi: true,
      deps: [ApiMetaDataService]
    }
  ],
  bootstrap: [AppComponent],
  // entryComponents:[NotificationComponent]
})
export class AppModule { }
export function initializeApplicationMetadata(apiMetaService: ApiMetaDataService) {
  return () => apiMetaService.getSlug();
}