import { EventEmitter, Injectable } from '@angular/core';
import { Observable, ReplaySubject, Subject } from 'rxjs';
import { take } from 'rxjs/operators';
import { ContentService } from '../../core/contentRT/contentRT.service';
import { LocalizationService } from '../../core/localization/localization.service';
import { AppInsightsErrorHandlerService } from '../app-insights-error-handler/app-insights-error-handler.service';
import { GroupFiltered } from '../core.model';
import { TelemetryService } from '../telemetry/telemetry.service';
import { MonthYearArray } from './utility.model';

@Injectable({
  providedIn: 'root',
})
export class UtilityService {
  public dateFilter: EventEmitter<string> = new EventEmitter<string>();
  public countryFilter: EventEmitter<string> = new EventEmitter<string>();
  public categoryFilter: EventEmitter<string> = new EventEmitter<string>();
  public audienceFilter: EventEmitter<string> = new EventEmitter<string>();
  public queryStringFilter: string;
  public groupOfFilter: GroupFiltered[] = [];
  public removesFilteredItem: EventEmitter<GroupFiltered> = new EventEmitter<GroupFiltered>();
  public successCategoryFilter: EventEmitter<boolean> = new EventEmitter<boolean>();

  private monthsYearsSubject$: Subject<any> = new ReplaySubject(1);
  private filterConfigSubject$: Subject<any> = new ReplaySubject(1);
  private errorConfigSubject$: Subject<any> = new ReplaySubject(1);

  constructor(
    private localizationService: LocalizationService,
    private contentService: ContentService,
    private telemetryService: TelemetryService,
    private appInsightsErrorHandler: AppInsightsErrorHandlerService
  ) { }

  public monthDataArray: MonthYearArray[] = [];
  public MonthArray: any;
  public contentLoading = true;

  public getMonthYears(): Observable<any> {
    return this.monthsYearsSubject$.asObservable();
  }

  public getFilterConfig(): Observable<any> {
    return this.filterConfigSubject$.asObservable();
  }

  // call this when the application boots up and it will allow component pages to get paging settings from cache
  public initMonthsYears() {
    const language = this.localizationService.getLanguage();
    const contentString = `months/list.json&language=${language}`;

    this.contentService
      .getCmsContent(contentString)
      .pipe(take(1))
      .subscribe(
        (result) => {
          const json = JSON.parse(result.documents[0].document);
          this.monthsYearsSubject$.next(json);
        },
        (err) => {
          this.telemetryService.logError(`Error getting months and years: ${JSON.stringify(err)}`
          );
          this.appInsightsErrorHandler.handleError(`Error getting months and years: ${JSON.stringify(err)}`);
        }
      );
  }

  public getCategoryFilterQuery(categories: string[]): string {
    let index = 0;
    let applyCategory = '';
    categories.forEach((element) => {
      applyCategory += index === 0 ? `t eq '${element}' ` : ` or t eq '${element}' `;
      index++;
    });

    return `and categories/any(t: ${applyCategory})`;
  }

  public getDateFilterQuery(date: string): string {

    // tslint:disable-next-line: radix
    const month = parseInt(date.split(',')[0]);
    // tslint:disable-next-line: radix
    const year = parseInt(date.split(',')[1]);
    const day = new Date(year, month + 1, 0).getDate();
    const startDate = new Date(year, month, 1);
    const jsonStartDate = new Date(startDate.getTime() - (startDate.getTimezoneOffset() * 60000)).toJSON();
    const endDate = new Date(year, month, day, 23, 59, 59, 59);
    const jsonEndDate = new Date(endDate.getTime() - (endDate.getTimezoneOffset() * 60000)).toJSON();

    return `and CreatedDate/date ge ${jsonStartDate} and CreatedDate/date le ${jsonEndDate}`;
  }

  public getCookie(cname) {
    const name = cname + '=';
    const ca = document.cookie.split(';');
    for (let i = 0; i < ca.length; i++) {
      let c = ca[i];
      while (c.charAt(0) === ' ') {
        c = c.substring(1);
      }
      if (c.indexOf(name) === 0) {
        return c.substring(name.length, c.length);
      }
    }
    return '';
  }

  // call this when the application boots up and it will allow component pages to get paging settings from cache
  public initFilterConfig() {
    const language = this.localizationService.getLanguage();
    const contentString = `filters/meta.json&language=${language}`;

    this.contentService
      .getCmsContent(contentString)
      .pipe(take(1))
      .subscribe(
        (result) => {
          const json = JSON.parse(result.documents[0].document);
          this.filterConfigSubject$.next(json);
        },
        (err) => {
          this.telemetryService.logError(`Error getting in utility service months and years: ${JSON.stringify(err)}`
          );
          this.appInsightsErrorHandler.handleError(`Error getting in utility service months and years: ${JSON.stringify(err)}`);
        }
      );
  }

  public getPageUnavailable(): Observable<any> {
    return this.errorConfigSubject$.asObservable();
  }

  public getFilterValue(): string[] {
    return this.groupOfFilter.map(val => val.filteredId);
    // return this.groupOfFilter.map(val => val.filteredId).join(',');
  }

  public getUnavailablePageConfig() {
    const language = this.localizationService.getLanguage();
    const contentPageString = `errors/page-unavailable.json&language=${language}`;
    this.contentService.getCmsContent(contentPageString)
      .pipe(take(1))
      .subscribe((result) => {
        const pageContent = result.documents[0];
        const metaData = JSON.parse(pageContent.document);
        this.errorConfigSubject$.next(metaData);
      }, err => {
        this.telemetryService.logError(`Error getting utility service page-unavailable : ${JSON.stringify(err)}`);
        this.appInsightsErrorHandler.handleError(`Error getting utility service page-unavailable : ${JSON.stringify(err)}`);
        this.contentLoading = false;
      });
  }

  public setFilterModel(model: any, filterBy: string) {

    const instance: GroupFiltered = {
      filterBy: filterBy,
      filteredRemoveId: model.id,
      // filteredId: model.categoryId,
      filteredId: model.name,
      filteredName: model.name,
    };

    this.groupOfFilter.push(instance);
  }
}
