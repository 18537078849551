<ng-container *ngIf="!contentLoading">
  <div class="material-surface">
  <div class="win-sv-footer container" [ngClass]="{'show-disclaimer':showDisclaimer}">
    <div class="row social" style="margin-right: 0;width: 100%!important;">
      <div class="col-xl-8 col-lg-12 col-md-12 col-sm-12 wip-content-left">
        <span class="h5 wip-content x-hidden-focus">Connect with Windows Insider Program&nbsp;:</span>
        <ul class="list-inline mb-0 align-middle">
          <li class="list-inline-item mr-g">
            <a itemprop="sameAs" href="https://answers.microsoft.com/" class="" target="_blank" aria-label="Microsoft Community">
              <picture>
                <img src="https://wip-web-cdn.azureedge.net/wipmedia/sites/8/2022/05/WIPchat.png" alt="" class="round-corners-none">
              </picture>
              <!-- <picture class="ow-high-contrast-on-black">
              <img src="//www.microsoft.com/onerfstatics/marketingsites-eas-prod/_h/8eedcc44/office.testdrive/images/social/W365ContactSales-high-contrast.png" alt="">
              </picture> -->
              <span class="ml-2" style="vertical-align: bottom;">Microsoft Community</span>
            </a>
          </li>
          <li class="list-inline-item mr-g">
            <a itemprop="sameAs" href="https://docs.microsoft.com/windows-insider" class="" target="_blank" aria-label="Windows Insider Documentation">
              <picture>
                <img src="https://wip-web-cdn.azureedge.net/wipmedia/sites/8/2022/05/WIPdocumentation.png" alt="" class="round-corners-none">
              </picture>
              <span class="ml-2" style="vertical-align: bottom;">Windows Insider Documentation</span>
            </a>
          </li>
        </ul>
      </div>
      
      <div class="col-lg-12 col-md-12 col-sm-12 wip-xl-hidden" style="padding-top: .4em;">
      <hr class="wip-divider" role="presentation" aria-hidden="true">
      </div>
      <!-- <div class="wip-vertical-divider wip-display-none wip-xl-show text-right" role="presentation" aria-hidden="true"></div> -->

      <div class="col-lg-12 col-md-12 col-sm-12 col-xl-4 wip-content-right">
        <span class="wip-vertical-divider wip-display-none wip-xl-show"></span>
        <span class="h5 wip-content">Follow Us</span>
        <ul class="list-inline mb-0 align-middle">
          <li class="list-inline-item mr-g" title="Twitter">
            <a class="d-inline-block" aria-label="Follow us on X"
              href="https://twitter.com/windowsinsider" target="_blank">
              <img src=".../../assets/media/images/social-svg/logo_X_black.svg" alt="Follow on X"
                  title="Follow on X" width="100%" height="100%" class="round-corners-none">
            </a>
          </li>
          <li class="list-inline-item mr-g" title="Instagram">
            <a class="d-inline-block" aria-label="Follow us on Instagram"
              href="https://www.instagram.com/windowsinsider/" target="_blank">
              <img src=".../../assets/media/images/social-svg/logo_Instagram_black.svg" alt="Follow on Instagram"
                  title="Follow on Instagram" width="100%" height="100%" class="round-corners-none">
            </a>
          </li>
          <li class="list-inline-item mr-g" title="Facebook">
            <a class="d-inline-block" aria-label="Follow us on Facebook"
              href="https://www.facebook.com/WindowsInsiderProgram/" target="_blank">
              <img src=".../../assets/media/images/social-svg/logo_Facebook_black.svg" alt="Follow on Facebook"
                  title="Follow on Facebook" width="100%" height="100%" class="round-corners-none">
            </a>
          </li>
          <li class="list-inline-item mr-g" title="YouTube">
            <a class="d-inline-block" aria-label="Follow us on YouTube"
              href="https://www.youtube.com/windowsinsiderprogramofficial" target="_blank">
              <img src=".../../assets/media/images/social-svg/logo_YouTube_black.svg" alt="Follow on Youtube"
                  title="Follow on Youtube" width="100%" height="100%" class="round-corners-none">
            </a>
          </li>

        </ul>
      </div>
      
    </div>
    <div *ngIf="showDisclaimer" class="disclaimer">
        <span>Pre release products shown, subject to change</span>
        <p><sup>1</sup>For supported devices with latest security updates installed.</p>
    </div>
  </div>
</div>
</ng-container>